import React from 'react'
import useWindowDimensions from '../modules/useWindowDimensions'

function NotFoundedComponent() {

    const { height, width } = useWindowDimensions();

    return (
        <div className='flexwrap tac'>
            <div className='flex1' />
            <div className='flexcolwrap' style={{height, 'width':(width / 4)}}>
                <div className='flex1' />
                <h1 className='fs25'>
                    경로를 찾을 수 없습니다.
                </h1>
                <div className='flex1' />
            </div>
            <div className='flex1' />
        </div>
    )
}

export default NotFoundedComponent