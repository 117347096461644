const DATASET = 'DATASET';

const initialState = {
    lastpage: "",
    page: 0,
    alerted: false,
    jwt: {}
}

const data = (state = initialState, action) => {

  let setPage = 0;
  if (action.lastpage === state.lastpage) {
    setPage = action.page
  }

  switch (action.type) {
    case DATASET:
      
      return {...state, 
        lastpage: action.lastpage,
        page: setPage,
        jwt: action.jwt,
        alerted : action.alerted
    }
    default:
      return state;
  }
};

export default data;