import axios from "axios";
import { checkSession, refreshCall } from "./tokenChecker";

class CallAPI {
  constructor(setLoading = null) {
    this.setLoading = setLoading;
  }

  async request(apiInfo, body = null) {
    this.startLoading();
    const { method, url } = apiInfo;
    try {
      console.log("apiInfo", apiInfo);
      if (!apiInfo) {
        console.log("apiInfo is null");
        return;
      }

      const { token, user } = await checkSession();

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const config = {
        method,
        url,
        headers,
        data: body,
      };
      console.log("config", config);

      if (body) {
        config.data = body;
      }

      let response = await axios(config);

      console.log(
        new Date().toLocaleTimeString(),
        "API response",
        response.status,
        response.data
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.log("403 error, trying to refresh token...");
        try {
          await refreshCall();
          const { token } = await checkSession(); // Get the new token

          const headers = {
            Authorization: `Bearer ${token}`,
          };

          const config = {
            method,
            url,
            headers,
            data: body,
          };

          if (body) {
            config.data = body;
          }

          const response = await axios(config);

          console.log(
            new Date().toLocaleTimeString(),
            "API response after refresh",
            response.status,
            response.data
          );
          return response;
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          throw refreshError;
        }
      } else {
        console.log("##### error", error);
        throw error;
      }
    } finally {
      this.endLoading();
    }
  }

  async requestWithOutToken(url, method, body = null) {
    try {
      const config = {
        method,
        url,
        data: body,
      };
      console.log("config", config);

      // body가 있을 경우 추가
      if (body) {
        config.data = body;
      }

      const response = await axios(config);

      // API 호출 성공 시의 로직 추가
      console.log("response", response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  startLoading() {
    if (this.setLoading) {
      this.setLoading(true);
    }
  }

  endLoading() {
    if (this.setLoading) {
      this.setLoading(false);
    }
  }
}

export default CallAPI;
