import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { getProfile } from "../../../modules/decodeToken";

function FAQDetailComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [typeText, setTypeText] = useState("분류");
  const [titleText, setTitleText] = useState("제목 영역입니다");
  const [conText, setConText] = useState(
    "내용 영역입니다\n내용이 작성된 내용입니다"
  );
  const [updateUrl, setUpdateUrl] = useState("#");

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  useEffect(() => {
    if (!params.target) {
      // page 잘못됨
      return;
    }

    setUserProfile();
    // console.log(params.target)
    setUpdateUrl("/cs/faq/write/" + params.target);

    const fetchData = async () => {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        const apiInfo = { ...api.faqDetail };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo);

        if (res.status === 200) {
          let data = res.data;

          setTitleText(data.title);
          setConText(data.content);
          setTypeText(data.category);
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/cs/faq" className="conTitleMenu">
            FAQ
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />

          {user && user.is_master ? (
            <Link to={updateUrl} className="linkButton">
              수정
            </Link>
          ) : (
            <></>
          )}

          <div className="conTitleMenu"></div>
          <Link to="/cs/faq" className="linkButton">
            뒤로
          </Link>
        </div>

        <div className="conDes">
          <div className="conDesTitle">
            <div className="flexwrap">
              <div className="fwb conInputTip">분류</div>
              <div className="flex1" />
            </div>
            <div>
              <input name="typeInput" type="text" value={typeText} disabled />
            </div>
          </div>
          <div className="conDesTitle">
            <div className="flexwrap">
              <div className="fwb conInputTip">제목</div>
              <div className="flex1" />
            </div>
            <div>
              <input name="titleInput" type="text" value={titleText} disabled />
            </div>
          </div>
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">내용</div>
              <div className="flex1" />
            </div>
            <div>
              <textarea name="desInput" disabled value={conText}></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQDetailComponent;
