import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";

function ProductCreateComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [isMedical, setIsMedical] = useState(true);
  const [releaseType, setReleaseType] = useState("demo");
  const [demoDate, setDemoDate] = useState("");
  const [salesDate, setSalesDate] = useState("");
  const [warrantyMonths, setWarrantyMonths] = useState(0);
  const [version, setVersion] = useState("1.0.9");
  const [checkedTests, setCheckedList] = useState([]);

  const [name, setName] = useState("1");
  const [nameInfo, setNameInfo] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);

  const [qualityMasterUser, setQualityMasterUser] = useState("");
  const [qualityMasterUserOptions, setQualityMasterUserOptions] = useState([]);

  const [testCheckBoxes, setTestCheckBoxes] = useState([]);

  const [clientName, setClientName] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [clientUrl, setClientUrl] = useState("/accountmanagement/client/");

  useEffect(() => {
    if (!params.clientId) {
      alert("잘못된 접근입니다.");
      navigate("/productmanagement/product");
    }

    callInfo();
  }, []);

  const callInfo = async () => {
    try {
      // target은 클라이언트 ID
      const target = params.clientId;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.clientDetail };
      apiInfo.url += target + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const data = res.data;
        setClientName(data.name);
        setClientCode(data.client_code);
        setClientUrl("/productmanagement/client/detail/" + target + "/");
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
    callType();
    callTest();
    callRaMaster();
  };

  // 체크된 검사들을 배열에 넣어 저장
  const testClicked = (e) => {
    const newArr = Array.from(
      document.querySelectorAll('input[name="testInput"]:checked')
    ).map((item) => item.value);

    setCheckedList(newArr);
  };

  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "nameInput") {
      setName(value);

      const productData = nameInfo.find(
        (product) => product.id === Number(value)
      );
      if (productData.is_medical) {
        setIsMedical(true);
      } else {
        setIsMedical(false);
      }
    } else if (name === "releaseTypeInput") {
      setReleaseType(value);
      // 판매일 경우 보증기간 12개월로 설정
      if (value === "sale") {
        setWarrantyMonths(12);
      }
    } else if (name === "demoDateInput") {
      setDemoDate(value);
    } else if (name === "salesDateInput") {
      setSalesDate(value);
    } else if (name === "warrantyMonthsInput") {
      setWarrantyMonths(value);
    } else if (name === "versionInput") {
      setVersion(value);
    } else if (name === "qualityMasterUserInput") {
      setQualityMasterUser(value);
    }
  };

  const callType = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.productTypeList);

      if (res.status === 200) {
        makeProductTypeOptions(res.data);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const callTest = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.productTestList);

      if (res.status === 200) {
        makeProductTestOptions(res.data);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const callRaMaster = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.masterUserRaList);

      if (res.status === 200) {
        makeProductRaOptions(res.data);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const makeProductRaOptions = (qualityMasterUserList) => {
    let options = [];
    for (let i = 0; i < qualityMasterUserList.length; i++) {
      const qualityMasterUser = qualityMasterUserList[i];
      options.push(
        <option value={qualityMasterUser.id}>{qualityMasterUser.name}</option>
      );
    }
    setQualityMasterUser(qualityMasterUserList[0].id);
    setQualityMasterUserOptions(options);
  };

  const makeProductTypeOptions = (typeList) => {
    setNameInfo(typeList);
    let options = [];
    for (let i = 0; i < typeList.length; i++) {
      const productName = typeList[i];
      options.push(
        <option value={productName.id} name={productName.is_medical}>
          {productName.product_name}
        </option>
      );
    }

    setNameOptions(options);
  };

  const makeProductTestOptions = (testList) => {
    let inputs = [];

    // testList 중 udi_di가 있는 test를 authorizedTest에 넣음
    const authorizedTests = testList.filter((test) => test.model_name);
    const unauthorizedTests = testList.filter((test) => !test.model_name);

    inputs.push(
      <div
        style={{ fontWeight: "bold", margin: "10px 0", color: "blueviolet" }}
      >
        허가 검사
      </div>
    );

    for (let i = 0; i < authorizedTests.length; i++) {
      const productTest = authorizedTests[i];
      inputs.push(
        <>
          <input
            name="testInput"
            type="checkbox"
            onClick={testClicked}
            style={{ width: "25px" }}
            value={productTest.id}
          />
          {productTest.test_name} <br />
        </>
      );
    }

    inputs.push(
      <details>
        <summary
          style={{ fontWeight: "bold", margin: "10px 0", color: "grey" }}
        >
          미허가 검사(개발중)
        </summary>
        {unauthorizedTests.map((productTest) => (
          <div>
            <input
              name="testInput"
              type="checkbox"
              onClick={testClicked}
              style={{ width: "25px" }}
              value={productTest.id}
            />
            {productTest.test_name} <br />
          </div>
        ))}
      </details>
    );
    // for (let i = 0; i < unauthorizedTests.length; i++) {
    //   const productTest = unauthorizedTests[i];
    //   inputs.push(
    //     <>
    //       <input
    //         name="testInput"
    //         type="checkbox"
    //         onClick={testClicked}
    //         style={{ width: "25px" }}
    //         value={productTest.id}
    //       />
    //       {productTest.test_name} <br />
    //     </>
    //   );
    // }

    setTestCheckBoxes(inputs);
  };

  const confirmOnClick = async () => {
    const validateData = () => {
      // 고객사명 100자 이하
      // PC, HMD는 숫자만 입력
      if (!name || !releaseType) {
        alert("제품명, 출고유형은 필수입니다.");
        return false;
      }

      if ((releaseType === "demo" || releaseType === "display") && !demoDate) {
        alert("데모 제품의 경우 데모(전시)출고일은 필수입니다.");
        return false;
      }

      if (releaseType === "sale" && (!salesDate || !warrantyMonths)) {
        alert("판매 제품의 경우 판매출고일, AS 보중기간은 필수입니다.");
        return false;
      }

      const productData = nameInfo.find(
        (product) => product.id === Number(name)
      );
      if (productData.is_medical) {
        if (checkedTests.length < 1 || !qualityMasterUser) {
          alert("의료기기의 경우 품질관리자, 검사 선택은 필수입니다.");
          return false;
        }
      }

      return true;
    };

    if (!validateData()) {
      return;
    }

    try {
      const callAPI = new CallAPI(setLoading);
      const body = {
        client_id: params.clientId,
        product_type_id: name,
        type: releaseType,
        version: version,
        warranty_months: warrantyMonths,
        demo_date: demoDate,
        sales_date: salesDate,
        quality_master_user_id: qualityMasterUser,
        test_info_id: checkedTests,
      };
      if (salesDate) body.sales_date = salesDate;

      const res = await callAPI.request(api.productCreate, body);

      if (res.status === 200) {
        alert("성공적으로 생성했습니다.");
        navigate("/productmanagement/product");
      } else {
        throw new Error(res);
      }
      // }
    } catch (err) {
      console.log("err", err);
      if (
        err.response.status === 400 &&
        err.response.data.error &&
        err.response.data.error.includes("Cannot create more")
      ) {
        alert("생성 가능한 기기 수를 초과했습니다.");
      } else if (err.response.status === 403) {
        alert("생성에 실패했습니다.\n 새로고침 후 다시 시도 해주세요.");
      } else {
        alert("생성에 실패했습니다.\n다시 시도 해주세요.");
      }
    }
  };

  const cancelOnClick = () => {
    if (demoDate || salesDate || warrantyMonths) {
      const confirm = window.confirm(
        "취소 시 작성하신 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }
    navigate("/productmanagement/client/");
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/product" className="conTitleMenu">
            제품
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            생성
          </Link>
          <div className="flex1" />
        </div>
        <div className="flexwrap">
          <div className="conDes width50">
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">클라이언트</div>
                <div className="flex1" />
              </div>
              <div>
                <Link className="detailButton" to={clientUrl}>
                  {clientName + " (" + clientCode + ")"}
                </Link>
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">
                  제품명<span className="red ml5">*</span>
                </div>
                <div className="flex1" />
              </div>
              <div>
                <select name="nameInput" value={name} onChange={changeValue}>
                  {nameOptions}
                </select>
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">
                  출고 유형<span className="red ml5">*</span>
                </div>
                <div className="flex1" />
              </div>
              <div>
                <select
                  name="releaseTypeInput"
                  value={releaseType}
                  onChange={changeValue}
                >
                  <option value="demo">데모</option>
                  <option value="sale">판매</option>
                  <option value="display">전시</option>
                  <option value="return">회수</option>
                </select>
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">데모(전시)출고일</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="demoDateInput"
                  type="date"
                  value={demoDate}
                  onChange={changeValue}
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">판매출고일</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="salesDateInput"
                  type="date"
                  value={salesDate}
                  onChange={changeValue}
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">AS 보증기간</div>
                <div className="flex1" />
              </div>
              <div style={{ textAlign: "right" }}>
                {/* 1부터 120까지 있는 options */}
                <select
                  name="warrantyMonthsInput"
                  value={warrantyMonths}
                  onChange={changeValue}
                >
                  {[...Array(121).keys()].map((num) => (
                    <option key={num} value={num}>
                      {num} 개월
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">버전</div>
              </div>
              <div>
                <input
                  name="versionInput"
                  value={version}
                  onChange={changeValue}
                />
              </div>
            </div>
            {isMedical ? (
              <div className="conDesDes">
                <div className="flexwrap">
                  <div className="fwb conInputTip">품질관리자</div>
                </div>
                <div>
                  <select
                    name="qualityMasterUserInput"
                    value={qualityMasterUser}
                    onChange={changeValue}
                  >
                    {qualityMasterUserOptions}
                  </select>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {isMedical ? (
            <div id="test" className="conDes width50 ml10">
              <div className="fwb conDesTitle">검사 선택</div>
              <div className="conDesDes" style={{ textAlign: "left" }}>
                {testCheckBoxes}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="conDesDes pt10 pb10">
          <div className="center">
            <div className="createButton" onClick={confirmOnClick}>
              생성
            </div>
            <div className="cancelButton" onClick={cancelOnClick}>
              취소
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCreateComponent;
