import {React, useEffect, useState} from 'react'
import useWindowDimensions from '../../modules/useWindowDimensions'
import { useNavigate, useLocation, Link } from "react-router-dom";
import { enterChecker } from "../../modules/common";
import "./auth.css";
import axios from "axios";

import api from "../../store/api";

function LoginComponent() {
  const navigate = useNavigate();
  const location = useLocation();
const [loading, setLoading] = useState(false);
const path = location.state ? location.state.path : null;

const { height, width } = useWindowDimensions();

const [id, setId] = useState("");
const [pass, setPass] = useState("");
const [loginType, setLoginType] = useState("master");
const [masterStyle, setMasterStyle] = useState({
  backgroundColor: "gray",
  color: "white",
});
const [clientStyle, setClientStyle] = useState({
  backgroundColor: "white",
  color: "gray",
});

const loginTypeChange = (type) => {
  setLoginType(type);
  if (type === "master") {
    setMasterStyle({ backgroundColor: "gray", color: "white" });
    setClientStyle({ backgroundColor: "white", color: "gray" });
  } else {
    setMasterStyle({ backgroundColor: "white", color: "gray" });
    setClientStyle({ backgroundColor: "gray", color: "white" });
  }
};

const onChangeId = (e) => {
  setId(e.target.value);
};
const onChangePass = (e) => {
  setPass(e.target.value);
};

const loginProc = () => {
  if (id === "") {
    alert("아이디를 입력해주세요.");
    return;
  }
  if (pass === "") {
    alert("비밀번호를 입력해주세요.");
    return;
  }

  // 로그인 처리 전에 localStrage에 저장된 토큰 삭제
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  // API 엔드포인트 및 인증 정보
  let apiUrl = api.masterLogin.url;
  if (loginType === "client") {
    apiUrl = api.clientLogin.url;
  }

  const body = { user_id: id, password: pass };

  axios
    .post(apiUrl, body)
    .then((res) => {
      if (res.status === 200 && res.data && res.data.access_token) {
        // 성공
        const accessToken = res.data.access_token;
        const refreshToken = res.data.refresh_token;
        const idToken = res.data.user_id;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        // 로그인 성공 시 접근했던 이전 페이지로 이동
        if (path) {
          location.state = {};
          navigate(path, { replace: true, state: null });
          return;
        }
        if (loginType === "client") {
          // 클라이언트의 경우 내 제품 페이지로 이동
          navigate("/myproduct/", { replace: true });
          return;
        }
        // 마스터의 경우 제품 관리 페이지로 이동
        navigate("/productmanagement/", { replace: true });
        return;
      } else {
        throw new Error("etc");
      }
    })
    .catch((err) => {
      console.log(err.message);
      alert("로그인에 실패했습니다.\n아이디 비밀번호를 확인해주세요.");
    });
};

  useEffect(() => {
    // 토큰을 가지고 있는지 확인해서 가지고 있으면 대시보드 페이지로 이동시키기
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken) {
      // 토큰 갱신
      console.log("로그인페이지 - 세션 토큰 갱신");
      const apiUrl = api.refreshToken.url;
      const body = { refresh_token: refreshToken };

      axios
        .post(apiUrl, body)
        .then((res) => {
          if (res.status === 200 && res.data && res.data.access_token) {
            // 성공
            const accessToken = res.data.access_token;
            const refreshToken = res.data.refresh_token;

            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            navigate("/productmanagement/");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
          }
        });
    }
  }, []);

  return (
    <div className="flexwrap tac">
      <div className="flex1" />
      <div
        className="flexcolwrap"
        style={{ height: width / 2, width: "450px" }}
      >
        <div className="flex1" />
        <h1 className="fs25">TENETUS Admin Service</h1>
        <div className="inputTip pl10 pt10">로그인 유형</div>
        <div className="p10">
          <button
            className="width50 fs13"
            style={masterStyle}
            onClick={() => {
              loginTypeChange("master");
            }}
          >
            {" "}
            마스터{" "}
          </button>
          <button
            className="width50 fs13"
            style={clientStyle}
            onClick={() => {
              loginTypeChange("client");
            }}
          >
            {" "}
            클라이언트{" "}
          </button>
        </div>
        <div className="inputTip pl10 pt10">아이디</div>
        <div className="p10">
          <input
            className="width244 fs13"
            onChange={onChangeId}
            placeholder="아이디를 입력해주세요."
            onKeyDown={(e) => enterChecker(e, loginProc)}
          />
        </div>
        <div className="inputTip pl10 pt10">비밀번호</div>
        <div className="p10">
          <input
            className="width244 fs13"
            onChange={onChangePass}
            type="password"
            placeholder="비밀번호를 입력해주세요."
            onKeyDown={(e) => enterChecker(e, loginProc)}
          />
        </div>

        <div className="p10">
          <button className="width266 fs13" onClick={loginProc}>
            {" "}
            로그인{" "}
          </button>
        </div>
        <div className="fs13 p10 fcgray">
          로그인을 할 수 없다면 테네터스로 문의 부탁드립니다.
        </div>
        <Link
          to="/build"
          style={{
            fontSize: "10px",
            textDecoration: "none",
            color: "gray",
            paddingTop: "20px",
          }}
        >
          설정파일 생성 페이지 바로가기
        </Link>
        <div className="flex1" />
      </div>
      <div className="flex1" />
    </div>
  );
}

export default LoginComponent
