import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import { phoneWithHyphen } from "../../../modules/formatter";
import LoadingSpinner from "../../loading/spinner";

function ClientCreateComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [pcLimit, setPcLimit] = useState(0);
  const [hmdLimit, setHmdLimit] = useState(0);

  const [isAgency, setIsAgency] = useState(false);
  const [agency, setAgency] = useState("0");
  const [agencies, setAgencies] = useState([]);
  const [agencyOptions, setAgencyOptions] = useState([]);

  useEffect(() => {
    callAgencyInfo();
  }, []);

  const callAgencyInfo = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.clientAgencyList);

      if (res.status === 200) {
        const data = res.data;
        makeAgencyOptions(data);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const makeAgencyOptions = (agencyList) => {
    setAgencies(agencyList);
    let options = [];
    for (let i = 0; i < agencyList.length; i++) {
      const agencyName = agencyList[i];
      options.push(<option value={agencyName.id}>{agencyName.name}</option>);
    }

    setAgencyOptions(options);
  };

  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "nameInput") {
      setName(value);
    } else if (name === "isAgencyInput") {
      setIsAgency(e.target.checked);
      if (value) {
        agencies ? setAgency(agencies[0].id) : setAgency("0");
        document.getElementById("agencyInput").disabled = false;
      } else {
        setAgency("0");
        document.getElementById("agencyInput").disabled = true;
      }
    } else if (name === "agencyInput") {
      setAgency(value);
    } else if (name === "phoneInput") {
      setPhone(phoneWithHyphen(value));
    } else if (name === "pcInput") {
      setPcLimit(value);
    } else if (name === "hmdInput") {
      setHmdLimit(value);
    }
  };

  const confirmOnClick = async () => {
    const formattedPhone = phone ? phone.replace(/-/g, "") : null;
    const validateData = () => {
      // 고객사명 100자 이하
      // PC, HMD는 숫자만 입력
      if (name.length < 1 || name.length > 100) {
        alert("고객사명은 100자 이하로 작성해주세요.");
        return false;
      }

      if (isAgency && agency === "") {
        alert("대리점을 선택해주세요.");
        return false;
      }

      if (formattedPhone && isNaN(formattedPhone)) {
        alert("연락처는 숫자만 입력해주세요.");
        return false;
      }

      if (isNaN(pcLimit) || isNaN(hmdLimit)) {
        alert("PC, HMD는 숫자로 입력해주세요.");
        return false;
      }

      if (isAgency && agency === "") {
        alert(
          "대리점을 선택해주세요.\n대리점이 아닌 클라이언트의 경우 대리점 여부를 해제해주세요."
        );
        return false;
      }

      return true;
    };

    if (!validateData()) {
      return;
    }

    try {
      const callAPI = new CallAPI(setLoading);
      const body = {
        name: name,
        phone: formattedPhone,
        pc_limit: Number(pcLimit),
        hmd_limit: Number(hmdLimit),
      };
      if (isAgency) {
        body.client_agency_id = agency;
      }

      const res = await callAPI.request(api.clientCreate, body);
      if (res.status === 200) {
        alert("성공적으로 생성했습니다.");
        navigate("/productmanagement/client");
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
      alert("생성에 실패했습니다.\n다시 시도 해주세요.");
    }
  };

  const cancelOnClick = () => {
    if (name || phone || pcLimit || hmdLimit) {
      const confirm = window.confirm(
        "취소 시 작성하신 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }
    navigate("/productmanagement/client/");
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/client" className="conTitleMenu">
            클라이언트
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            생성
          </Link>
          <div className="flex1" />
        </div>
        <div className="flexwrap">
          <div className="conDes lowerCenterWidth50">
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">
                  고객사명(100자)<span className="red ml5">*</span>
                </div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="nameInput"
                  type="text"
                  placeholder="고객사명을 입력해주세요."
                  value={name}
                  onChange={changeValue}
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">대리점</div>
                <div className="flex1" />
              </div>
              <div className="flexwrap">
                <label className="radioButton width50">
                  <span className="vam">대리점 여부</span>
                  <input
                    name="isAgencyInput"
                    type="checkbox"
                    className="block"
                    value={isAgency}
                    onChange={changeValue}
                  />
                </label>
                <select
                  id="agencyInput"
                  name="agencyInput"
                  value={agency}
                  onChange={changeValue}
                  className="width70 ml5"
                  disabled={!isAgency}
                >
                  <option value="0">-</option>
                  {agencyOptions}
                </select>
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">연락처</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="phoneInput"
                  type="text"
                  placeholder="연락처를 입력해주세요."
                  value={phone}
                  onChange={changeValue}
                />
              </div>
            </div>

            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">설치가능 PC</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="pcInput"
                  type="number"
                  placeholder="PC 설치 허용할 대수를 입력해주세요."
                  value={pcLimit}
                  onChange={changeValue}
                  min={0}
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">설치가능 HMD</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="hmdInput"
                  type="number"
                  placeholder="HMD 설치 허용할 대수"
                  value={hmdLimit}
                  onChange={changeValue}
                  min={0}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="conDesDes pt10 pb10">
          <div className="center">
            <div className="createButton" onClick={confirmOnClick}>
              생성
            </div>
            <div className="cancelButton" onClick={cancelOnClick}>
              취소
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientCreateComponent;
