import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat, datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { downloadXLSX } from "../../../modules/downloadExcel";
import { enterChecker } from "../../../modules/common";

function UdiComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [udiDataList, setUdiDataList] = useState([]);

  const [udiTable, setUdiList] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");

  // page view
  const [pageSec, setPageSec] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("client-name");
  const pageRowMax = 10;

  const handleDownloadXLSX = () => {
    downloadXLSX(udiDataList, "UDI");
  };

  const makeTable = (datas, targetPage = null) => {
    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="flex1 br1g">UDI DI</div>
        <div className="flex1 br1g">UDI PI</div>
        <div className="flex1 br1g">검사</div>
        <div className="flex1 br1g">발급일</div>
        <div className="flex1 br1g">클라이언트</div>
        <div className="flex1 br1g">제품</div>
        <div className="flex1">생성 정보</div>
      </div>
    );
    tableArr.push(titleSec);

    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }

    const thisPage = datas.slice(f, l);

    // contents
    // testudiTable -> data
    thisPage.forEach((udi) => {
      const masterUser = udi.master_user ? udi.master_user : {};
      const client = udi.client ? udi.client : {};
      const product = udi.product ? udi.product : {};
      const test = udi.test ? udi.test : {};

      const udiLink = "/productmanagement/udi/detail/" + udi.id;
      const productLink = "/productmanagement/product/detail/" + product.id;
      const clientLink = "/productmanagement/client/detail/" + client.id;
      const masterAccountLink =
        "/accountmanagement/master/detail/" + masterUser.id;

      const desSec = (
        <Link
          key={udi.id}
          to={udiLink}
          className="flexwrap tableCon productTable"
        >
          <div className="flex1 br1g">
            {test && test.test_info ? test.test_info.udi_di : ""}
          </div>
          <div className="flex1 br1g">{udi.udi_pi}</div>
          <div className="flex1 br1g">
            {test && test.test_info ? test.test_info.test_name : ""}
          </div>
          <div className="flex1 br1g">{dateFormat(udi.issue_date)}</div>
          <div className="flex1 br1g">
            {client ? (
              <Link className="detailButton" to={clientLink}>
                {client.name + " (" + client.client_code + ")"}
              </Link>
            ) : (
              "-"
            )}
          </div>
          <div className="flex1 br1g">
            {product && product.product_type ? (
              <Link className="detailButton" to={productLink}>
                {product.product_type.product_name}
              </Link>
            ) : (
              "-"
            )}
          </div>
          <div className="flex1">
            {datetimeFormat(udi.created_date)}
            <br />
            {masterUser.name ? (
              <Link className="detailButton" to={masterAccountLink}>
                {masterUser.name}
              </Link>
            ) : (
              "-"
            )}
          </div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setUdiList(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;
    // page set

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(pageSelected / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (pageSelected > 9) {
      const prePage = (Math.floor(pageSelected / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" "}
          {"<"}{" "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === pageSelected) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" "}
          {">"}{" "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.udiList);

      if (res.status === 200) {
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });
        }

        setUdiDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const makeSearchedArr = (udiDataList, searchType, searchText) => {
    let searchResultArr = [];
    searchText = searchText + "";

    for (let i = 0; i < udiDataList.length; i++) {
      const udi = udiDataList[i];
      const test = udi.test ? udi.test : {};
      const product = udi.product ? udi.product : {};
      const client = udi.client ? udi.client : {};

      let add = false;
      switch (searchType) {
        case "udi":
          if (udi.id == searchText) {
            add = true;
          }
          break;
        case "client":
          if (client && client.id == searchText) {
            add = true;
          }
          break;
        case "product":
          if (product && product.id == searchText) {
            add = true;
          }
          break;
        case "udi-di":
          if (
            test.test_info &&
            test.test_info.udi_di &&
            test.test_info.udi_di.includes(searchText)
          ) {
            add = true;
          }
          break;
        case "udi-pi":
          if (udi.udi_pi && udi.udi_pi.includes(searchText)) {
            add = true;
          }

          break;
        case "test-name":
          if (test && test.test_info && test.test_info.test_name) {
            // test name과 searchtext 둘다 대문자로 변경해서 비교
            let upperTest = test.test_info.test_name.toUpperCase();
            let upperSearch = searchText.toUpperCase();

            if (test && test.id && upperTest.includes(upperSearch)) {
              add = true;
            }
          }
          break;
        case "product-name":
          if (product && product.id && product.name.includes(searchText)) {
            add = true;
          }
          break;
        case "client-name":
          if (client && client.id && client.name.includes(searchText)) {
            add = true;
          }
          break;
        case "client-code":
          if (client && client.id && client.client_code.includes(searchText)) {
            add = true;
          }
          break;
        default:
          break;
      }
      if (add) {
        searchResultArr.push(udi);
      }
    }

    return searchResultArr;
  };

  const pageClicked = (target) => {
    let search = "";
    let keyword = "";

    if (searchType !== "" && searchText !== "") {
      search = searchType;
      keyword = searchText;
    }

    if (search && keyword) {
      navigate(
        "/productmanagement/udi/" + (target + 1) + "/" + search + "/" + keyword
      );
    } else {
      navigate("/productmanagement/udi/" + (target + 1));
    }
    if (udiDataList.length !== 0) {
      let list = udiDataList;

      if (search !== "" && keyword !== "") {
        list = makeSearchedArr(list, search, keyword);
      }

      makeTable(list, target);
      makePageSec(list, target);
    } else {
      callList();
    }
  };

  const searchClick = () => {
    if (searchText === "") {
      if (udiDataList.length !== 0) {
        makeTable(udiDataList, 0);
        makePageSec(udiDataList, 0);
      } else {
        callList();
      }
      navigate("/productmanagement/udi/1/", { replace: true });
      // reset
      return;
    }

    navigate("/productmanagement/udi/1/" + searchType + "/" + searchText, {
      replace: true,
    });

    if (udiDataList.length !== 0) {
      let list = udiDataList;
      if (searchType && searchText) {
        list = makeSearchedArr(list, searchType, searchText);
      }
      makeTable(list, 0);
      makePageSec(list, 0);
    } else {
      callList();
    }
  };

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  // 최초 데이터 셋
  useEffect(() => {
    callList();

    if (params.search && params.keyword) {
      setSearchText(params.keyword);
      setSearchType(params.search);
    } else {
      setSearchText("");
      setSearchType("client-name");
    }
  }, []);

  useEffect(() => {
    let list = udiDataList;

    if (params.search && params.keyword) {
      list = makeSearchedArr(list, params.search, params.keyword);
    }

    if (udiTable.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [udiDataList]);

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/udi" className="conTitleMenu">
            UDI
          </Link>
          <div className="conTitleMenu"></div>
          <div className="flex1" />
          <div className="downloadButton" onClick={handleDownloadXLSX}>
            엑셀다운로드
          </div>
        </div>

        {!loading ? (
          <div className="tableWrap">
            <div className="tableCount">{dataCount}</div>
            {udiTable}

            <div className="pageSec">
              <div className="flex1" />
              {pageSec}
              <div className="flex1" />
            </div>

            {params.search !== "client" &&
            params.search !== "product" &&
            params.search !== "udi" ? (
              <div className="searchSec ">
                <div className="flex1" />
                <div className="">
                  <select
                    name="searchType"
                    style={{ width: "170px", padding: "5px" }}
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    value={searchType}
                  >
                    <option value="client-name">클라이언트 고객사명</option>
                    <option value="client-code">클라이언트 코드</option>
                    <option value="udi-di">UDI DI</option>
                    <option value="udi-pi">UDI PI</option>
                    <option value="test-name">검사</option>
                    <option value="product-name">제품명</option>
                  </select>
                </div>
                <div className="ml10">
                  <input
                    placeholder="검색어를 입력하세요"
                    name="searchText"
                    className="searchinput"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      enterChecker(e, searchClick);
                    }}
                  />
                </div>
                <div className="ml10">
                  <div
                    style={{ cursor: "pointer" }}
                    className="searchButton plainbtn"
                    onClick={searchClick}
                  >
                    검색
                  </div>
                </div>
                <div className="flex1" />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default UdiComponent;
