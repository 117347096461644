import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat, datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { getProfile } from "../../../modules/decodeToken";
import { enterChecker } from "../../../modules/common";

function MasterComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const pageRowMax = 10;

  const [masterDataList, setMasterDataList] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");
  const [masterList, setMasterList] = useState([]);
  const [password, setPassword] = useState("");

  // page view
  const [pageSec, setPageSec] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("user-id");

  const makeTable = (datas, targetPage = null) => {
    const page =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    let f = page * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }
    const thisPage = datas.slice(f, l);

    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="flex1 br1g">아이디</div>
        <div className="flex1 br1g">팀</div>
        <div className="flex1 br1g">이름</div>
        <div className="flex1 br1g">생성일</div>
        <div className="flex1 br1g">최근로그인</div>
        <div className="flex1">활성 여부</div>
      </div>
    );
    tableArr.push(titleSec);

    // contents
    // testmasterList -> data
    thisPage.forEach((master) => {
      const masterTeam = master.master_team ? master.master_team : {};
      const masterLoginInfo = master.login_info ? master.login_info : {};

      const linkTarget = "/accountmanagement/master/detail/" + master.id;
      const desSec = (
        <Link key={master.id} to={linkTarget} className="flexwrap tableCon">
          <div className="flex1 br1g tac">{master.user_id}</div>
          <div className="flex1 br1g tac">{masterTeam.name ?? "-"}</div>
          <div className="flex1 br1g tac">{master.name}</div>
          <div className="flex1 br1g tac">
            {master.created_date ? datetimeFormat(master.created_date) : ""}
          </div>
          <div className="flex1 br1g tac">
            {masterLoginInfo.last_login
              ? datetimeFormat(masterLoginInfo.last_login)
              : "-"}
          </div>
          <div className="flex1 tac">{master.is_active ? "O" : "X"}</div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setMasterList(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(pageSelected / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (pageSelected > 9) {
      const prePage = (Math.floor(pageSelected / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" "}
          {"<"}{" "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === pageSelected) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" "}
          {">"}{" "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  // 최초 데이터 셋
  useEffect(() => {
    setUserProfile();
    callList();

    if (params.search && params.keyword) {
      setSearchText(params.keyword);
      setSearchType(params.search);
    } else {
      setSearchText("");
      setSearchType("user-id");
    }
  }, []);

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.masterUserList);

      if (res.status === 200) {
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });
        }
        setMasterDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // 테스트 데이터 만들기
  useEffect(() => {
    let list = masterDataList;

    if (params.search && params.keyword) {
      list = makeSearchedArr(list, params.search, params.keyword);
    }
    if (masterList.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [masterDataList]);

  const pageClicked = (target) => {
    let search = "";
    let keyword = "";

    if (searchType !== "" && searchText !== "") {
      search = searchType;
      keyword = searchText;
    }
    if (search && keyword) {
      navigate(
        "/accountmanagement/master/" +
          (target + 1) +
          "/" +
          search +
          "/" +
          keyword
      );
    } else {
      navigate("/accountmanagement/master/" + (target + 1));
    }

    if (masterDataList.length !== 0) {
      let list = masterDataList;

      if (search !== "" && keyword !== "") {
        list = makeSearchedArr(list, search, keyword);
      }
      makePageSec(masterDataList, target);
      makeTable(masterDataList, target);
    } else {
      callList();
    }
  };

  const makeSearchedArr = (datas, searchType, searchText) => {
    let searchResultArr = [];
    searchText = searchText + "";

    for (let i = 0; i < datas.length; i++) {
      const data = datas[i];

      let add = false;
      switch (searchType) {
        case "master":
          if (data.id == searchText) {
            add = true;
          }
          break;
        case "user-id":
          if (data.user_id.includes(searchText)) {
            add = true;
          }
          break;
        case "team":
          if (
            data.master_team.name &&
            data.master_team.name.includes(searchText)
          ) {
            add = true;
          }

          break;
        case "name":
          if (data.name.includes(searchText)) {
            add = true;
          }

          break;
        default:
          break;
      }
      if (add) {
        searchResultArr.push(data);
      }
    }

    return searchResultArr;
  };

  const searchClick = () => {
    if (searchText === "") {
      if (masterDataList.length !== 0) {
        makeTable(masterDataList, 0);
        makePageSec(masterDataList, 0);
      } else {
        callList();
      }
      // reset
      navigate("/accountmanagement/master/1/", { replace: true });
      return;
    }

    navigate("/accountmanagement/master/1/" + searchType + "/" + searchText, {
      replace: true,
    });

    if (masterDataList.length !== 0) {
      let list = masterDataList;
      if (searchType && searchText) {
        list = makeSearchedArr(list, searchType, searchText);
      }
      makeTable(list, 0);
      makePageSec(list, 0);
    } else {
      callList();
    }
  };

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/accountmanagement/master" className="conTitleMenu">
            관리자 계정
          </Link>
          <div className="conTitleMenu"></div>
          <div className="flex1" />
          {user && user.is_super ? (
            <Link
              to="/accountmanagement/master/create"
              className="createButton"
            >
              생성
            </Link>
          ) : (
            <></>
          )}
        </div>

        {!loading ? (
          <div className="tableWrap">
            <div className="tableCount">{dataCount}</div>
            {masterList}
            <div className="pageSec">
              <div className="flex1" />
              {pageSec}
              <div className="flex1" />
            </div>
            <div className="searchSec ">
              <div className="flex1" />
              <div className="">
                <select
                  name="searchType"
                  style={{ width: "150px", padding: "5px" }}
                  onChange={(e) => {
                    setSearchType(e.target.value);
                  }}
                  value={searchType}
                >
                  <option value="user-id">아이디</option>
                  <option value="team">팀</option>
                  <option value="name">이름</option>
                </select>
              </div>
              <div className="ml10">
                <input
                  placeholder="검색어를 입력하세요"
                  name="searchText"
                  className="searchinput"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyDown={enterChecker}
                />
              </div>
              <div className="ml10">
                <div
                  style={{ cursor: "pointer" }}
                  className="searchButton plainbtn"
                  onClick={searchClick}
                >
                  검색
                </div>
              </div>
              <div className="flex1" />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default MasterComponent;
