import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";

function BuildInfoComponent() {
  const { width } = useWindowDimensions();

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/accountmanagement/build/list" className="conTitleMenu">
            빌드 관리
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            빌드 정보
          </Link>
          <div className="flex1" />
          {/* <Link to="/cs/qna/write/1" className='linkButton'>수정</Link>
                    <div className="conTitleMenu"></div> */}
          <Link to="/accountmanagement/build/list" className="linkButton">
            뒤로
          </Link>
        </div>

        <div className="conDes">
          <div className="conDesTitle">d</div>
          <div className="conDesDes">f</div>
        </div>
      </div>
    </div>
  );
}

export default BuildInfoComponent;
