import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";

function UdiCreateComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [udiDi, setUdiDi] = useState("");
  const [udiPi, setUdiPi] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [productName, setProductName] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [testName, setTestName] = useState("");
  const [test, setTest] = useState({});

  const [productUrl, setProductUrl] = useState("");
  const [clientUrl, setClientUrl] = useState("");

  useEffect(() => {
    if (!params.productId || !params.testId) {
      alert("잘못된 접근입니다.");
      navigate("/productmanagement/udi");
    }

    callInfo();
  }, []);

  const callInfo = async () => {
    try {
      // 제품 ID와 검사 ID 가져와서 검증
      const productId = params.productId;
      const testId = params.testId;

      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.productDetail };
      apiInfo.url += productId + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const data = res.data;
        const tests = data.test;
        // data.test 에서 testId와 같은 객체를 찾아서 가져옴
        const test = tests.find((item) => item.id === Number(testId));

        setProductName(data.product_type.product_name);
        setClientName(data.client.name);
        setClientCode(data.client.client_code);
        setTest(data.test);

        setTestName(test.test_info.test_name);
        setUdiDi(test.test_info.udi_di);
        setProductUrl("/productmanagement/product/detail/" + data.id + "/");
        setClientUrl(
          "/productmanagement/client/detail/" + data.client.id + "/"
        );
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "udiDiInput") {
      setUdiDi(value);
    } else if (name === "udiPiInput") {
      setUdiPi(value);
    } else if (name === "issueDateInput") {
      setIssueDate(value);
    }
  };

  const confirmOnClick = async () => {
    const validateData = () => {
      if (!udiDi || !udiPi || !issueDate) {
        alert("UDI DI, UDI PI, 발급일은 필수입니다.");
        return false;
      }

      return true;
    };

    if (!validateData()) {
      return;
    }

    try {
      const callAPI = new CallAPI(setLoading);
      const body = {
        product_id: params.productId,
        test_id: params.testId,
        udi_di: udiDi,
        udi_pi: udiPi,
        issue_date: issueDate,
      };

      const res = await callAPI.request(api.udiCreate, body);

      if (res.status === 200) {
        alert("성공적으로 생성했습니다.");
        navigate(productUrl);
      } else {
        throw new Error(res);
      }
      // }
    } catch (err) {
      if (err.response.status === 400) {
        // udi_di 객체가 err.response.data 안에 존재하는지 확인
        if (err.response.data.udi_di) {
          alert("이미 존재하는 UDI DI입니다.");
        } else if (err.response.data.udi_pi) {
          alert("이미 존재하는 UDI PI입니다.");
        }
      } else {
        alert("생성에 실패했습니다.\n다시 시도 해주세요.");
      }

      console.log("err", err);
    }
  };

  const cancelOnClick = () => {
    if (udiDi || udiPi || issueDate) {
      const confirm = window.confirm(
        "취소 시 작성하신 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }
    navigate(productUrl);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/product" className="conTitleMenu">
            UDI
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            생성
          </Link>
          <div className="flex1" />
        </div>
        <div className="flexwrap">
          <div className="conDes lowerCenterWidth50">
            {clientName ? (
              <div className="conDesDes">
                <div className="flexwrap">
                  <div className="fwb conInputTip">클라이언트</div>
                  <div className="flex1" />
                </div>
                <div>
                  <Link className="detailButton" to={clientUrl}>
                    {clientName + " (" + clientCode + ")"}
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}
            {productName ? (
              <div className="conDesDes">
                <div className="flexwrap">
                  <div className="fwb conInputTip">제품</div>
                  <div className="flex1" />
                </div>
                <div>
                  <Link className="detailButton" to={productUrl}>
                    {productName}
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">검사</div>
                <div className="flex1" />
              </div>
              <div>
                <span>{testName}</span>
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">UDI DI</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="udiDiInput"
                  type="text"
                  placeholder="UDI DI를 입력해주세요."
                  value={udiDi}
                  disabled={true}
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">
                  UDI PI<span className="red ml5">*</span>
                </div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="udiPiInput"
                  type="text"
                  placeholder="UDI PI를 입력해주세요."
                  value={udiPi}
                  onChange={changeValue}
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">
                  발급일<span className="red ml5">*</span>
                </div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="issueDateInput"
                  type="date"
                  value={issueDate}
                  onChange={changeValue}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="conDesDes pt10 pb10">
          <div className="center">
            <div className="createButton" onClick={confirmOnClick}>
              생성
            </div>
            <div className="cancelButton" onClick={cancelOnClick}>
              취소
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UdiCreateComponent;
