import React from "react";
import { Route, Routes } from "react-router-dom";
// import useWindowDimensions from '../../modules/useWindowDimensions'

import TopComponent from "../menu/top";
import SideMenuComponent from "../menu/side";

import Product from "./product/product.component";
import ProductInfo from "./product/product.detail.component";

function MyproductMainComponent() {
  // const { height, width } = useWindowDimensions();

  return (
    <>
      <TopComponent />

      <div className="flexwrap tac">
        <SideMenuComponent />

        <Routes>
          <Route exact path="/" element={<Product />} />
          <Route
            exact
            path="/product/:page?/:search?/:keyword?"
            element={<Product />}
          />
          <Route
            exact
            path="/product/detail/:target?"
            element={<ProductInfo />}
          />
        </Routes>
      </div>
    </>
  );
}

export default MyproductMainComponent;
