import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { numWithCommas } from "../../../modules/formatter";

function QnaWriteComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const titleMax = 100;
  const desMax = 1500;
  const [titleCounter, setTitleCounter] = useState("0/100");
  const [desCounter, setDesCounter] = useState("0/1,500");
  const [subTitle, setSubTitle] = useState("작성");

  const [titleCounterColor, setTitleCounterColor] = useState({ color: "gray" });
  const [desCounterColor, setDesCounterColor] = useState({ color: "gray" });

  const [titleText, setTitleText] = useState("");
  const [conText, setConText] = useState("");

  const changeValue = (e) => {
    const tLength = e.target.value.length;
    if (e.target.name === "titleInput") {
      if (tLength > titleMax) {
        setTitleCounterColor({ color: "red" });
      } else {
        setTitleCounterColor({ color: "gray" });
      }

      setTitleCounter(tLength + "/" + titleMax);

      setTitleText(e.target.value);
    } else if (e.target.name === "desInput") {
      if (tLength > desMax) {
        setDesCounterColor({ color: "red" });
      } else {
        setDesCounterColor({ color: "gray" });
      }

      setDesCounter(numWithCommas(tLength) + "/" + numWithCommas(desMax));
      setConText(e.target.value);
    }
  };

  useEffect(() => {
    if (!params.target) {
      // 수정 아님
      return;
    }
    setSubTitle("수정");

    const fetchData = async () => {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        const apiInfo = { ...api.qnaDetail };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo);

        if (res.status === 200) {
          let data = res.data;

          setTitleText(data.title);
          setConText(data.content);

          const tltleLength = data.title.length;

          setTitleCounter(tltleLength + "/" + titleMax);

          const desLength = data.content.length;
          setDesCounter(numWithCommas(desLength) + "/" + numWithCommas(desMax));
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
      }
    };
    fetchData();
  }, []);

  const contentWrite = async () => {
    if (
      titleText === "" ||
      conText === "" ||
      titleText === undefined ||
      conText === undefined
    ) {
      alert("제목, 내용은 한글자 이상 포함되어야 합니다.");
      return;
    }

    try {
      const callAPI = new CallAPI(setLoading);
      const body = {
        title: titleText,
        content: conText,
      };
      if (!params.target) {
        // 최초 작성
        const res = await callAPI.request(api.qnaWrite, body);

        if (res.status === 200) {
          callEndProc(res);
        } else {
          throw new Error(res);
        }
      } else {
        // 수정
        const target = params.target;
        const apiInfo = { ...api.qnaUpdate };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo, body);

        if (res.status === 200) {
          callEndProc(res);
        } else {
          throw new Error(res);
        }
      }
    } catch (err) {
      console.log("err", err);
      alert("작성에 실패했습니다.\n다시 시도 해주세요.");
    }
  };

  const callEndProc = (res) => {
    if (res.status === 200) {
      // 성공
      alert("성공적으로 작성되었습니다.");
      // /cs/notice
      navigate("/cs/qna", { replace: true });
    } else if (res.status === 200) {
      // 성공
      alert("수정이 완료되었습니다.");
    }
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/cs/qna" className="conTitleMenu">
            문의사항
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            작성
          </Link>
          <div className="flex1" />
          <Link to="/cs/qna" className="linkButton">
            취소
          </Link>
          <Link to="#" className="linkButton ml10" onClick={contentWrite}>
            {subTitle}
          </Link>
        </div>

        <div className="conDes">
          <div className="conDesTitle">
            <div className="flexwrap">
              <div className="fwb conInputTip">제목 (최대 100자)</div>
              <div className="flex1" />
              <div className="textCounter" style={titleCounterColor}>
                {titleCounter}
              </div>
            </div>
            <div>
              <input
                name="titleInput"
                type="text"
                onChange={changeValue}
                defaultValue={titleText}
              />
            </div>
          </div>
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">내용 (최대 1,500자)</div>
              <div className="flex1" />
              <div className="textCounter" style={desCounterColor}>
                {desCounter}
              </div>
            </div>
            <div>
              <textarea
                name="desInput"
                onChange={changeValue}
                value={conText}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QnaWriteComponent;
