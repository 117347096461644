import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat, datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { downloadXLSX } from "../../../modules/downloadExcel";
import { getProfile } from "../../../modules/decodeToken";
import { enterChecker } from "../../../modules/common";

function DeviceComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [deviceDataList, setDeviceDataList] = useState([]);

  const [deviceTable, setDeviceTable] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");

  // page view
  const [pageSec, setPageSec] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("client-name");

  // 필터
  const [selectedType, setSelectedType] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [sortOption, setSortOption] = useState({
    field: "",
    order: "asc",
  });

  const pageRowMax = 10;

  const STATUS = {
    all: "상태",
    active: "사용중",
    inactive: "해제",
    requested: "해제신청",
    return: "회수",
  };

  // 기기 유형
  const TYPE = {
    all: "기기 유형",
    pc: "PC",
    hmd: "HMD",
  };

  const handleDownloadXLSX = () => {
    downloadXLSX(deviceDataList, "기기");
  };

  // 기기 유형 변경 이벤트 핸들러
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value); // 출고 유형 상태 업데이트
  };

  // 상태 유형 변경 이벤트 핸들러
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // 출고 유형 상태 업데이트
  };

  // 기기 유형, 상태 필터링
  const filteredList = (list) => {
    if (selectedType !== "all") {
      list = list.filter(
        (device) => device.type === selectedType.toLowerCase()
      );
    }

    if (selectedStatus !== "all") {
      list = list.filter((device) => device.status === selectedStatus);
    }

    return list;
  };

  // 기기출고일, 기기회수일 정렬 이벤트 핸들러
  const handleSortChange = (field) => {
    setSortOption({
      field: field,
      order:
        sortOption.field === field && sortOption.order === "asc"
          ? "desc"
          : "asc",
    });
  };

  const compareFunction = (a, b) => {
    const aValue = a[sortOption.field] || "";
    const bValue = b[sortOption.field] || "";

    if (!aValue && !bValue) return 0; // 둘 다 빈 값이면 순서 유지
    if (!aValue) return 1; // a가 빈 값이면 b를 앞으로
    if (!bValue) return -1; // b가 빈 값이면 a를 앞으로

    if (sortOption.order === "asc") {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  };

  const makeTable = (datas, targetPage = null) => {
    // 정렬
    datas.sort(compareFunction);

    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="flex1 br1g">기기 코드</div>
        <div className="flex1 br1g">Mac Address</div>
        <div className="flex1 br1g">
          <select
            className="selectInTable"
            value={selectedType}
            onChange={handleTypeChange}
          >
            {Object.keys(TYPE).map((key) => (
              <option key={key} value={key}>
                {TYPE[key]}
              </option>
            ))}
          </select>
        </div>
        <div className="flex1 br1g">
          <select
            className="selectInTable"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            {Object.keys(STATUS).map((key) => (
              <option key={key} value={key}>
                {STATUS[key]}
              </option>
            ))}
          </select>
        </div>
        <div className="flex1 br1g">
          기기출고일
          <span
            className="sortable"
            onClick={() => handleSortChange("release_date")}
          >
            {sortOption.field === "release_date" && sortOption.order === "asc"
              ? " ▲"
              : " ▼"}
          </span>
        </div>
        <div className="flex1 br1g">
          기기회수일
          <span
            className="sortable"
            onClick={() => handleSortChange("return_date")}
          >
            {sortOption.field === "return_date" && sortOption.order === "asc"
              ? " ▲"
              : " ▼"}
          </span>
        </div>
        <div className="flex1 br1g">클라이언트</div>
        <div className="flex1 br1g">제품</div>
        <div className="flex1">생성 정보</div>
      </div>
    );
    tableArr.push(titleSec);

    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }

    const thisPage = datas.slice(f, l);

    // contents
    // testdeviceTable -> data
    thisPage.forEach((device) => {
      const masterUser = device.master_user ? device.master_user : {};
      const client = device.client ? device.client : {};
      const product = device.product ? device.product : {};

      const deviceLink = "/productmanagement/device/detail/" + device.id;
      const productLink = "/productmanagement/product/detail/" + product.id;
      const clientLink = "/productmanagement/client/detail/" + client.id;
      const masterAccountLink =
        "/accountmanagement/master/detail/" + masterUser.id;
      console.log("device", device)

      const desSec = (
        <Link
          key={device.id}
          to={deviceLink}
          className="flexwrap tableCon productTable"
        >
          <div className="flex1 br1g">
            {device.device_code ? device.device_code : "-"}
          </div>
          <div className="flex1 br1g">
            {device.mac_address ? device.mac_address : "-"}
          </div>
          <div className="flex1 br1g">{TYPE[device.type]}</div>
          <div className="flex1 br1g">{STATUS[device.status]}</div>
          <div className="flex1 br1g">
            {device.release_date ? dateFormat(device.release_date) : "-"}
          </div>
          <div className="flex1 br1g">
            {device.return_date ? dateFormat(device.return_date) : "-"}
          </div>
          <div className="flex1 br1g">
            {client && client.name ? (
              <Link className="detailButton" to={clientLink}>
                {client.name + " (" + client.client_code + ")"}
              </Link>
            ) : (
              "-"
            )}
          </div>
          <div className="flex1 br1g">
            {product && product.product_name ? (
              <Link className="detailButton" to={productLink}>
                {product.product_name}
              </Link>
            ) : (
              "-"
            )}
          </div>
          <div className="flex1">
            {datetimeFormat(device.created_date)}
            <br />
            {masterUser.name ? (
              <Link className="detailButton" to={masterAccountLink}>
                {masterUser.name}
              </Link>
            ) : (
              "-"
            )}
          </div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setDeviceTable(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(pageSelected / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (pageSelected > 9) {
      const prePage = (Math.floor(pageSelected / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" < "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === pageSelected) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" > "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.deviceList);

      if (res.status === 200) {
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });
        }

        setDeviceDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const makeSearchedArr = (deviceDataList, searchType, searchText) => {
    let searchResultArr = [];
    searchText = searchText + "";

    for (let i = 0; i < deviceDataList.length; i++) {
      const device = deviceDataList[i];
      const product = device.product ? device.product : {};
      const client = device.client ? device.client : {};

      let add = false;
      if (searchText === "") {
        add = true;
      } else {
        switch (searchType) {
          case "client":
            if (client && client.id == searchText) {
              add = true;
            }
            break;
          case "product":
            if (product && product.id == searchText) {
              add = true;
            }
            break;
          case "client-name":
            if (client && client.name && client.name.includes(searchText)) {
              add = true;
            }
            break;
          case "client-code":
            if (
              client &&
              client.client_code &&
              client.client_code.includes(searchText)
            ) {
              add = true;
            }
            break;
          case "device-code":
            if (device.device_code && device.device_code.includes(searchText)) {
              add = true;
            }
            break;
          case "mac-address":
            if (device.mac_address && device.mac_address.includes(searchText)) {
              add = true;
            }
            break;
          case "product-name":
            if (
              product &&
              product.product_name &&
              product.product_name.includes(searchText)
            ) {
              add = true;
            }
            break;
          default:
            break;
        }
      }
      if (add) {
        searchResultArr.push(device);
      }
    }

    // 출고 유형, 상태 필터 적용
    searchResultArr = filteredList(searchResultArr);

    // 정렬 기준에 따라 필터된 리스트 정렬하기
    if (sortOption.field) {
      searchResultArr.sort((a, b) => {
        const aValue = a[sortOption.field] || ""; // null 값 처리
        const bValue = b[sortOption.field] || ""; // null 값 처리

        if (sortOption.order === "asc") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }

    return searchResultArr;
  };

  const pageClicked = (target) => {
    let search = "";
    let keyword = "";

    if (searchType !== "" && searchText !== "") {
      search = searchType;
      keyword = searchText;
    }

    // 검색어가 있을 경우 검색어 적용하여 이동
    if (search && keyword) {
      navigate(
        "/productmanagement/device/" +
          (target + 1) +
          "/" +
          search +
          "/" +
          keyword
      );
    } else {
      navigate("/productmanagement/device/" + (target + 1));
    }

    if (deviceDataList.length !== 0) {
      let list = deviceDataList;

      if (search !== "" && keyword !== "") {
        list = makeSearchedArr(list, search, keyword);
      }

      list = filteredList(list);

      makeTable(list, target);
      makePageSec(list, target);
    } else {
      callList();
    }
  };

  const searchClick = () => {
    let list = deviceDataList;

    // 검색어가 없을 때
    if (searchText === "") {
      if (deviceDataList.length !== 0) {
        list = filteredList(list);

        makeTable(list, 0);
        makePageSec(list, 0);
      } else {
        callList();
      }
      navigate("/productmanagement/device/1/", { replace: true });
      // reset
      return;
    }

    navigate("/productmanagement/device/1/" + searchType + "/" + searchText, {
      replace: true,
    });

    if (deviceDataList.length !== 0) {
      if (searchType && searchText) {
        list = makeSearchedArr(list, searchType, searchText);
      }

      makeTable(list, 0);
      makePageSec(list, 0);
    } else {
      callList();
    }
  };

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  useEffect(() => {
    let list = deviceDataList;

    if (params.search && params.keyword) {
      list = makeSearchedArr(list, params.search, params.keyword);
    }

    if (deviceTable.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [deviceDataList]);

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  // 최초 데이터 셋
  useEffect(() => {
    setUserProfile();
    callList();

    if (params.search && params.keyword) {
      setSearchText(params.keyword);
      setSearchType(params.search);
    } else {
      setSearchText("");
      setSearchType("client-name");
    }
  }, []);

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  // 필터링 및 정렬 적용
  useEffect(() => {
    let list = deviceDataList;
    let filteredList = makeSearchedArr(list, searchType, searchText);

    makeTable(filteredList);
    makePageSec(filteredList);
  }, [deviceDataList, selectedType, selectedStatus, sortOption]);

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/device" className="conTitleMenu">
            기기
          </Link>
          <div className="conTitleMenu"></div>
          <div className="flex1" />
          <div className="downloadButton" onClick={handleDownloadXLSX}>
            엑셀다운로드
          </div>
        </div>
        {!loading ? (
          <div className="tableWrap">
            <div className="tableCount">{dataCount}</div>
            {deviceTable}

            <div className="pageSec">
              <div className="flex1" />
              {pageSec}
              <div className="flex1" />
            </div>

            {params.search !== "client" && params.search !== "product" ? (
              <div className="searchSec ">
                <div className="flex1" />
                <div className="">
                  <select
                    name="searchType"
                    style={{ width: "170px", padding: "5px" }}
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    value={searchType}
                  >
                    <option value="client-name">클라이언트 고객사명</option>
                    <option value="client-code">클라이언트 코드</option>
                    <option value="device-code">기기 코드</option>
                    <option value="product-name">제품명</option>
                    <option value="mac-address">Mac Address</option>
                  </select>
                </div>
                <div className="ml10">
                  <input
                    placeholder="검색어를 입력하세요"
                    name="searchText"
                    className="searchinput"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      enterChecker(e, searchClick);
                    }}
                  />
                </div>
                <div className="ml10">
                  <div
                    style={{ cursor: "pointer" }}
                    className="searchButton plainbtn"
                    onClick={searchClick}
                  >
                    검색
                  </div>
                </div>
                <div className="flex1" />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default DeviceComponent;
