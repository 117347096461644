import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import * as CryptoJS from "crypto-js";

import api from "../../../store/api";
import axios from "axios";

function BuildComponent() {
  const { width, height } = useWindowDimensions();
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [macAddress, setMacAddress] = useState("047C160C3133");
  const [id, setId] = useState("");
  const [pass, setPass] = useState("");

  const measureList = [
    { test: "Lancaster_E", name: "랑카스터_E" },
    { test: "Lancaster_N", name: "랑카스터_N" },
    { test: "SVV", name: "SVV" },
    { test: "RAPD", name: "RAPD" },
    { test: "ContrastSensitivity", name: "대비감도" },
    { test: "Vision_ContrastSensitivity", name: "시력+대비감도" },
    { test: "ExtraocularMuscle", name: "외안근" },
    { test: "OcularTorsion", name: "회선검사" },
    { test: "Stereopsis", name: "입체시검사" },
    { test: "Pupil", name: "동공검사" },
    { test: "VisualField", name: "시야검사" },
    { test: "NDI_VisualField", name: "Braintellego_시야검사" },
    { test: "StrabismusAngle", name: "사시검사" },
    { test: "StrabismusAngle_New", name: "사시검사_new" },
    { test: "OKN", name: "OKN" },
    { test: "SP", name: "SP" },
    { test: "LND_NPC", name: "LND_NPC" },
    { test: "ColorBlindness", name: "색맹검사" },
    { test: "AmslerGrid", name: "암슬러그리드" },
    { test: "Astigmatism", name: "난시검사" },
    { test: "VisualAcuity", name: "시력검사" },
  ];

  const onChangeId = (e) => {
    setId(e.target.value);
  };
  const onChangePass = (e) => {
    setPass(e.target.value);
  };

  function createKey(baseString) {
    return CryptoJS.SHA256(baseString).toString(CryptoJS.enc.Hex);
  }

  function encryptJson(input, keyBase = "07110711") {
    let jsonToString = JSON.stringify(input);

    let mac = macAddress.replaceAll("-", "");
    console.log(`${keyBase}${mac}`);

    const key = createKey(`${keyBase}${mac}`);
    console.log(key);

    const iv = CryptoJS.lib.WordArray.random(16);
    const encryptedData = CryptoJS.AES.encrypt(jsonToString, key, { iv: iv });

    const encryptedBytes = CryptoJS.lib.WordArray.create(
      iv.words.concat(encryptedData.ciphertext.words)
    );
    return encryptedBytes;
  }

  const loginChecker = () => {
    makeConfig();
    return;

    if (id === "") {
      alert("아이디를 입력해주세요.");
      return;
    }
    if (pass === "") {
      alert("비밀번호를 입력해주세요.");
      return;
    }

    // API 엔드포인트 및 인증 정보
    const apiUrl = api.masterLogin;

    const body = { user_id: id, password: pass };

    axios
      .post(apiUrl, body)
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.data && res.data.access_token) {
          // 성공

          makeConfig();
        } else {
          throw new Error("etc");
        }
      })
      .catch((err) => {
        console.log(err.message);
        alert("관리자 정보를 확인해주세요.");
      });
  };

  function makeConfig() {
    console.log(checkedList);

    if (checkedList.length < 1) {
      alert("한개 이상의 검사를 선택해주세요");
      return;
    }

    if (macAddress === "") {
      alert("Mac Address를 입력해주세요.");
      return;
    }

    // 예시 JSON 데이터
    let jsonData = {
      checkTimeNoneInput: 5.0,
      defaultOption: {
        allVolume: 10,
        channel: 1,
        language: "Korean",
        showPPlVideo: true,
        usePPLVolume: true,
        addPupilDiameters: 0.5,
      },
      networkSetting: {
        screenMirroringType: "Udp",
        tcpPort: 55200,
        ipFinderPort: 56200,
        screenMirrroringPort: 57311,
        positionGuideMirrroringPort: 57700,
        eyeMirrroringPort: 58200,
      },
      noTouchCheckTime: 600.0,
      totalMeasureList: [],
      measureList: [],
    };

    jsonData["measureList"] = checkedList;
    jsonData["totalMeasureList"] = checkedList;
    console.log(jsonData);
    // JSON 데이터 암호화

    const encryptedData = encryptJson(jsonData);
    const encryptedWordArray = CryptoJS.enc.Base64.stringify(encryptedData);

    const latin1String = CryptoJS.enc.Base64.parse(encryptedWordArray).toString(
      CryptoJS.enc.Latin1
    );

    // Create a Blob with the Latin1-encoded string
    const blob = new Blob([latin1String], {
      type: "text/plain;charset=latin1",
    });

    const url = URL.createObjectURL(blob);

    // 파일 다운로드를 위한 a 태그 생성 및 클릭 이벤트 실행
    const a = document.createElement("a");
    a.href = url;
    a.download = "configdata.json";
    document.body.appendChild(a); // 임시로 DOM에 추가
    a.click(); // 다운로드 시작
    document.body.removeChild(a); // 추가했던 DOM 요소 삭제
    URL.revokeObjectURL(url); // 생성했던 URL 해제

    alert("설정파일이 다운로드 되었습니다.");
  }

  const checkboxClicked = (e) => {
    let newArr = checkedList;

    if (e.target.checked) {
      newArr.push(e.target.value);
    } else {
      newArr = checkedList.filter((item) => item !== e.target.value);
    }

    setCheckedList(newArr);
  };

  useEffect(() => {
    let inputs = [];
    for (let i = 0; i < measureList.length; i++) {
      const measure = measureList[i];
      inputs.push(
        <>
          <input
            type="checkbox"
            onClick={checkboxClicked}
            style={{ width: "25px" }}
            value={measure.test}
          />{" "}
          {measure.name} <br />
        </>
      );
    }

    setCheckBoxes(inputs);
  }, []);

  return (
    <div className="contentWrap" style={{ width: width, display: "flex" }}>
      <div className="flex1" />
      <div className="flex2">
        <div className="conTitle">
          <Link to="#" className="conTitleMenu">
            TENETUS 검사기기 설정파일 생성
          </Link>
          <div className="flex1" />
          {/* <div onClick={closePage} style={{cursor:"pointer"}} className='linkButton'>나가기</div> */}
          {/* <div className="conTitleMenu"></div> */}
          {/* <Link to="/accountmanagement/build/list" className='linkButton'>뒤로</Link> */}
        </div>

        <div className="conDes">
          <div className="conDesTitle">검사 선택</div>
          <div className="conDesDes" style={{ textAlign: "left" }}>
            {checkBoxes}
          </div>
          <div>
            Mac Address :{" "}
            <input
              type="text"
              onChange={(e) => {
                setMacAddress(e.target.value);
              }}
              value={macAddress}
            />
          </div>
          <div className="">
            관리자아이디 :<input className="" onChange={onChangeId} />
          </div>
          <div className="">
            비밀번호
            <input className="" onChange={onChangePass} type="password" />
          </div>
          <div className="linkButton tac" onClick={loginChecker}>
            설정파일 생성
          </div>
        </div>
      </div>
      <div className="flex1" />
    </div>
  );
}

export default BuildComponent;
