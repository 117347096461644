import { React, useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat, datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import "react-datepicker/dist/react-datepicker.css";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import {
  TitleBox,
  CommonBox,
  CopyBox,
  EditableBox,
  LinkBox,
  editButtons,
  MasterInfoBox,
  EmptyBox,
} from "../../../modules/commonTable";

function ProductDetailComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const STATUS = {
    active: "사용중",
    inactive: "해제",
    requested: "해제신청",
  };

  const [isMedical, setIsMedical] = useState(true);
  const [productCode, setProductCode] = useState("");
  const [productName, setProductName] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [version, setVersion] = useState("");
  const [status, setStatus] = useState("");
  const [test, setTest] = useState({});
  const [testNames, setTestNames] = useState("");

  const [originStatus, setOriginStatus] = useState("");

  // 수정 모드
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (!params.target) {
      alert("잘못된 접근입니다.");
      navigate("/productmanagement/product");
    }

    callInfo();
  }, []);

  const callInfo = async () => {
    if (!params.target) {
      // page 잘못됨
      return;
    }
    try {
      const target = params.target;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.productDetail };
      apiInfo.url += target + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const data = res.data;

        setProductCode(data.product_code);
        setProductName(data.product_type.product_name);
        // setIsMedical(data.product_type.is_medical);
        setVersion(data.version);
        setReleaseDate(data.release_date ? dateFormat(data.release_date) : "");
        setStatus(data.status);
        setTest(data.test ? data.test : {});
        setTestNames(
          data.test ? data.test.map((item) => item.test_name).join(", ") : ""
        );

        setOriginStatus(data.status);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const changeValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "statusInput") {
      setStatus(value);
    }
  };

  const editOnClick = () => {
    setIsEditMode(true);
  };

  const updateOnClick = async () => {
    let targets = [];

    const statusChanged = status !== originStatus;

    if (!statusChanged) {
      alert("변경된 사항이 없습니다.\n변경 후 수정 버튼을 눌러주세요.");
      return;
    }

    if (statusChanged) {
      targets.push({ target: "status", value: status });
    }
    // target 길이가 0이상이면 업데이트 콜
    if (targets.length > 0) {
      await updateTargetCall(targets);
    }
  };

  const updateTargetCall = async (targets) => {
    const _confirm = window.confirm("정보를 수정 하시겠습니까?");

    if (_confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        let body = {};
        targets.forEach((target) => {
          body[target.target] = target.value;
        });

        const apiInfo = { ...api.productUpdate };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo, body);

        if (res.status === 200) {
          alert("수정이 완료되었습니다.");
          window.location.reload();
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        if (err.response.status) {
          alert("올바른 데이터를 보내주세요. \n다시 시도 해주세요.");
        } else {
          alert("수정에 실패했습니다.\n다시 시도 해주세요.");
        }
      }
    }
  };

  const productCancelOnClick = async () => {
    const _confirm = window.confirm("제품을 해제 신청하시겠습니까?");

    if (_confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        const apiInfo = { ...api.productCancel };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo);

        if (res.status === 200) {
          alert("해제 신청되었습니다.");
          window.location.reload();
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        alert("해제 신청에 실패했습니다.\n다시 시도 해주세요.");
      }
    }
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/product" className="conTitleMenu">
            제품관리
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />
        </div>

        <div className="conDes">
          <TitleBox title="제품 정보" />
          <div>
            <div className="flexwrap">
              <CommonBox title="제품 코드" content={productCode} />
              <CommonBox title="제품명" content={productName} />
            </div>
            <div className="flexwrap">
              <CommonBox title="버전" content={version} />
              <CommonBox title="설치일" content={releaseDate} />
            </div>
            <div className="flexwrap">
              <CommonBox
                title="상태"
                content={
                  status === "active" ? (
                    <>
                      <span className="mr10">{STATUS[status]}</span>
                      <div
                        onClick={productCancelOnClick}
                        className="updateButton"
                      >
                        해제 신청
                      </div>
                    </>
                  ) : (
                    <span> {STATUS[status]} </span>
                  )
                }
              />
              {isMedical && test.length > 0 ? (
                <CommonBox
                  title="검사 정보"
                  content={
                    <span className="tar">
                      {"총 " + test.length + "개"}
                      <br />
                      {testNames}
                    </span>
                  }
                />
              ) : (
                <EmptyBox />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailComponent;
