import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { getProfile } from "../../../modules/decodeToken";

function MasterCreateComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  // 마스터가 아니면 잘못된 접근입니다 띄워야할 듯
  const master = true;

  const [masterName, setMasterName] = useState("");
  const [masterId, setMasterId] = useState("");
  const [masterPassword, setMasterPassword] = useState("");
  const [masterTeam, setMasterTeam] = useState(1);

  const [team, setTeam] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);

    if ((user && !user.is_super) || !user.is_master) {
      alert("잘못된 접근입니다.");
      navigate("/accountmanagement");
      return;
    }
  };

  useEffect(() => {
    setUserProfile();

    const fetchData = async () => {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);
        const res = await callAPI.request(api.masterTeam);

        if (res.status === 200) {
          makeTeamOptions(res.data);
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
      }
    };
    fetchData();
  }, []);

  const makeTeamOptions = (teamList) => {
    let options = [];
    for (let i = 0; i < teamList.length; i++) {
      const team = teamList[i];
      options.push(<option value={team.id}>{team.name}</option>);
    }

    setTeamOptions(options);
  };

  const changeValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "id") {
      setMasterId(value);
    } else if (name === "name") {
      setMasterName(value);
    } else if (name === "password") {
      setMasterPassword(value);
    } else if (name === "team") {
      setMasterTeam(value);
    }
  };

  const createOnClick = async () => {
    const _confirm = window.confirm("관리자를 생성 하시겠습니까?");
    let targets = [];
    if (_confirm) {
      // 2~16자리
      if (masterId.length < 2 || masterId.length > 16) {
        alert("아이디는 2자 이상 16자 이하로 입력해주세요.");
        return;
      }
      if (masterName.length < 1) {
        alert("이름은 한글자 이상 입력해주세요.");
        return;
      }
      if (masterPassword.length < 4 || masterPassword.length > 16) {
        alert("비밀번호은 한글자 이상 입력해주세요.");
        return;
      }

      try {
        const callAPI = new CallAPI(setLoading);
        const body = {
          user_id: masterId,
          name: masterName,
          password: masterPassword,
          master_team_id: masterTeam,
        };

        const res = await callAPI.request(api.masterUserCreate, body);

        if (res.status === 200) {
          alert("성공적으로 생성되었습니다.");
          navigate("/accountmanagement/master/", { replace: true });
        } else if (res.status === 400) {
          alert("이미 존재하는 아이디입니다.");
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        if (err.response.status === 400) {
          alert("이미 존재하는 아이디입니다.");
        } else {
          alert("생성에 실패했습니다.\n다시 시도 해주세요.");
        }
      }
    }
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/accountmanagement/master" className="conTitleMenu">
            관리자 계정
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            생성
          </Link>
        </div>

        <div className="conDes">
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">이름</div>
              <div className="flex1" />
            </div>
            <div>
              <input
                name="name"
                type="text"
                placeholder="사용자 이름을 입력해주세요."
                value={masterName}
                onChange={changeValue}
              />
            </div>
          </div>
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">아이디</div>
              <div className="flex1" />
            </div>
            <div>
              <input
                name="id"
                type="text"
                placeholder="사용자 아이디를 입력해주세요. (4자 이상 16자 이하)"
                value={masterId}
                onChange={changeValue}
              />
            </div>
          </div>

          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">비밀번호</div>
              <div className="flex1" />
            </div>
            <div>
              <input
                name="password"
                type="password"
                placeholder="사용자 비밀번호를 입력해주세요. (4자 이상 16자 이하)"
                value={masterPassword}
                onChange={changeValue}
              />
            </div>
          </div>
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">팀</div>
              <div className="flex1" />
            </div>
            <div>
              <select
                name="team"
                id="team"
                value={masterTeam}
                onChange={changeValue}
              >
                {teamOptions}
              </select>
            </div>
          </div>
          <div className="conDesDes pt10">
            <div className="flexwrap">
              <div className="flex1" />
              <div className="createButton" onClick={createOnClick}>
                생성
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterCreateComponent;
