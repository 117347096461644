import React from 'react'
import useWindowDimensions from '../../modules/useWindowDimensions'

function DashboardClientComponent() {

    const { height, width } = useWindowDimensions();

    return (
        <div className='flexwrap tac'>
            <div className='flex1' />
            <div className='flexcolwrap' style={{height, 'width':(width / 4)}}>
                <div className='flex1' />
                <h1 className='fs25'>
                    클라이언트 대시보드 컴포넌트
                </h1>
                <div className='flex1' />
            </div>
            <div className='flex1' />
        </div>
    )
}

export default DashboardClientComponent