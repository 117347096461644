import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getProfile } from "../../modules/decodeToken";

// import useWindowDimensions from '../../modules/useWindowDimensions'

import TopComponent from "../menu/top";
import SideMenuComponent from "../menu/side";

import Client from "./client/client.component";
import ClientCreate from "./client/client.create.component";
import ClientDetail from "./client/client.detail.component";

import Product from "./product/product.component";
import ProductCreate from "./product/product.create.component";
import ProductDetail from "./product/product.detail.component";

import Device from "./device/device.component";
import DeviceCreate from "./device/device.create.component";
import DeviceDetail from "./device/device.detail.component";

import Udi from "./udi/udi.component";
import UdiCreate from "./udi/udi.create.component";
import UdiDetail from "./udi/udi.detail.component";

function ProductmanagementMainComponent() {
  // const { height, width } = useWindowDimensions();
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);

    if (user && !user.is_master) {
      alert("잘못된 접근입니다.");
      navigate("/dashboard");
      return;
    }
  };

  useEffect(() => {
    setUserProfile();
  }, []);

  return (
    <>
      <TopComponent />

      <div className="flexwrap tac">
        <SideMenuComponent />

        <Routes>
          <Route exact path="/" element={<Client />} />
          <Route
            exact
            path="/client/:page?/:search?/:keyword?"
            element={<Client />}
          />
          <Route exact path="/client/create" element={<ClientCreate />} />
          <Route
            exact
            path="/client/detail/:target?"
            element={<ClientDetail />}
          />

          <Route
            exact
            path="/product/:page?/:search?/:keyword?"
            element={<Product />}
          />
          <Route
            exact
            path="/product/create/:clientId?"
            element={<ProductCreate />}
          />
          <Route
            exact
            path="/product/detail/:target?"
            element={<ProductDetail />}
          />

          <Route
            exact
            path="/device/:page?/:search?/:keyword?"
            element={<Device />}
          />
          <Route
            exact
            path="/device/create/:productId?"
            element={<DeviceCreate />}
          />
          <Route
            exact
            path="/device/detail/:target?"
            element={<DeviceDetail />}
          />

          <Route
            exact
            path="/udi/:page?/:search?/:keyword?"
            element={<Udi />}
          />
          <Route
            exact
            path="/udi/create/:productId?/:testId?"
            element={<UdiCreate />}
          />
          <Route exact path="/udi/detail/:target?" element={<UdiDetail />} />
        </Routes>
      </div>
    </>
  );
}

export default ProductmanagementMainComponent;
