import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { datetimeFormat } from "../../../modules/formatter";
import { getProfile } from "../../../modules/decodeToken";

import {
  TitleBox,
  LinkBox,
  CommonBox,
  EditableBox,
  editButtons,
  EmptyBox,
} from "../../../modules/commonTable";

function MasterInfoComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [masterName, setMasterName] = useState("");
  const [masterId, setMasterId] = useState("");
  const [masterPassword, setMasterPassword] = useState("****");
  const [masterTeamName, setMasterTeamName] = useState("");
  const [masterLoginCount, setMasterLoginCount] = useState(0);
  const [masterLastLogin, setMasterLastLogin] = useState("");
  const [masterIsActive, setMasterIsActive] = useState("X");

  const [masterOriginName, setMasterOriginName] = useState("");
  const [masterOriginId, setMasterOriginId] = useState("");
  const [masterOriginPassword, setMasterOriginPassword] = useState("****");

  const [masterLoginLogUrl, setMasterLoginLogUrl] = useState(
    "/accountmanagement/loginlog/master/"
  );

  // 수정 모드
  const [isEditMode, setIsEditMode] = useState(false);

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  useEffect(() => {
    if (!params.target) {
      // page 잘못됨
      return;
    }

    setUserProfile();

    const fetchData = async () => {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        const apiInfo = { ...api.masterUserDetail };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo);

        if (res.status === 200) {
          let data = res.data;

          setMasterName(data.name);
          setMasterId(data.user_id);

          setMasterOriginName(data.name);
          setMasterOriginId(data.user_id);

          setMasterTeamName(data.master_team.name ?? "-");
          setMasterLoginCount(data.login_info.count);

          setMasterLastLogin(
            data.login_info.last_login
              ? datetimeFormat(data.login_info.last_login)
              : ""
          );
          setMasterIsActive(data.is_active ? "O" : "X");

          setMasterLoginLogUrl(
            "/accountmanagement/loginlog/master/1/master-user/" + data.id + "/"
          );
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
      }
    };
    fetchData();
  }, []);

  const updateOnClick = async () => {
    console.log("수정");
    let targets = [];
    const passwordChanged = masterPassword !== "****" && masterPassword !== "";
    const idChanged = masterId !== masterOriginId;
    const nameChanged = masterName !== masterOriginName;

    if (!passwordChanged && !idChanged && !nameChanged) {
      alert(
        "변경된 사항이 없습니다.\n하나이상 변경 후 수정 버튼을 눌러주세요."
      );
      return;
    }
    if (nameChanged) {
      if (masterName.length < 1) {
        alert("이름은 한글자 이상 입력해주세요.");
        return;
      }
      targets.push({ target: "name", value: masterName });
    }
    if (idChanged) {
      if (masterId.length < 2 || masterId.length > 16) {
        alert("아이디는 2자 이상 16자 이하로 입력해주세요.");
        return;
      }
      targets.push({ target: "user_id", value: masterId });
    }
    if (passwordChanged) {
      if (masterPassword.length < 4 || masterPassword.length > 16) {
        alert("비밀번호는 4자 이상 16자 이하로 입력해주세요.");
        return;
      }
      targets.push({ target: "password", value: masterPassword });
    }

    // target 길이가 0이상이면 업데이트 콜
    if (targets.length > 0) {
      await updateTargetCall(targets);
    }
  };

  const updateTargetCall = async (targets) => {
    const _confirm = window.confirm("정보를 수정 하시겠습니까?");

    if (_confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        let body = {};
        targets.forEach((target) => {
          body[target.target] = target.value;
        });

        const apiInfo = { ...api.masterUserUpdate };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo, body);

        if (res.status === 200) {
          alert("수정이 완료되었습니다.");
          window.location.reload();
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        if (err.response.status === 403) {
          alert("수정 권한이 없습니다.\n관리자에게 문의하세요");
        } else {
          alert("수정에 실패했습니다.\n다시 시도 해주세요.");
        }
      }
    }
  };

  const inactiveOnClick = async () => {
    // confirm 삭제하면 되돌릴 수 없습니다.
    const confirm = window.confirm(
      "비활성화 시 되돌릴 수 없습니다.\n그래도 삭제하시겠습니까?"
    );
    if (confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        const apiInfo = { ...api.masterUserDelete };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo);

        if (res.status === 200) {
          alert("성공적으로 비활성화되었습니다.");
          navigate("/accountmanagement/master/", { replace: true });
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        alert("비활성화에 실패했습니다.\n다시 시도 해주세요.");
      }
    }
  };

  const cancelOnClick = () => {
    const passwordChanged = masterPassword !== "****" && masterPassword !== "";
    const idChanged = masterId !== masterOriginId;
    const nameChanged = masterName !== masterOriginName;

    if (passwordChanged || idChanged || nameChanged) {
      const confirm = window.confirm(
        "취소 시 변경된 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }

    setMasterName(masterOriginName);
    setMasterId(masterOriginId);
    setMasterPassword("****");

    setIsEditMode(false);
  };

  const changeValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "id") {
      setMasterId(value);
    } else if (name === "name") {
      setMasterName(value);
    } else if (name === "password") {
      setMasterPassword(value);
    }
  };

  const focusPassword = () => {
    if (masterPassword === "****") {
      setMasterPassword("");
    }
  };

  const editOnClick = () => {
    setIsEditMode(true);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/accountmanagement/master" className="conTitleMenu">
            관리자 계정
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />
        </div>
        <div className="conDes">
          <TitleBox title="마스터 계정 정보" />
          <div>
            <div className="flexwrap">
              {user && user.is_master && user.is_super ? (
                <EditableBox
                  title="이름"
                  content={masterName}
                  isEditMode={isEditMode}
                  changeValue={changeValue}
                  name="name"
                />
              ) : (
                <CommonBox title="이름" content={masterName} />
              )}
              <CommonBox title="팀" content={masterTeamName} />
            </div>
            <div className="flexwrap">
              <EditableBox
                title="아이디"
                content={masterId}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="id"
              />
              {masterLoginCount ? (
                <LinkBox
                  title="로그인 정보"
                  content={"총 " + masterLoginCount + "회"}
                  link={masterLoginLogUrl}
                />
              ) : (
                <CommonBox title="로그인 정보" content={"총 0회"} />
              )}
            </div>
            <div className="flexwrap">
              <EditableBox
                title="비밀번호"
                content={masterPassword}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="password"
                onClick={focusPassword}
              />
              <CommonBox
                title="최근 로그인"
                content={masterLastLogin ? masterLastLogin : "-"}
              />
            </div>
            <div className="flexwrap">
              <CommonBox title="활성 여부" content={masterIsActive} />
              <EmptyBox />
            </div>
          </div>

          {/* 슈퍼마스터 또는 본인 계정 수정 가능하도록 */}
          {(user &&
            user.is_master &&
            user.is_super &&
            masterIsActive === "O") ||
          (user && user.is_master && params.target == user.id) ? (
            <div className="conDesDes pt10">
              <div className="flexwrap">
                <div className="flex1" />
                <div
                  className="createButton"
                  style={{ margin: "5px", fontWeight: "bold" }}
                  onClick={inactiveOnClick}
                >
                  비활성화
                </div>
                {editButtons(
                  isEditMode,
                  updateOnClick,
                  cancelOnClick,
                  editOnClick
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default MasterInfoComponent;
