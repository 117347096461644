import axios from "axios";
import api from "../store/api";
import { jwtDecode } from "jwt-decode";

let isRefreshing = false;
let refreshQueue = [];


function convertUnixTimeToDateTime(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Unix 시간을 밀리초로 변환
  return date; // 현재 지역에 맞는 날짜 및 시간 형식으로 변환하여 반환
}

const logoutBySessionEnd = () => {
  window.location.href = "/logout";

  return;
};

const refreshCall = async (refreshToken) => {
  try {
    if (isRefreshing) {
      // 리프레시 중이면 대기열에 추가하고 대기
      return new Promise((resolve) => {
        refreshQueue.push(resolve);
      });
    }

    isRefreshing = true;

    const apiUrl = api.refreshToken.url;

    // localStorage에 있는 refreshToken을 사용해서 토큰 갱신
    if (!refreshToken) {
      console.error("refreshToken is null!!");
      logoutBySessionEnd();
      return;
    }

    const body = {
      refresh_token: refreshToken,
    };

    const response = await axios.post(apiUrl, body);

    if (
      response.status === 200 &&
      response.data &&
      response.data.access_token
    ) {
      const { access_token, refresh_token } = response.data;
      const profile = jwtDecode(access_token).profile;
      console.log("checkSession and refresh");

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);

      // 대기열에 있던 함수들에게 리프레시된 토큰 전달
      while (refreshQueue.length) {
        const resolve = refreshQueue.shift();
        resolve({ token: access_token, user: profile });
      }

      isRefreshing = false;
      console.log("new token", access_token, profile);
      return { token: access_token, user: profile };
    }
  } catch (error) {
    console.log("refresh fail");
    console.error(error.message);
    logoutBySessionEnd();
  }
};

// 이상없을 시 accessToken 반환
const checkSession = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  // 1. accessToken, refreshToken이 수집 됐는지 확인
  if (accessToken && refreshToken) {
    const decordedAccessToken = jwtDecode(accessToken);
    const decordedRefreshToken = jwtDecode(refreshToken);
    const profile = decordedAccessToken.profile;
    const now = new Date();

    // 2. 있을 경우 refreshToken까지 다 만료 됐는지 확인
    if (convertUnixTimeToDateTime(decordedAccessToken.exp) < now) {
      if (convertUnixTimeToDateTime(decordedRefreshToken.exp) < now) {
        // 2-1. 만료 됐다면 로그아웃 처리 없을 경우 로그아웃 처리
        return logoutBySessionEnd();
      } else {
        // 2-2. 만료 안됐다면 리프레시 처리
        return await refreshCall(refreshToken);
      }
    }

    console.log(
      "pass!!!",
      convertUnixTimeToDateTime(decordedAccessToken.exp),
      now,
      profile
    );
    return { token: accessToken, user: profile };
  } else {
    // 2-2. 없을 경우 로그아웃 처리
    if (window.location.pathname !== "/") {
      return logoutBySessionEnd();
    }
    return;
  }
};

export { checkSession, refreshCall };
