import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getProfile } from "../../modules/decodeToken";
// import useWindowDimensions from '../../modules/useWindowDimensions'

import TopComponent from "../menu/top";
import SideMenuComponent from "../menu/side";

import MasterList from "./master/master.component";
import MasterCreate from "./master/master.create.component";
import MasterInfo from "./master/master.detail.component";

import ClientList from "./client/client.component";
import ClientCreate from "./client/client.create.component";
import ClientInfo from "./client/client.detail.component";

import BuildList from "./build/build.list.component";
import BuildCreate from "./build/build.component";
import BuildInfo from "./build/build.detail.component";

import MasterLoginLogList from "./loginlog/master.component";
import ClientLoginLogList from "./loginlog/client.component";

function AccountmanagementMainComponent({}) {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);

    if (user && !user.is_master) {
      alert("잘못된 접근입니다.");
      navigate("/dashboard");
      return;
    }
  };

  useEffect(() => {
    setUserProfile();
  }, []);
  return (
    <>
      <TopComponent />

      <div className="flexwrap tac">
        <SideMenuComponent />

        <Routes>
          <Route exact path="/" element={<MasterList />} />
          <Route
            exact
            path="/master/:page?/:search?/:keyword?"
            element={<MasterList />}
          />
          <Route exact path="/master/create" element={<MasterCreate />} />
          <Route
            exact
            path="/master/detail/:target?"
            element={<MasterInfo />}
          />

          <Route
            exact
            path="/client/:page?/:search?/:keyword?"
            element={<ClientList />}
          />
          <Route
            exact
            path="/client/create/:clientId"
            element={<ClientCreate />}
          />
          <Route
            exact
            path="/client/detail/:target?"
            element={<ClientInfo />}
          />

          <Route
            exact
            path="/loginlog/:page?/:search?/:keyword?"
            element={<MasterLoginLogList />}
          />
          <Route
            exact
            path="/loginlog/master/:page?/:search?/:keyword?"
            element={<MasterLoginLogList />}
          />
          <Route
            exact
            path="/loginlog/client/:page?/:search?/:keyword?"
            element={<ClientLoginLogList />}
          />

          <Route exact path="/build/list" element={<BuildList />} />
          <Route exact path="/build" element={<BuildCreate />} />
          <Route exact path="/build/detail/:target?" element={<BuildInfo />} />
        </Routes>
      </div>
    </>
  );
}

export default AccountmanagementMainComponent;
