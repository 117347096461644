import { React } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { datetimeFormat } from "../modules/formatter";

// 표를 렌더링하는 함수
const renderTable = (title, content) => {
  return (
    <div key={title} className="flex1">
      <div className="flexwrap detailTableInfo">
        <div className="flex1 detailTableTitle">{title}</div>
        <div className="flex1 detailTableContent">{content}</div>
      </div>
    </div>
  );
};

// 공통 모듈로 분리된 컴포넌트
function CommonTable({ title, content }) {
  return renderTable(title, content);
}

const CommonBox = ({ title, content }) => {
  return <CommonTable title={title} content={content} />;
};

const TitleBox = ({ title, rightButton = "", className = "" }) => {
  return (
    <div className="conDesTitle">
      <div className="flexwrap">
        <div className={"fwb " + className}>{title}</div>
        <div className="flex1" />
        {rightButton}
      </div>
    </div>
  );
};

const InputBox = ({ title, content, changeValue, name }) => {
  return (
    <CommonTable
      title={title}
      content={
        <input
          name={name}
          type="text"
          placeholder={title}
          value={content}
          onChange={changeValue}
        />
      }
    />
  );
};

const EditableBox = ({
  title,
  content,
  isEditMode,
  changeValue,
  name,
  add = "",
  type = "text",
  onClick = () => {},
}) => {
  return (
    <CommonTable
      title={title}
      content={
        isEditMode ? (
          <input
            name={name}
            type={type}
            placeholder={title}
            value={content == "-" ? "" : content}
            onChange={changeValue}
            onClick={onClick}
          />
        ) : (
          <span>
            {content}
            {add}
          </span>
        )
      }
    />
  );
};

const CopyBox = ({ title, content }) => {
  return (
    <CommonTable
      title={title}
      content={
        <CopyToClipboard
          style={{ cursor: "pointer" }}
          className="Toram"
          text={content}
          onCopy={() => alert("클라이언트 코드가 복사되었습니다.")}
        >
          <text>{content} (클릭 시 복사)</text>
        </CopyToClipboard>
      }
    />
  );
};

const LinkBox = ({
  title,
  content,
  link,
  className = "detailButton",
  target = "",
}) => {
  return (
    <CommonTable
      title={title}
      content={
        <Link className={className} to={link} target={target}>
          {content}
        </Link>
      }
    />
  );
};

// 생성 정보를 위한 box
const MasterInfoBox = ({ masterUser, createdDate, masterUrl }) => {
  return (
    <CommonTable
      title="생성 정보"
      content={
        masterUser && masterUser.id ? (
          <div className="tar">
            <div className="mr10">
              {createdDate ? datetimeFormat(createdDate) : ""}
            </div>
            <Link className="detailButton " to={masterUrl}>
              {masterUser.name}
            </Link>
          </div>
        ) : (
          <span className="mr10">
            {createdDate ? datetimeFormat(createdDate) : ""}
          </span>
        )
      }
    />
  );
};

const EmptyBox = () => {
  return <div className="flex1"></div>;
};

const editButtons = (isEditMode, updateOnClick, cancelOnClick, editOnClick) => {
  return isEditMode ? (
    <>
      <div className="updateButton " onClick={updateOnClick}>
        수정 완료
      </div>
      <div className="cancelButton" onClick={cancelOnClick}>
        취소
      </div>
    </>
  ) : (
    <div className="updateButton" onClick={editOnClick}>
      수정
    </div>
  );
};

export {
  TitleBox,
  CommonBox,
  InputBox,
  CopyBox,
  LinkBox,
  EditableBox,
  MasterInfoBox,
  EmptyBox,
  editButtons,
};
