import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { enterChecker } from "../../../modules/common";

function MasterComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const pageRowMax = 10;

  const [masterLoginLogDataList, setMasterLoginLogDataList] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");
  const [masterLoginLogTable, setMasterLoginLogTable] = useState([]);

  // page view
  const [pageSec, setPageSec] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("user-id");

  const navigateToClient = () => {
    navigate("/accountmanagement/loginlog/client");
  };

  const makeTable = (datas, targetPage = null) => {
    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="flex1 br1g">계정</div>
        <div className="flex1 br1g">이름</div>
        <div className="flex1 br1g">로그인 여부</div>
        <div className="flex1 br1g">접속 IP</div>
        <div className="flex1">접속시간</div>
      </div>
    );
    tableArr.push(titleSec);

    const page =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    let f = page * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }
    const thisPage = datas.slice(f, l);

    // contents
    // testmasterList -> data
    thisPage.forEach((log) => {
      const masterUser = log.master_user;
      const masterAccountLink =
        "/accountmanagement/master/detail/" + masterUser.id;
      const desSec = (
        <Link key={log.id} to={masterAccountLink} className="flexwrap tableCon">
          <div className="flex1 br1g tac">{masterUser.user_id}</div>
          <div className="flex1 br1g tac">{masterUser.name}</div>
          <div className="flex1 br1g tac">
            {log.login_successful ? "O" : "X"}
          </div>
          <div className="flex1 br1g tac">{log.ip_address}</div>
          <div className="flex1 br1g tac">
            {log.created_date ? datetimeFormat(log.created_date) : ""}
          </div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setMasterLoginLogTable(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(pageSelected / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (pageSelected > 9) {
      const prePage = (Math.floor(pageSelected / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" "}
          {"<"}{" "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === pageSelected) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" "}
          {">"}{" "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  // 최초 데이터 셋
  useEffect(() => {
    callList();

    if (params.search && params.keyword) {
      setSearchText(params.keyword);
      setSearchType(params.search);
    } else {
      setSearchText("");
      setSearchType("user-id");
    }
  }, []);

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const apiUrl = api.loginLog;
      const res = await callAPI.request(apiUrl);

      if (res.status === 200) {
        let list = res.data.master_login_logs;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });
        }
        setMasterLoginLogDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    let list = masterLoginLogDataList;

    if (params.search && params.keyword) {
      list = makeSearchedArr(list, params.search, params.keyword);
    }
    if (masterLoginLogTable.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [masterLoginLogDataList]);

  const pageClicked = (target) => {
    let search = "";
    let keyword = "";

    if (searchType !== "" && searchText !== "") {
      search = searchType;
      keyword = searchText;
    }

    if (search && keyword) {
      navigate(
        "/accountmanagement/loginlog/master/" +
          (target + 1) +
          "/" +
          search +
          "/" +
          keyword
      );
    } else {
      navigate("/accountmanagement/loginlog/master/" + (target + 1));
    }

    if (masterLoginLogDataList.length !== 0) {
      let list = masterLoginLogDataList;

      if (search !== "" && keyword !== "") {
        list = makeSearchedArr(list, search, keyword);
      }

      makeTable(list, target);
      makePageSec(list, target);
    } else {
      callList();
    }
  };

  const makeSearchedArr = (datas, searchType, searchText) => {
    let searchResultArr = [];
    searchText = searchText + "";

    for (let i = 0; i < datas.length; i++) {
      const data = datas[i];
      let add = false;
      switch (searchType) {
        case "master-user":
          if (data.master_user && data.master_user.id == searchText) {
            add = true;
          }
          break;
        case "user-id":
          if (
            data.master_user &&
            data.master_user.user_id.includes(searchText)
          ) {
            add = true;
          }
          break;
        case "name":
          if (data.master_user && data.master_user.name.includes(searchText)) {
            add = true;
          }

          break;
        case "ip":
          if (data.ip_address && data.ip_address.includes(searchText)) {
            add = true;
          }

          break;

        default:
          break;
      }
      if (add) {
        searchResultArr.push(data);
      }
    }

    return searchResultArr;
  };

  const searchClick = () => {
    if (searchText === "") {
      if (masterLoginLogDataList.length !== 0) {
        makeTable(masterLoginLogDataList, 0);
        makePageSec(masterLoginLogDataList, 0);
      } else {
        callList();
      }
      // reset
      navigate("/accountmanagement/loginlog/master/1/", { replace: true });
      return;
    }

    navigate(
      "/accountmanagement/loginlog/master/1/" + searchType + "/" + searchText,
      {
        replace: true,
      }
    );

    if (masterLoginLogDataList.length !== 0) {
      let list = masterLoginLogDataList;
      if (searchType && searchText) {
        list = makeSearchedArr(list, searchType, searchText);
      }
      makeTable(list, 0);
      makePageSec(list, 0);
    } else {
      callList();
    }
  };

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/accountmanagement/loginlog" className="conTitleMenu">
            로그인 기록
          </Link>
          <div className="radioMenu">
            <label className="radioButton">
              <input type="radio" checked />
              마스터 계정
            </label>
            <label className="radioButton ml10">
              <input type="radio" onClick={navigateToClient} />
              클라이언트 계정
            </label>
          </div>
        </div>
        {!loading ? (
          <div className="tableWrap">
            <div className="tableCount">{dataCount}</div>
            {masterLoginLogTable}

            <div className="pageSec">
              <div className="flex1" />
              {pageSec}
              <div className="flex1" />
            </div>
            {params.search !== "master-user" ? (
              <div className="searchSec">
                <div className="flex1" />
                <div className="">
                  <select
                    name="searchType"
                    style={{ width: "150px", padding: "5px" }}
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    value={searchType}
                  >
                    <option value="user-id">계정</option>
                    <option value="name">이름</option>
                    <option value="ip">접속 IP</option>
                  </select>
                </div>
                <div className="ml10">
                  <input
                    placeholder="검색어를 입력하세요"
                    name="searchText"
                    className="searchinput"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={enterChecker}
                  />
                </div>
                <div className="ml10">
                  <div
                    style={{ cursor: "pointer" }}
                    className="searchButton plainbtn"
                    onClick={searchClick}
                  >
                    검색
                  </div>
                </div>
                <div className="flex1" />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default MasterComponent;
