import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./auth.css";

function LogoutComponent() {
  const navigate = useNavigate();
  let referrer = document.referrer;
  let path = "";

  if (referrer) {
    const url = new URL(referrer);
    path = url.pathname;
  }

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    navigate("/", { state: { path: path } });
  }, []);

  return <div>로그아웃 중</div>;
}

export default LogoutComponent;
