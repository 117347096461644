import { React, useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { getProfile } from "../../../modules/decodeToken";

function NoticeDetailComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [titleText, setTitleText] = useState("제목 영역입니다");
  const [conText, setConText] = useState("");
  const [updateUrl, setUpdateUrl] = useState("#");

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  useEffect(() => {
    setUserProfile();
  }, []);

  useEffect(() => {
    if (!params.target) {
      // page 잘못됨
      return;
    }

    const fetchData = async () => {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        const apiInfo = { ...api.noticeDetail };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo);

        if (res.status === 200) {
          let data = res.data;

          setTitleText(data.title);
          setConText(data.content);
          setUpdateUrl("/cs/notice/write/" + target);
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
      }
    };
    fetchData();
  }, [location]);

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/cs/notice" className="conTitleMenu">
            공지사항
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />

          {user && user.is_master ? (
            <Link to={updateUrl} className="linkButton">
              수정
            </Link>
          ) : (
            <></>
          )}

          <div className="conTitleMenu"></div>
          <Link to="/cs/notice" className="linkButton">
            뒤로
          </Link>
        </div>

        <div className="conDes">
          <div className="conDesTitle">
            <div className="flexwrap">
              <div className="fwb conInputTip">제목 (최대 100자)</div>
              <div className="flex1" />
            </div>
            <div>
              <input name="titleInput" type="text" value={titleText} disabled />
            </div>
          </div>
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">내용 (최대 1,500자)</div>
              <div className="flex1" />
            </div>
            <div>
              <textarea name="desInput" value={conText} disabled></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeDetailComponent;
