import {React, useEffect, useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import checkSession from "../../modules/tokenChecker";
import { getProfile } from "../../modules/decodeToken";

function TopMenuComponent() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  const [updateInfoUrl, setUpdateInfoUrl] = useState("#");
  const [name, setName] = useState("");
  const [dashMenuStyle, dashMenuStyleSet] = useState({ padding: "20px" });
  const [csMenuStyle, csMenuStyleSet] = useState({ padding: "20px" });
  const [myproductMenuStyle, myproductMenuStyleSet] = useState({
    padding: "20px",
  });
  const [accountmanagementMenuStyle, accountmanagementMenuStyleSet] = useState({
    padding: "20px",
  });
  const [productmanagementMenuStyle, productmanagementMenuStyleSet] = useState({
    padding: "20px",
  });

  const logoutByClick = () => {
    alert("로그아웃 되었습니다.");
    navigate("/logout");

    return;
  };

  // 이름, 탑메뉴 관련 내용 구축
  const setData = async () => {
    const user = getProfile();
    setUser(user);

    if (user && user.name) {
      setName(user.name + "(" + user.user_id + ")");
      if (user.is_super || user.is_master) {
        setUpdateInfoUrl("/accountmanagement/master/detail/" + user.id);
      } else {
        setUpdateInfoUrl("/dashboard/");
      }
    }
  };

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  useEffect(() => {
    setUserProfile();

    setData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const pathname = window.location.pathname;

      // 현재 메뉴에 따라 스타일 변경
      const styles = {
        dashboard: { padding: "20px", color: "gray", textDecoration: "none" },
        cs: { padding: "20px", color: "gray", textDecoration: "none" },
        myproduct: { padding: "20px", color: "gray", textDecoration: "none" },
        accountmanagement: {
          padding: "20px",
          color: "gray",
          textDecoration: "none",
        },
        productmanagement: {
          padding: "20px",
          color: "gray",
          textDecoration: "none",
        },
      };

      if (pathname.includes("dashboard"))
        styles.dashboard = {
          ...styles.dashboard,
          color: "white",
          fontWeight: "bolder",
        };
      else if (pathname.includes("cs"))
        styles.cs = { ...styles.cs, color: "white", fontWeight: "bolder" };
      else if (pathname.includes("myproduct")) {
        styles.myproduct = {
          ...styles.myproduct,
          color: "white",
          fontWeight: "bolder",
        };
      } else if (pathname.includes("accountmanagement"))
        styles.accountmanagement = {
          ...styles.accountmanagement,
          color: "white",
          fontWeight: "bolder",
        };
      else if (pathname.includes("productmanagement"))
        styles.productmanagement = {
          ...styles.productmanagement,
          color: "white",
          fontWeight: "bolder",
        };

      dashMenuStyleSet(styles.dashboard);
      csMenuStyleSet(styles.cs);
      myproductMenuStyleSet(styles.myproduct);
      productmanagementMenuStyleSet(styles.productmanagement);
      accountmanagementMenuStyleSet(styles.accountmanagement);
    };
    fetchData();
  }, [navigate]);

  return (
    <div className="flexwrap minw1000 tac p10 bb1 darkbluebg fs13">
      {/* <Link style={dashMenuStyle} to={"/dashboard"}>
        대시보드
      </Link> */}
      {user && !user.is_master ? (
        <Link style={myproductMenuStyle} to={"/myproduct/"}>
          내 제품
        </Link>
      ) : (
        <></>
      )}
      {user && user.is_master ? (
        <Link style={productmanagementMenuStyle} to={"/productmanagement/"}>
          제품관리
        </Link>
      ) : (
        <></>
      )}
      {user && user.is_master ? (
        <Link style={accountmanagementMenuStyle} to={"/accountmanagement/"}>
          계정관리
        </Link>
      ) : (
        <></>
      )}
      <Link style={csMenuStyle} to={"/cs/"}>
        고객지원
      </Link>

      <div className="flex1" />
      <Link
        style={{ padding: "20px", color: "white", textDecoration: "none" }}
        to={updateInfoUrl}
      >
        {name}님
      </Link>
      <div className="p10">
        <button className="fs13" onClick={logoutByClick}>
          로그아웃
        </button>
      </div>
    </div>
  );
}

export default TopMenuComponent