import {React, useState, useEffect} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from '../../modules/useWindowDimensions'
import { datetimeFormat } from "../../modules/formatter";
import api from "../../store/api";
import CallAPI from "../../modules/callAPI";
import { getProfile } from "../../modules/decodeToken";
import LoadingSpinner from "../loading/spinner";

import TopComponent from "../menu/top";
import SideMenuComponent from "../menu/side";

function DashboardComponent() {
  const { width } = useWindowDimensions();

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({});

  const [qnaDataList, setQnaDataList] = useState([]);
  const [qnaList, setQnaList] = useState([]);

  const [noticeDataList, setNoticeDataList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);

  const makeTable = () => {
    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="flex1 br1g">문의 분류</div>
        <div className="width50 br1g">제목</div>
        <div className="flex1 br1g">생성일</div>
        <div className="flex1">답변여부</div>
      </div>
    );
    tableArr.push(titleSec);

    // contents
    qnaDataList.forEach((qna) => {
      const _at = new Date(qna.created_date);
      const dayText = datetimeFormat(_at);
      const linkTarget = "/cs/qna/detail/" + qna.id;
      const desSec = (
        <Link key={qna.id} to={linkTarget} className="flexwrap tableCon">
          <div className="flex1 tac">
            {qna.category === "client" ? "클라이언트" : "유저"}
          </div>
          <div className="width50 ">{qna.title}</div>
          <div className="flex1 tac">{dayText}</div>
          <div className="flex1 tac">X</div>
        </Link>
      );
      if (!qna.answer) {
        tableArr.push(desSec);
      }
    });
    setQnaList(tableArr);
  };

  // 최초 데이터 셋
  useEffect(() => {
    const fetchData = async () => {
      try {
        const callAPI = new CallAPI(setLoading);
        const res = await callAPI.request(api.qnaList);

        if (res.status === 200) {
          // 성공
          let list = res.data;
          if (list.length > 0) {
            list = list.sort(function (a, b) {
              return b.id - a.id;
            });
          }
          if (!user.is_master) {
            let newList = [];

            for (let i = 0; i < list.length; i++) {
              const l = list[i];
              if (l.client_user === user.id) {
                newList.push(l);
              }
              if (newList.length > 5) {
                // 최근 5개까지만 노출됨
                break;
              }
            }

            list = newList;
          }
          setQnaDataList(list);
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
      }
    };
    fetchData();
  }, [location]);

  // 테스트 데이터 만들기
  useEffect(() => {
    makeTable();
  }, [qnaDataList]);

  const makeNoticeTable = () => {
    if (noticeDataList.length === 0) {
      return;
    }

    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="width70 br1g">제목</div>
        <div className="flex2">생성일</div>
      </div>
    );
    tableArr.push(titleSec);

    // contents
    // testNoticeList -> data
    noticeDataList.forEach((notice) => {
      const _at = new Date(notice.created_date);
      const dayText = datetimeFormat(_at);
      const linkTarget = "/cs/notice/detail/" + notice.id;
      const desSec = (
        <Link key={notice.id} to={linkTarget} className="flexwrap tableCon">
          <div className="width70 ">{notice.title}</div>
          <div className="flex2 tac">{dayText}</div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setNoticeList(tableArr);
  };

  // 테스트 데이터 만들기
  useEffect(() => {
    makeNoticeTable();
  }, [noticeDataList]);

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  useEffect(() => {
    setUserProfile();

    callList();
  }, []);

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.noticeList);

      if (res.status === 200) {
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });
        }
        list = list.slice(0, 5);
        setNoticeDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <TopComponent />

      <div className="flexwrap">
        <SideMenuComponent />
        <div className="contentWrap" style={{ width: width - 130 }}>
          <LoadingSpinner isVisible={loading} />
          <div className="contentBox" style={{ paddingBottom: "150px" }}>
            <div className="conTitle">
              <Link to="#" className="conTitleMenu">
                대시보드
              </Link>
              <div className="flex1" />
            </div>

            <div className="tableWrap">
              {user && !user.is_master ? (
                <div>
                  <div style={{ fontWeight: "bold", paddingBottom: "15px" }}>
                    공지사항
                  </div>
                  {noticeList}
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "15px",
                      paddingTop: "35px",
                    }}
                  >
                    문의사항
                  </div>
                </div>
              ) : (
                <></>
              )}

              {qnaList}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardComponent;