import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat, datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { enterChecker } from "../../../modules/common";

function ProductComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [productDataList, setAllProductDataList] = useState([]);

  const [productList, setProductList] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");

  // page view
  const [pageSec, setPageSec] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("product-code");
  const pageRowMax = 10;

  const STATUS = {
    active: "사용중",
    inactive: "해제",
    requested: "해제신청",
  };

  const makeTable = (datas, targetPage = null) => {
    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle productTable">
        <div className="flex1 br1g">제품 코드</div>
        <div className="flex1 br1g">제품명</div>
        <div className="flex1 br1g">검사개수</div>
        <div className="flex1 br1g">설치일</div>
        <div className="flex1 br1g">상태</div>
      </div>
    );
    tableArr.push(titleSec);

    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }

    const thisPage = datas.slice(f, l);

    // contents
    // testproductList -> data
    thisPage.forEach((product) => {
      const linkTarget = "/myproduct/product/detail/" + product.id;
      const desSec = (
        <Link
          key={product.id}
          to={linkTarget}
          className="flexwrap tableCon productTable"
        >
          <div className="flex1 br1g">{product.product_code}</div>
          <div className="flex1 br1g">{product.product_type.product_name}</div>
          <div className="flex1 br1g">{product.test_count}</div>
          <div className="flex1 br1g">
            {product.release_date ? dateFormat(product.release_date) : "-"}
          </div>
          <div className="flex1">{STATUS[product.status]}</div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setProductList(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;
    // page set

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(pageSelected / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (pageSelected > 9) {
      const prePage = (Math.floor(pageSelected / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" "}
          {"<"}{" "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === pageSelected) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" "}
          {">"}{" "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.productList);

      if (res.status === 200) {
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });
        }

        setSearchType("product-code");
        setAllProductDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const makeSearchedArr = (productDataList, searchType, searchText) => {
    let searchResultArr = [];
    searchText = searchText + "";

    for (let i = 0; i < productDataList.length; i++) {
      const product = productDataList[i];

      let add = false;
      switch (searchType) {
        case "product-code":
          if (product.product_code.includes(searchText)) {
            add = true;
          }
          break;
        case "product-name":
          if (product.product_type.product_name.includes(searchText)) {
            add = true;
          }
          break;
        default:
          break;
      }
      if (add) {
        searchResultArr.push(product);
      }
    }

    return searchResultArr;
  };

  const pageClicked = (target) => {
    let search = "";
    let keyword = "";

    if (searchType !== "" && searchText !== "") {
      search = searchType;
      keyword = searchText;
    }

    if (search && keyword) {
      navigate(
        "/myproduct/product/" + (target + 1) + "/" + search + "/" + keyword
      );
    } else {
      navigate("/myproduct/product/" + (target + 1));
    }
    if (productDataList.length !== 0) {
      let list = productDataList;

      if (search !== "" && keyword !== "") {
        list = makeSearchedArr(list, search, keyword);
      }

      makeTable(list, target);
      makePageSec(list, target);
    } else {
      callList();
    }
  };

  const searchClick = () => {
    if (searchText === "") {
      if (productDataList.length !== 0) {
        makeTable(productDataList, 0);
        makePageSec(productDataList, 0);
      } else {
        callList();
      }
      navigate("/myproduct/product/1/", { replace: true });
      // reset
      return;
    }

    navigate("/myproduct/product/1/" + searchType + "/" + searchText, {
      replace: true,
    });

    if (productDataList.length !== 0) {
      let list = productDataList;
      if (searchType && searchText) {
        list = makeSearchedArr(list, searchType, searchText);
      }
      makeTable(list, 0);
      makePageSec(list, 0);
    } else {
      callList();
    }
  };

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  useEffect(() => {
    let list = productDataList;

    if (params.search && params.keyword) {
      list = makeSearchedArr(list, params.search, params.keyword);
    }

    if (productList.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [productDataList]);

  // 최초 데이터 셋
  useEffect(() => {
    callList();

    if (params.search && params.keyword) {
      setSearchText(params.keyword);
      setSearchType(params.search);
    } else {
      setSearchText("");
      setSearchType("product-code");
    }
  }, []);

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/product" className="conTitleMenu">
            제품
          </Link>
          <div className="conTitleMenu"></div>
          <div className="flex1" />
        </div>

        <div className="tableWrap">
          <div className="tableCount">{dataCount}</div>
          {productList}

          <div className="pageSec">
            <div className="flex1" />
            {pageSec}
            <div className="flex1" />
          </div>
          <div className="searchSec ">
            <div className="flex1" />
            <div className="">
              <select
                name="searchType"
                style={{ width: "150px", padding: "5px" }}
                onChange={(e) => {
                  setSearchType(e.target.value);
                }}
                value={searchType}
              >
                <option value="product-code">제품코드</option>
                <option value="product-name">제품명</option>
              </select>
            </div>
            <div className="ml10">
              <input
                placeholder="검색어를 입력하세요"
                name="searchText"
                className="searchinput"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onKeyDown={(e) => {
                  enterChecker(e, searchClick);
                }}
              />
            </div>
            <div className="ml10">
              <div
                style={{ cursor: "pointer" }}
                className="searchButton plainbtn"
                onClick={searchClick}
              >
                {" "}
                검색{" "}
              </div>
            </div>
            <div className="flex1" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductComponent;
