import { React, useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat, datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import "react-datepicker/dist/react-datepicker.css";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { getProfile } from "../../../modules/decodeToken";

import {
  TitleBox,
  CommonBox,
  CopyBox,
  EditableBox,
  LinkBox,
  editButtons,
  MasterInfoBox,
  EmptyBox,
} from "../../../modules/commonTable";

function ProductDetailComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const TYPE = {
    demo: "데모",
    display: "전시",
    sale: "판매",
    return: "회수",
  };

  const DEVICE_STATUS = {
    active: "사용중",
    inactive: "해제",
    requested: "해제신청",
    return: "회수",
  };

  const DEVICE_TYPE = {
    pc: "PC",
    hmd: "HMD",
  };

  const routerModelList = [
    "ipTIME A604-V3",
    "ipTIME A604-V5",
    "ipTIME A604SE",
    "TP - Link Archer C50(KR) Ver6.0",
  ];

  // 제품 정보
  const [isMedical, setIsMedical] = useState(false);
  const [productName, setProductName] = useState("");
  const [client, setClient] = useState({});
  const [warrantyMonths, setWarrantyMonths] = useState("");
  const [warrantyDate, setWarrantyDate] = useState("");
  const [version, setVersion] = useState("");
  const [buildUrl, setBuildUrl] = useState("");
  const [masterUser, setMasterUser] = useState({});
  const [createdDate, setCreatedDate] = useState("");
  const [memo, setMemo] = useState("");

  // 출고 정보
  const [type, setType] = useState("");
  const [demoDate, setDemoDate] = useState("");
  const [salesDate, setSalesDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [routerModel, setRouterModel] = useState("");
  const [routerSerialModel, setRouterSerialModel] = useState("");
  const [isDongle, setIsDongle] = useState(false);

  // 기기 정보
  const [device, setDevice] = useState({});

  // 검사 정보
  const [test, setTest] = useState({});
  const [qualityMasterUser, setQualityMasterUser] = useState("");
  const [reportMemo, setReportMemo] = useState("");

  const [deviceTable, setDeviceTable] = useState("");
  const [testTable, setTestTable] = useState("");
  const [clientUrl, setClientUrl] = useState("");
  const [masterUrl, setMasterUrl] = useState("");
  const [udiUrl, setUdiUrl] = useState("");
  const [deviceUrl, setDeviceUrl] = useState("");
  const [qualityMasterUrl, setQualityMasterUrl] = useState("");

  const [originDemoDate, setOriginDemoDate] = useState("");
  const [originSalesDate, setOriginSalesDate] = useState("");
  const [originReturnDate, setOriginReturnDate] = useState("");
  const [originWarrantyMonths, setOriginWarrantyMonths] = useState("");
  const [originVersion, setOriginVersion] = useState("");
  const [originType, setOriginType] = useState("");
  const [originBuildUrl, setOriginBuildUrl] = useState("");
  const [originRouterModel, setOriginRouterModel] = useState("");
  const [originRouterSerialNumber, setOriginRouterSerialNumber] = useState("");
  const [originIsDongle, setOriginIsDongle] = useState(false);
  const [originMemo, setOriginMemo] = useState("");
  const [originReportMemo, setOriginReportMemo] = useState("");

  // 수정 모드
  const [isEditMode, setIsEditMode] = useState(false);
  const [isReportEditMode, setIsReportEditMode] = useState(false);

  const createDeviceUrl = "/productmanagement/device/create/" + params.target;

  useEffect(() => {
    if (!params.target) {
      alert("잘못된 접근입니다.");
      navigate("/productmanagement/product");
    }
    setUserProfile();
    callInfo();
  }, []);

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  const callInfo = async () => {
    if (!params.target) {
      // page 잘못됨
      return;
    }
    try {
      const target = params.target;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.productDetail };
      apiInfo.url += target + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const data = res.data;

        // 제품 정보
        setIsMedical(data.product_type.is_medical);
        setProductName(data.product_type.product_name);
        setClient(data.client);
        setClientUrl(
          "/productmanagement/client/detail/" + data.client.id + "/"
        );
        setWarrantyMonths(data.warranty_months);
        if (data.warranty_months) {
          // salesDate에 data.warranty_months를 더한 날짜
          const warrantyDate = new Date(data.sales_date);
          warrantyDate.setMonth(warrantyDate.getMonth() + data.warranty_months);
          setWarrantyDate(dateFormat(warrantyDate));
        }
        setVersion(data.version);
        setBuildUrl(data.build_url);
        setMasterUser(data.master_user);
        setMasterUrl("/accountmanagement/master/detail/" + data.master_user.id);
        setCreatedDate(datetimeFormat(data.created_date));
        setMemo(data.memo);

        // 출고 정보
        setType(data.type);
        setDemoDate(data.demo_date ? dateFormat(data.demo_date) : "");
        setSalesDate(data.sales_date ? dateFormat(data.sales_date) : "");
        setReturnDate(data.return_date ? dateFormat(data.return_date) : "");
        setRouterModel(data.router_model);
        setRouterSerialModel(data.router_serial_number);
        setIsDongle(data.is_dongle);

        // 기기 정보
        setDevice(data.device);
        setDeviceUrl("/productmanagement/device/1/product/" + data.id + "/");

        // 검사 정보
        setTest(data.test);
        setReportMemo(data.report_memo);
        setUdiUrl("/productmanagement/udi/1/product/" + data.id + "/");
        if (data.quality_master_user) {
          setQualityMasterUser(data.quality_master_user);
          setQualityMasterUrl(
            "/accountmanagement/master/detail/" + data.quality_master_user.id
          );
        }

        if (data.product_type.is_medical && data.test) {
          makeTestTable(data.test);
        }
        if (data.device) {
          makeDeviceTable(data.device);
        }

        setOriginWarrantyMonths(data.warranty_months);
        setOriginVersion(data.version);
        setOriginType(data.type);
        setOriginBuildUrl(data.build_url);
        setOriginMemo(data.memo);
        setOriginDemoDate(data.demo_date ? dateFormat(data.demo_date) : "");
        setOriginSalesDate(data.sales_date ? dateFormat(data.sales_date) : "");
        setOriginReturnDate(
          data.return_date ? dateFormat(data.return_date) : ""
        );
        setOriginRouterModel(data.router_model);
        setOriginRouterSerialNumber(data.router_serial_number);
        setOriginIsDongle(data.is_dongle);
        setOriginReportMemo(data.report_memo);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const makeDeviceTable = (devices) => {
    let tableArr = [];

    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="flex1 br1g">기기 코드</div>
        <div className="flex1 br1g">기기 유형</div>
        <div className="flex1 br1g">모델명</div>
        <div className="flex1 br1g">상태</div>
        <div className="flex1 br1g">기기출고일</div>
        <div className="flex1">생성 정보</div>
      </div>
    );
    tableArr.push(titleSec);
    setDeviceTable(tableArr);

    devices.forEach((deviceData) => {
      let deviceLink =
        "/productmanagement/device/detail/" + deviceData.id + "/";
      deviceData.masterAccountLink =
        "/accountmanagement/master/detail/" + deviceData.master_user.id;

      const desSec = (
        <Link
          key={deviceData.id}
          to={deviceLink}
          className="flexwrap tableCon productTable"
        >
          <div className="flex1 br1g">
            <Link className="detailButton" to={deviceLink}>
              {deviceData.device_code}
            </Link>
          </div>
          <div className="flex1 br1g">{DEVICE_TYPE[deviceData.type]}</div>
          <div className="flex1 br1g">{deviceData.model_name}</div>
          <div className="flex1 br1g">{DEVICE_STATUS[deviceData.status]}</div>
          <div className="flex1 br1g">
            {dateFormat(deviceData.release_date)}
          </div>
          <div className="flex1">
            {datetimeFormat(deviceData.created_date)}
            <br />
            {deviceData.master_user ? (
              <Link className="detailButton" to={deviceData.masterAccountLink}>
                {deviceData.master_user.name}
              </Link>
            ) : (
              "-"
            )}
          </div>
        </Link>
      );
      tableArr.push(desSec);
    });
    setDeviceTable(tableArr);
  };

  // 파일 검증 후 API로 업로드
  // pdf만 가능, 최대 10mb 제한
  const uploadFile = async (e, testDataId, type) => {
    // type이 process, final, check 중 하나여야함
    if (type !== "process" && type !== "final" && type !== "check") {
      alert("잘못된 접근입니다.");
      return;
    }
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    if (file.size > 10000000) {
      alert("파일 용량이 너무 큽니다. 10MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    if (file.type !== "application/pdf") {
      alert("pdf 파일만 업로드 가능합니다.");
      return;
    }

    // 파일이름과 함께 업로드 여부 확인
    if (!window.confirm("[" + file.name + "] 파일을 업로드 하시겠습니까?")) {
      return;
    }

    // formdata로 파일 업로드
    const formData = new FormData();
    formData.append("file", file);
    formData.append("test_id", testDataId);

    let apiUrl = "";
    if (type === "process") {
      apiUrl = api.reportAddProcess;
    } else if (type === "final") {
      apiUrl = api.reportAddFinal;
    } else if (type === "check") {
      apiUrl = api.reportAddCheck;
    }

    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(apiUrl, formData);

      if (res.status === 200) {
        alert("파일이 업로드 되었습니다.");
        window.location.reload();
      } else {
        alert("파일 업로드에 실패했습니다.");
      }
    } catch (err) {
      console.log("err", err);
      alert("파일 업로드에 실패했습니다.");
    }
  };

  const makeTestTable = (tests) => {
    let tableArr = [];

    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="flex1 br1g">검사</div>
        <div className="flex1 br1g">공정검사성적서</div>
        <div className="flex1 br1g">완제품검사성적서</div>
        <div className="flex1 br1g">설치점검표</div>
        <div className="flex1">UDI</div>
      </div>
    );
    tableArr.push(titleSec);
    setTestTable(tableArr);

    tests.forEach((testData) => {
      const testDataId = testData.id;
      const createUrl =
        "/productmanagement/udi/create/" + params.target + "/" + testData.id;
      const processReports = testData.report.process;
      const finalReports = testData.report.final;
      const checkReports = testData.report.check;

      const udi = testData.udi;
      if (udi) {
        udi.udiLink = "/productmanagement/udi/detail/" + udi.id;
      }

      const desSec = (
        <div className="flexwrap tableCon productTable">
          <div className="flex1 br1g">{testData.test_info.test_name}</div>
          <div className="flex1 br1g">
            {processReports.map((item, index) => (
              <Link
                key={index}
                className="detailButton"
                to={item.path}
                target="_blank"
              >
                {item.file_name}
              </Link>
            ))}
            {processReports.length < 10 ? (
              <label
                htmlFor={"processFile" + testDataId}
                className="createButton"
              >
                + 파일 첨부하기
                <input
                  className="none"
                  type="file"
                  id={"processFile" + testDataId}
                  onChange={(e) => uploadFile(e, testDataId, "process")}
                  accept=".pdf"
                />
              </label>
            ) : (
              ""
            )}
          </div>
          <div className="flex1 br1g">
            {finalReports.map((item, index) => (
              <Link
                key={index}
                className="detailButton"
                to={item.path}
                target="_blank"
              >
                {item.file_name}
              </Link>
            ))}
            {finalReports.length < 10 ? (
              <label
                htmlFor={"finalFile" + testDataId}
                className="createButton"
              >
                + 파일 첨부하기
                <input
                  className="none"
                  type="file"
                  id={"finalFile" + testDataId}
                  onChange={(e) => uploadFile(e, testDataId, "final")}
                  accept=".pdf"
                />
              </label>
            ) : (
              ""
            )}
          </div>
          <div className="flex1 br1g">
            {checkReports.map((item, index) => (
              <Link
                key={index}
                className="detailButton"
                to={item.path}
                target="_blank"
              >
                {item.file_name}
              </Link>
            ))}
            {checkReports.length < 10 ? (
              <label
                htmlFor={"checkFile" + testDataId}
                className="createButton"
              >
                + 파일 첨부하기
                <input
                  className="none"
                  type="file"
                  id={"checkFile" + testDataId}
                  onChange={(e) => uploadFile(e, testDataId, "check")}
                  accept=".pdf"
                />
              </label>
            ) : (
              ""
            )}
          </div>
          {udi ? (
            <div className="flex1">
              <Link className="detailButton" to={udi.udiLink}>
                {testData.test_info.udi_di ?? ""} {udi.udi_pi}
              </Link>
            </div>
          ) : processReports.length == 0 && finalReports.length == 0 ? (
            <div className="flex1">
              <div
                className="createButton"
                onClick={(e) => {
                  alert(
                    "공정검사성적서와 완제품검사성적서를 먼저 업로드 해주세요."
                  );
                }}
              >
                UDI 생성하기
              </div>
            </div>
          ) : (
            <div className="flex1">
              <Link className="createButton" to={createUrl}>
                UDI 생성하기
              </Link>
            </div>
          )}
        </div>
      );
      tableArr.push(desSec);
    });
    setTestTable(tableArr);
  };

  const changeValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "demoDateInput") {
      setDemoDate(value);
    } else if (name === "salesDateInput") {
      setSalesDate(value);
    } else if (name === "returnDateInput") {
      setReturnDate(value);
    } else if (name === "warrantyMonthsInput") {
      setWarrantyMonths(value);
    } else if (name === "versionInput") {
      setVersion(value);
    } else if (name === "buildUrlInput") {
      setBuildUrl(value);
    } else if (name === "typeInput") {
      setType(value);
    } else if (name === "routerModelInput") {
      setRouterModel(value);
    } else if (name === "routerSerialNumberInput") {
      setRouterSerialModel(value);
    } else if (name === "isDongleInput") {
      const checked = e.target.checked;

      if (checked) {
        setIsDongle(true);
      } else {
        setIsDongle(false);
      }
    } else if (name === "memoInput") {
      setMemo(value);
    } else if (name === "reportMemoInput") {
      setReportMemo(value);
    }
  };

  const editOnClick = () => {
    setIsEditMode(true);
  };

  const editReportOnClick = () => {
    setIsReportEditMode(true);
  };

  const updateOnClick = async () => {
    let targets = [];

    const demoDateChanged = demoDate !== originDemoDate;
    const salesDateChanged = salesDate !== originSalesDate;
    const returnDateChanged = returnDate !== originReturnDate;
    const warrantyMonthsChanged = warrantyMonths !== originWarrantyMonths;
    const versionChanged = version !== originVersion;
    const typeChanged = type !== originType;
    const buildUrlChanged = buildUrl !== originBuildUrl;
    const routerModelChanged = routerModel !== originRouterModel;
    const routerSerialModelChanged =
      routerSerialModel !== originRouterSerialNumber;
    const isDongleChanged = isDongle !== originIsDongle;
    const memoChanged = memo !== originMemo;

    if (
      !demoDateChanged &&
      !salesDateChanged &&
      !returnDateChanged &&
      !warrantyMonthsChanged &&
      !versionChanged &&
      !typeChanged &&
      !buildUrlChanged &&
      !routerModelChanged &&
      !routerSerialModelChanged &&
      !isDongleChanged &&
      !memoChanged
    ) {
      alert(
        "변경된 사항이 없습니다.\n하나이상 변경 후 수정 버튼을 눌러주세요."
      );
      return;
    }

    if (demoDateChanged) {
      targets.push({ target: "demo_date", value: demoDate });
    }
    if (salesDateChanged) {
      targets.push({ target: "sales_date", value: salesDate });
    }
    if (returnDateChanged) {
      targets.push({ target: "return_date", value: returnDate });
    }

    if (warrantyMonthsChanged) {
      targets.push({
        target: "warranty_months",
        value: warrantyMonths,
      });
    }
    if (versionChanged) {
      targets.push({ target: "version", value: version });
    }
    if (typeChanged) {
      // 데모 또는 전시일 경우 데모(전시)출고일이 필수
      // 판매일 경우 판매출고일이 필수
      // 회수일 경우 회수일이 필수
      if (type === "demo" && !demoDate) {
        alert("데모로 변경 시 데모(전시)출고일은 필수입니다.");
        return;
      } else if (type === "display" && !demoDate) {
        alert("전시로 변경 시 데모(전시)출고일은 필수입니다.");
        return;
      } else if (type === "sale" && !salesDate) {
        alert("판매로 변경 시 판매출고일은 필수입니다.");
        return;
      } else if (type === "return" && !returnDate) {
        alert("회수로 변경 시 회수일은 필수입니다.");
        return;
      }

      targets.push({ target: "type", value: type });
    }
    if (buildUrlChanged) {
      targets.push({ target: "build_url", value: buildUrl });
    }
    if (routerModelChanged) {
      targets.push({ target: "router_model", value: routerModel });
    }
    if (routerSerialModelChanged) {
      targets.push({
        target: "router_serial_number",
        value: routerSerialModel,
      });
    }
    if (isDongleChanged) {
      targets.push({ target: "is_dongle", value: isDongle });
    }
    if (memoChanged) {
      targets.push({ target: "memo", value: memo });
    }

    // target 길이가 0이상이면 업데이트 콜
    if (targets.length > 0) {
      await updateTargetCall(targets);
    }
  };

  const updateReportOnClick = async () => {
    let targets = [];

    const reportMemoChanged = reportMemo !== originReportMemo;

    if (!reportMemoChanged) {
      alert(
        "변경된 사항이 없습니다.\n하나이상 변경 후 수정 버튼을 눌러주세요."
      );
      return;
    }

    if (reportMemoChanged) {
      targets.push({ target: "report_memo", value: reportMemo });
    }

    // target 길이가 0이상이면 업데이트 콜
    if (targets.length > 0) {
      await updateTargetCall(targets);
    }
  };

  const updateTargetCall = async (targets) => {
    const _confirm = window.confirm("정보를 수정 하시겠습니까?");

    if (_confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        let body = {};
        targets.forEach((target) => {
          body[target.target] = target.value;
        });

        const apiInfo = { ...api.productUpdate };
        apiInfo.url += target + "/";
        console.log(body);
        const res = await callAPI.request(apiInfo, body);

        if (res.status === 200) {
          alert("수정이 완료되었습니다.");
          window.location.reload();
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        if (err.response.status == 400) {
          if (
            err.response.data.error &&
            err.response.data.error === "Device return required"
          ) {
            alert(
              "기기 회수가 필요한 제품입니다. \n기기 회수 후 수정해주세요."
            );
          } else {
            alert("수정에 실패했습니다.\n다시 시도 해주세요.");
          }
        } else if (err.response.status === 403) {
          alert("새로고침 후 다시 시도 해주세요.");
        } else {
          alert("수정에 실패했습니다.\n다시 시도 해주세요.");
        }
      }
    }
  };

  const cancelOnClick = () => {
    const demoDateChanged = demoDate !== originDemoDate;
    const salesDateChanged = salesDate !== originSalesDate;
    const returnDateChanged = returnDate !== originReturnDate;
    const warrantyMonthsChanged = warrantyMonths !== originWarrantyMonths;
    const versionChanged = version !== originVersion;
    const typeChanged = type !== originType;
    const buildUrlChanged = buildUrl !== originBuildUrl;
    const routerModelChanged = routerModel !== originRouterModel;
    const routerSerialModelChanged =
      routerSerialModel !== originRouterSerialNumber;
    const isDongleChanged = isDongle !== originIsDongle;
    const memoChanged = memo !== originMemo;

    if (
      demoDateChanged &&
      salesDateChanged &&
      returnDateChanged &&
      warrantyMonthsChanged &&
      versionChanged &&
      typeChanged &&
      buildUrlChanged &&
      routerModelChanged &&
      routerSerialModelChanged &&
      isDongleChanged &&
      memoChanged
    ) {
      const confirm = window.confirm(
        "취소 시 변경된 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }

    setDemoDate(originDemoDate);
    setSalesDate(originSalesDate);
    setReturnDate(originReturnDate);
    setWarrantyMonths(originWarrantyMonths);
    setType(originType);
    setVersion(originVersion);
    setType(originType);
    setBuildUrl(originBuildUrl);
    setRouterModel(originRouterModel);
    setRouterSerialModel(originRouterSerialNumber);
    setIsDongle(originIsDongle);
    setMemo(originMemo);

    setIsEditMode(false);
  };

  const cancelReportOnClick = () => {
    const reportMemoChanged = reportMemo !== originReportMemo;

    if (reportMemoChanged) {
      const confirm = window.confirm(
        "취소 시 변경된 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }

    setReportMemo(originReportMemo);
    setIsReportEditMode(false);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/product" className="conTitleMenu">
            제품관리
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />
        </div>

        <div className="conDes">
          <div className="flexwrap">
            <div className="flex1 mr50">
              <TitleBox className="mb10" title="제품 정보" />
              <div>
                <div className="flexwrap">
                  <CommonBox title="제품명" content={productName} />
                  <LinkBox
                    title="클라이언트"
                    content={client.name + " (" + client.client_code + ")"}
                    link={clientUrl}
                  />
                </div>
                <div className="flexwrap">
                  <EditableBox
                    title="버전"
                    content={version}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    name="versionInput"
                  />
                  {isEditMode || !buildUrl ? (
                    <EditableBox
                      title="빌드 URL"
                      content={buildUrl ? buildUrl : "-"}
                      isEditMode={isEditMode}
                      changeValue={changeValue}
                      name="buildUrlInput"
                    />
                  ) : (
                    <LinkBox
                      title="빌드 URL"
                      link={buildUrl}
                      className="shortcutButton"
                      content="바로가기"
                      target="_blank"
                    />
                  )}

                  {/* <CommonBox
                title="기기(PC/HMD)"
                content={
                  device && device.length > 0 ? (
                    <Link className="detailButton mr10" to={deviceUrl}>
                      {device.filter((item) => item.type == "pc").length} 대 /{" "}
                      {device.filter((item) => item.type == "hmd").length} 대
                    </Link>
                  ) : (
                    <span className="mr10">0 대 / 0 대</span>
                  )
                }
              />{" "} */}
                </div>
                <div className="flexwrap">
                  <CommonBox
                    title="AS 보증기간"
                    content={
                      isEditMode ? (
                        <select
                          name="warrantyMonthsInput"
                          value={warrantyMonths}
                          onChange={changeValue}
                          className="width70 center"
                        >
                          {[...Array(121).keys()].map((num) => (
                            <option key={num} value={num}>
                              {num} 개월
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span>
                          {warrantyMonths
                            ? warrantyMonths + "개월 (" + warrantyDate + ")"
                            : "-"}
                        </span>
                      )
                    }
                  />
                  <MasterInfoBox
                    masterUser={masterUser}
                    createdDate={createdDate}
                    masterUrl={masterUrl}
                  />
                </div>
              </div>
            </div>
            <div className="flex1">
              <TitleBox className="mb10" title="출고 정보" />
              <div>
                <div className="flexwrap">
                  <CommonBox
                    title="출고 유형"
                    content={
                      isEditMode ? (
                        <select
                          className="width70 center"
                          onChange={changeValue}
                          value={type}
                          name="typeInput"
                        >
                          <option value="demo" selected={type === "demo"}>
                            데모
                          </option>
                          <option value="display" selected={type === "display"}>
                            전시
                          </option>
                          <option value="sale" selected={type === "sale"}>
                            판매
                          </option>
                          <option value="return" selected={type === "return"}>
                            회수
                          </option>
                        </select>
                      ) : (
                        <span> {TYPE[type]} </span>
                      )
                    }
                  />
                  <EditableBox
                    title="데모(전시)출고일"
                    content={demoDate ? demoDate : "-"}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    name="demoDateInput"
                    type="date"
                  />
                </div>
                <div className="flexwrap">
                  <EditableBox
                    title="판매출고일"
                    content={salesDate ? salesDate : "-"}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    name="salesDateInput"
                    type="date"
                  />
                  <EditableBox
                    title="회수일"
                    content={returnDate ? returnDate : "-"}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    name="returnDateInput"
                    type="date"
                  />
                </div>
                <div className="flexwrap">
                  <CommonBox
                    title="공유기 모델"
                    content={
                      isEditMode ? (
                        <select
                          className="width70 center"
                          onChange={changeValue}
                          value={routerModel}
                          name="routerModelInput"
                        >
                          <option value="" selected={!routerModel}>
                            -
                          </option>
                          {routerModelList.map((name) => (
                            <option
                              value={name}
                              selected={routerModel === name}
                            >
                              {name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span> {routerModel ? routerModel : "-"} </span>
                      )
                    }
                  />
                  <EditableBox
                    title="공유기 S/N"
                    content={routerSerialModel ? routerSerialModel : "-"}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    name="routerSerialNumberInput"
                  />
                </div>
                <div className="flexwrap">
                  <CommonBox
                    title="동글 출고 여부"
                    content={
                      isEditMode ? (
                        <input
                          type="checkbox"
                          name="isDongleInput"
                          checked={isDongle}
                          onChange={changeValue}
                        />
                      ) : (
                        <span> {isDongle ? "O" : "X"} </span>
                      )
                    }
                  />
                  <EmptyBox />
                </div>
              </div>
            </div>
          </div>
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">비고</div>
              <div className="flex1" />
            </div>
            <div>
              {isEditMode ? (
                <textarea
                  name="memoInput"
                  className="memoInput"
                  value={memo}
                  onChange={(e) => {
                    setMemo(e.target.value);
                  }}
                ></textarea>
              ) : (
                <div className="memo">{memo ? memo : ""}</div>
              )}
            </div>
          </div>
        </div>
        <div className="conDesDes pt10 pb10">
          <div className="flexwrap">
            <div className="flex1"></div>
            {editButtons(isEditMode, updateOnClick, cancelOnClick, editOnClick)}
          </div>
        </div>
        <div className="conDes mb20">
          <div className="conDesTitle">
            <div className="flexwrap">
              <div className="fwb">기기 정보</div>
              <div className="flex1" />
              <Link className="createButton" to={createDeviceUrl}>
                + 기기 추가
              </Link>
            </div>
          </div>
          <div className="flex1"></div>
          <div className="tableWrap" style={{ marginBottom: "0" }}>
            <div className="tableCount">총 {device.length}개</div>
            {deviceTable}
          </div>
        </div>

        {isMedical ? (
          <>
            <div className="conDes">
              <TitleBox title="의료기기 정보" />
              <div>
                <div className="flexwrap">
                  <LinkBox
                    title="품질관리자"
                    content={qualityMasterUser.name}
                    link={qualityMasterUrl}
                  />
                  <LinkBox
                    title="UDI 개수 / 검사 개수"
                    // udi 개수는 test.udi가 null이 아닌 것의 개수
                    content={
                      test.filter((item) => item.udi !== null).length +
                      " / " +
                      test.length
                    }
                    link={udiUrl}
                  />
                </div>
              </div>

              <TitleBox title="검사 · 성적서 · UDI 정보" />
              <div className="tableWrap" style={{ marginBottom: "0" }}>
                <div className="tableCount">총 {test.length}개</div>
                {testTable}
              </div>
              <div className="conDesDes mt20">
                <div className="flexwrap">
                  <div className="fwb conInputTip">성적서 비고</div>
                  <div className="flex1" />
                </div>
                <div>
                  {isReportEditMode ? (
                    <textarea
                      name="reportMemoInput"
                      className="memoInput"
                      value={reportMemo}
                      onChange={(e) => {
                        setReportMemo(e.target.value);
                      }}
                    ></textarea>
                  ) : (
                    <div className="memo">{reportMemo ? reportMemo : ""}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="flexwrap">
              <div className="flex1"></div>
              {editButtons(
                isReportEditMode,
                updateReportOnClick,
                cancelReportOnClick,
                editReportOnClick
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ProductDetailComponent;
