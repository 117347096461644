import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import {
  dateFormat,
  datetimeFormat,
  phoneWithHyphen,
} from "../../../modules/formatter";
import {
  TitleBox,
  CommonBox,
  CopyBox,
  EditableBox,
  LinkBox,
  editButtons,
  MasterInfoBox,
  EmptyBox,
} from "../../../modules/commonTable";

function UdiDetailComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [udiDi, setUdiDi] = useState("");
  const [udiPi, setUdiPi] = useState("");
  const [testName, setTestName] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [client, setClient] = useState({});
  const [productName, setProductName] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [masterUser, setMasterUser] = useState({});

  const [productUrl, setProductUrl] = useState("");
  const [clientUrl, setClientUrl] = useState("");
  const [masterUrl, setMasterUrl] = useState("");

  const [originUdiPi, setOriginUdiPi] = useState("");
  const [originIssueDate, setOriginIssueDate] = useState("");

  // 수정 모드
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (!params.target) {
      alert("잘못된 접근입니다.");
      navigate("/productmanagement/udi");
    }

    callInfo();
  }, []);

  const callInfo = async () => {
    try {
      const target = params.target;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.udiDetail };
      apiInfo.url += target + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const data = res.data;
        const test = data.test;

        setTestName(test.test_info.test_name);
        setUdiDi(test.test_info.udi_di);
        setUdiPi(data.udi_pi);
        setIssueDate(dateFormat(data.issue_date));
        setClient(data.client);
        setProductName(data.product.product_type.product_name);
        setCreatedDate(datetimeFormat(data.created_date));
        setMasterUser(data.master_user);

        setOriginUdiPi(data.udi_pi);
        setOriginIssueDate(dateFormat(data.issue_date));

        setClientUrl("/productmanagement/client/detail/" + data.client.id);
        setProductUrl("/productmanagement/product/detail/" + data.product.id);
        setMasterUrl("/accountmanagement/master/detail/" + data.master_user.id);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const changeValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "udiDiInput") {
      setUdiDi(value);
    } else if (name === "udiPiInput") {
      setUdiPi(value);
    } else if (name === "issueDateInput") {
      setIssueDate(value);
    }
  };

  const editOnClick = () => {
    setIsEditMode(true);
  };

  const updateOnClick = async () => {
    let targets = [];

    const udiPiChanged = udiPi !== originUdiPi;
    const issueDateChanged = issueDate !== originIssueDate;

    if (!udiPiChanged && !issueDateChanged) {
      alert(
        "변경된 사항이 없습니다.\n하나이상 변경 후 수정 버튼을 눌러주세요."
      );
      return;
    }

    if (udiPiChanged) {
      if (udiPi.length < 1 || udiPi.length > 100) {
        alert("UDI PI는 1자 이상 100자 이하로 입력해주세요.");
        return;
      }
      targets.push({ target: "udi_pi", value: udiPi });
    }
    if (issueDateChanged) {
      targets.push({ target: "issue_date", value: issueDate });
    }

    // target 길이가 0이상이면 업데이트 콜
    if (targets.length > 0) {
      await updateTargetCall(targets);
    }
  };

  const updateTargetCall = async (targets) => {
    const _confirm = window.confirm("정보를 수정 하시겠습니까?");

    if (_confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        let body = {};
        targets.forEach((target) => {
          body[target.target] = target.value;
        });

        const apiInfo = { ...api.udiUpdate };

        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo, body);

        if (res.status === 200) {
          alert("수정이 완료되었습니다.");
          window.location.reload();
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        if (err.response.status === 400) {
          // udi_di 객체가 err.response.data 안에 존재하는지 확인
          if (err.response.data.udi_di) {
            alert("이미 존재하는 UDI DI입니다.");
          } else if (err.response.data.udi_pi) {
            alert("이미 존재하는 UDI PI입니다.");
          }
        } else {
          alert("수정에 실패했습니다.\n다시 시도 해주세요.");
        }
      }
    }
  };

  const cancelOnClick = () => {
    const udiPiChanged = udiPi !== originUdiPi;
    const issueDateChanged = issueDate !== originIssueDate;

    if (udiPiChanged || issueDateChanged) {
      const confirm = window.confirm(
        "취소 시 변경된 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }

    setUdiPi(originUdiPi);
    setIssueDate(originIssueDate);

    setIsEditMode(false);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/client" className="conTitleMenu">
            UDI
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />
        </div>
        <div className="conDes">
          <TitleBox title="UDI 정보" />
          <div>
            <div className="flexwrap">
              <CommonBox title="검사" content={testName} />
              <LinkBox title="제품" content={productName} link={productUrl} />
            </div>
            <div className="flexwrap">
              <CommonBox title="UDI DI" content={udiDi} />
              <LinkBox
                title="클라이언트"
                content={client.name + " (" + client.client_code + ")"}
                link={clientUrl}
              />
            </div>
            <div className="flexwrap">
              <EditableBox
                title="UDI PI"
                content={udiPi}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="udiPiInput"
              />
              <MasterInfoBox
                masterUser={masterUser}
                createdDate={createdDate}
                masterUrl={masterUrl}
              />
            </div>
            <div className="flexwrap">
              <EditableBox
                title="발급일"
                content={issueDate}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="issueDateInput"
                type="date"
              />
              <EmptyBox />
            </div>
          </div>
          <div className="conDesDes pt10">
            <div className="flexwrap">
              <div className="flex1"></div>
              {editButtons(
                isEditMode,
                updateOnClick,
                cancelOnClick,
                editOnClick
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UdiDetailComponent;
