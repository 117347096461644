import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import {
  dateFormat,
  datetimeFormat,
  phoneWithHyphen,
} from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { downloadXLSX } from "../../../modules/downloadExcel";
import { enterChecker } from "../../../modules/common";

function ClientComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [clientDataList, setClientDataList] = useState([]);

  const [clientTable, setClientTable] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");
  // page view
  const [pageSec, setPageSec] = useState([]);

  const [agency, setAgency] = useState({
    all: "대리점",
  });
  const [selectedAgency, setSelectedAgency] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("client-name");
  const pageRowMax = 10;

  // 최초 데이터 셋
  useEffect(() => {
    callList();

    if (params.search && params.keyword) {
      setSearchText(params.keyword);
      setSearchType(params.search);
    } else {
      setSearchText("");
      setSearchType("client-name");
    }
  }, []);

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  useEffect(() => {
    let list = clientDataList;

    if (params.search && params.keyword) {
      list = makeSearchedArr(list, params.search, params.keyword);
    }

    if (clientTable.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [clientDataList]);

  // 필터링 및 정렬 적용
  useEffect(() => {
    let list = clientDataList;
    let filteredList = makeSearchedArr(list, searchType, searchText);

    makeTable(filteredList);
    makePageSec(filteredList);
  }, [clientDataList, selectedAgency]);

  const handleDownloadXLSX = () => {
    downloadXLSX(clientDataList, "클라이언트");
  };

  const handleAgencyChange = (e) => {
    setSelectedAgency(e.target.value); // 대리점 업데이트
  };

  // 대리점 필터 적용
  const filterClient = (clientDataList) => {
    if (selectedAgency !== "all") {
      clientDataList = clientDataList.filter(
        (client) => client.client_agency.name === selectedAgency
      );
    }
    return clientDataList;
  };

  const makeTable = (datas, targetPage = null) => {
    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle clientTable">
        <div className="flex1 br1g">클라이언트 코드</div>
        <div className="flex1 br1g">고객사명</div>
        <div className="flex1 br1g">
          <select
            className="selectInTable"
            value={selectedAgency}
            onChange={handleAgencyChange}
          >
            {Object.keys(agency).map((key) => (
              <option key={key} value={key}>
                {agency[key]}
              </option>
            ))}
          </select>
        </div>
        <div className="flex1 br1g">연락처</div>
        <div className="flex1 br1g">제품</div>
        <div className="flex1 br1g">보유 기기(PC/HMD)</div>
        <div className="flex1">생성 정보</div>
      </div>
    );
    tableArr.push(titleSec);

    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }

    const thisPage = datas.slice(f, l);

    // contents
    // testclientTable -> data
    thisPage.forEach((client) => {
      const masterUser = client.master_user ? client.master_user : {};
      const clientUser = client.client_user ? client.client_user : {};
      const clientAgency = client.client_agency ? client.client_agency : {};

      const productLink = "/productmanagement/product/1/client/" + client.id;
      const deviceLink = "/productmanagement/device/1/client/" + client.id;
      const clientLink = "/productmanagement/client/detail/" + client.id;
      // const clientAccountLink =
      //   "/accountmanagement/client/detail/" + clientUser.id;
      const masterAccountLink =
        "/accountmanagement/master/detail/" + masterUser.id;

      const desSec = (
        <Link to={clientLink} className="flexwrap tableCon clientTable">
          <div className="flex1 br1g">{client.client_code}</div>
          <div className="flex1 br1g">{client.name}</div>
          <div className="flex1 br1g">
            {clientAgency.name ? clientAgency.name : "-"}{" "}
          </div>
          <div className="flex1 br1g">
            {client.phone ? phoneWithHyphen(client.phone) : "-"}
          </div>
          <div className="flex1 br1g">
            {client.product_count ? (
              <Link className="detailButton" to={productLink}>
                {client.product_count} 개
              </Link>
            ) : (
              "0 개"
            )}
          </div>
          <div className="flex1 br1g">
            {client.device_count &&
            client.device_count.hmd.use + client.device_count.pc.use > 0 ? (
              <Link className="detailButton" to={deviceLink}>
                {client.device_count.pc.use} 대 / {client.device_count.hmd.use}{" "}
                대
              </Link>
            ) : (
              "0 대"
            )}
          </div>
          <div className="flex1">
            {datetimeFormat(client.created_date)}
            <br />
            {masterUser.name ? (
              <Link className="detailButton" to={masterAccountLink}>
                {masterUser.name}
              </Link>
            ) : (
              "-"
            )}
          </div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setClientTable(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(pageSelected / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (pageSelected > 9) {
      const prePage = (Math.floor(pageSelected / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" < "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === pageSelected) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" "}
          {">"}{" "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.clientList);

      if (res.status === 200) {
        // 성공
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });

          let AGENCY = {
            all: "대리점",
          };
          list.forEach((client) => {
            if (client.client_agency && client.client_agency.name) {
              if (!AGENCY[client.client_agency.name]) {
                AGENCY[client.client_agency.name] = client.client_agency.name;
              }
            }
          });
          setAgency(AGENCY);
        }
        setClientDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const pageClicked = (target) => {
    let search = "";
    let keyword = "";

    if (searchType !== "" && searchText !== "") {
      search = searchType;
      keyword = searchText;
    }

    if (search && keyword) {
      navigate(
        "/productmanagement/client/" +
          (target + 1) +
          "/" +
          search +
          "/" +
          keyword
      );
    } else {
      navigate("/productmanagement/client/" + (target + 1));
    }
    if (clientDataList.length !== 0) {
      let list = clientDataList;

      if (search !== "" && keyword !== "") {
        list = makeSearchedArr(list, search, keyword);
      }

      makeTable(list, target);
      makePageSec(list, target);
    } else {
      callList();
    }
  };

  const makeSearchedArr = (clientDataList, searchType, searchText) => {
    let searchResultArr = [];
    searchText = searchText + "";

    for (let i = 0; i < clientDataList.length; i++) {
      const client = clientDataList[i];

      let add = false;
      switch (searchType) {
        case "client":
          if (client.id == searchText) {
            add = true;
          }
          break;
        case "client-name":
          if (client && client.name && client.name.includes(searchText)) {
            add = true;
          }
          break;
        case "client-code":
          if (
            client &&
            client.client_code &&
            client.client_code.includes(searchText)
          ) {
            add = true;
          }
          break;
        case "client-phone":
          searchText = searchText.replace(/-/g, "");
          if (client && client.phone && client.phone.includes(searchText)) {
            add = true;
          }

          break;
        case "client-account":
          if (
            client.client_user &&
            client.client_user.user_id &&
            client.client_user.user_id.includes(searchText)
          ) {
            add = true;
          }

          break;

        default:
          break;
      }
      if (add) {
        searchResultArr.push(client);
      }
    }

    // 대리점 필터 적용
    searchResultArr = filterClient(searchResultArr);

    return searchResultArr;
  };

  const searchClick = () => {
    // 검색어가 없을 때
    if (searchText === "") {
      if (clientDataList.length !== 0) {
        let list = filterClient(clientDataList);

        makeTable(list, 0);
        makePageSec(list, 0);
      } else {
        callList();
      }
      navigate("/productmanagement/client/1/", { replace: true });
      return;
      // reset
    }

    navigate("/productmanagement/client/1/" + searchType + "/" + searchText, {
      replace: true,
    });

    if (clientDataList.length !== 0) {
      let list = clientDataList;
      if (searchType && searchText) {
        list = makeSearchedArr(list, searchType, searchText);
      }
      makeTable(list, 0);
      makePageSec(list, 0);
    } else {
      callList();
    }
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/client" className="conTitleMenu">
            클라이언트
          </Link>
          <div className="conTitleMenu"></div>
          <div className="flex1" />
          <div className="downloadButton" onClick={handleDownloadXLSX}>
            엑셀다운로드
          </div>
          <Link to="/productmanagement/client/create" className="createButton">
            + 클라이언트 생성
          </Link>
        </div>
        {!loading ? (
          <div className="tableWrap">
            <div className="tableCount">{dataCount}</div>
            {clientTable}
            <div className="pageSec">
              <div className="flex1" />
              {pageSec}
              <div className="flex1" />
            </div>
            {params.search !== "client" ? (
              <div className="searchSec ">
                <div className="flex1" />
                <div className="">
                  <select
                    name="searchType"
                    style={{ width: "170px", padding: "5px" }}
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    value={searchType}
                  >
                    <option value="client-name">클라이언트 고객사명</option>
                    <option value="client-code">클라이언트 코드</option>
                    <option value="client-phone">연락처</option>
                    <option value="client-account">계정</option>
                  </select>
                </div>
                <div className="ml10">
                  <input
                    placeholder="검색어를 입력하세요"
                    name="searchText"
                    className="searchinput"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      enterChecker(e, searchClick);
                    }}
                  />
                </div>
                <div className="ml10">
                  <div
                    style={{ cursor: "pointer" }}
                    className="searchButton plainbtn"
                    onClick={searchClick}
                  >
                    검색
                  </div>
                </div>
                <div className="flex1" />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ClientComponent;
