import * as XLSX from "xlsx";
import { nowDate } from "./formatter";

// XLSX 다운로드 함수
export const downloadXLSX = (data, pageName) => {
  // data가 없으면 빠져나감
  if (!data || data.length === 0) {
    alert("추출할 데이터가 없습니다.");
    return;
  }

  // data내의 오브젝트의 id 키값으로 순차적 정렬
  data = data.sort(function (a, b) {
    return a.id - b.id;
  });

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();

  // 시트 생성
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // 파일 저장
  XLSX.writeFile(wb, `${pageName}_${nowDate()}.xlsx`);
};
