import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat, datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { downloadXLSX } from "../../../modules/downloadExcel";
import { getProfile } from "../../../modules/decodeToken";
import { enterChecker } from "../../../modules/common";

function ProductComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [productDataList, setProductDataList] = useState([]);

  const [productTable, setProductTable] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");

  // page view
  const [pageSec, setPageSec] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("client-name");

  const [agency, setAgency] = useState({
    all: "대리점",
  });
  // 필터
  const [selectedType, setSelectedType] = useState("all");
  const [selectedAgency, setSelectedAgency] = useState("all");
  const [sortOption, setSortOption] = useState({
    field: "",
    order: "asc",
  });

  const pageRowMax = 10;

  // 출고 유형
  const TYPE = {
    all: "출고 유형", // 새로운 유형 추가
    demo: "데모",
    display: "전시",
    sale: "판매",
    return: "회수",
  };

  // 최초 데이터 셋
  useEffect(() => {
    setUserProfile();
    callList();

    if (params.search && params.keyword) {
      setSearchText(params.keyword);
      setSearchType(params.search);
    } else {
      setSearchText("");
      setSearchType("client-name");
    }
  }, []);

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  useEffect(() => {
    let list = productDataList;

    if (params.search && params.keyword) {
      list = makeSearchedArr(list, params.search, params.keyword);
    }

    if (productTable.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [productDataList]);

  // 필터링 및 정렬 적용
  useEffect(() => {
    let list = productDataList;
    let filteredList = makeSearchedArr(list, searchType, searchText);

    makeTable(filteredList);
    makePageSec(filteredList);
  }, [productDataList, selectedType, selectedAgency, sortOption]);

  const handleDownloadXLSX = () => {
    downloadXLSX(productDataList, "제품");
  };

  // 출고 유형 변경 이벤트 핸들러
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value); // 출고 유형 업데이트
  };

  const handleAgencyChange = (e) => {
    setSelectedAgency(e.target.value); // 대리점 업데이트
  };

  // 데모(전시)출고일, 판매출고일 정렬 이벤트 핸들러
  const handleSortChange = (field) => {
    setSortOption({
      field: field,
      order:
        sortOption.field === field && sortOption.order === "asc"
          ? "desc"
          : "asc",
    });
  };

  // 필터 적용
  const filterProduct = (productDataList) => {
    // 대리점 필터
    if (selectedAgency !== "all") {
      productDataList = productDataList.filter(
        (product) =>
          product.client &&
          product.client.client_agency &&
          product.client.client_agency.name === selectedAgency
      );
    }

    // 출고 유형 필터
    if (selectedType !== "all") {
      productDataList = productDataList.filter(
        (product) => product.type === selectedType
      );
    }
    return productDataList;
  };

  const compareFunction = (a, b) => {
    const aValue = a[sortOption.field] || "";
    const bValue = b[sortOption.field] || "";

    if (!aValue && !bValue) return 0; // 둘 다 빈 값이면 순서 유지
    if (!aValue) return 1; // a가 빈 값이면 b를 앞으로
    if (!bValue) return -1; // b가 빈 값이면 a를 앞으로

    if (sortOption.order === "asc") {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  };

  const makeTable = (datas, targetPage = null) => {
    // 정렬
    datas.sort(compareFunction);

    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle productTable">
        <div className="flex1 br1g">제품명</div>
        <div className="flex1 br1g">
          <select
            className="selectInTable"
            value={selectedType}
            onChange={handleTypeChange}
          >
            {Object.keys(TYPE).map((key) => (
              <option key={key} value={key}>
                {TYPE[key]}
              </option>
            ))}
          </select>
        </div>
        <div className="flex1 br1g">
          데모(전시)출고일
          <span
            className="sortable"
            onClick={() => handleSortChange("demo_date")}
          >
            {sortOption.field === "demo_date" && sortOption.order === "asc"
              ? " ▲"
              : " ▼"}
          </span>
        </div>
        <div className="flex1 br1g">
          판매출고일
          <span
            className="sortable"
            onClick={() => handleSortChange("sales_date")}
          >
            {sortOption.field === "sales_date" && sortOption.order === "asc"
              ? " ▲"
              : " ▼"}
          </span>
        </div>
        <div className="flex1 br1g">클라이언트</div>
        <div className="flex1 br1g">
          <select
            className="selectInTable"
            value={selectedAgency}
            onChange={handleAgencyChange}
          >
            {Object.keys(agency).map((key) => (
              <option key={key} value={key}>
                {agency[key]}
              </option>
            ))}
          </select>
        </div>
        <div className="flex1 br1g">기기(PC/HMD)</div>
        <div className="flex1 br1g">UDI / 검사</div>
        <div className="flex1">생성 정보</div>
      </div>
    );
    tableArr.push(titleSec);

    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;
    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }

    const thisPage = datas.slice(f, l);

    // contents
    // testproductTable -> data
    thisPage.forEach((product) => {
      const masterUser = product.master_user ? product.master_user : {};
      const client = product.client ? product.client : {};
      const isMedical = product.product_type.is_medical;

      const masterAccountLink =
        "/accountmanagement/master/detail/" + masterUser.id;
      const clientLink = "/productmanagement/client/detail/" + client.id;
      const udiLink = "/productmanagement/udi/1/product/" + product.id;
      const deviceLink = "/productmanagement/device/1/product/" + product.id;

      const linkTarget = "/productmanagement/product/detail/" + product.id;
      const desSec = (
        <Link
          key={product.id}
          to={linkTarget}
          className="flexwrap tableCon productTable"
        >
          <div className="flex1 br1g">{product.product_type.product_name}</div>
          <div className="flex1 br1g">{TYPE[product.type]}</div>
          <div className="flex1 br1g">
            {product.demo_date ? dateFormat(product.demo_date) : "-"}
          </div>
          <div className="flex1 br1g">
            {product.sales_date ? dateFormat(product.sales_date) : "-"}
          </div>
          <div className="flex1 br1g">
            <Link className="detailButton" to={clientLink}>
              {client.name}
            </Link>
          </div>
          <div className="flex1 br1g">
            {client.client_agency ? client.client_agency.name : "-"}
          </div>
          <div className="flex1 br1g">
            {product.pc_count || product.hmd_count ? (
              <Link className="detailButton" to={deviceLink}>
                {product.pc_count} 대 / {product.hmd_count} 대
              </Link>
            ) : (
              "0 대 / 0 대"
            )}
          </div>
          <div className="flex1 br1g">
            {isMedical ? (
              <Link className="detailButton" to={udiLink}>
                {product.udi_count} / {product.test_count}
              </Link>
            ) : (
              "-"
            )}
          </div>
          <div className="flex1">
            {datetimeFormat(product.created_date)}
            <br />
            {masterUser.name ? (
              <Link className="detailButton" to={masterAccountLink}>
                {masterUser.name}
              </Link>
            ) : (
              "-"
            )}
          </div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setProductTable(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(pageSelected / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (pageSelected > 9) {
      const prePage = (Math.floor(pageSelected / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" < "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === pageSelected) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" > "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.productList);

      if (res.status === 200) {
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });

          // 대리점 정보 가져오기
          let AGENCY = {
            all: "대리점",
          };
          list.forEach((product) => {
            let client = product.client ? product.client : {};
            if (client.client_agency && client.client_agency.name) {
              if (!AGENCY[client.client_agency.name]) {
                AGENCY[client.client_agency.name] = client.client_agency.name;
              }
            }
          });
          setAgency(AGENCY);
        }

        if (user && !user.is_master) {
          let newList = [];
          for (let i = 0; i < list.length; i++) {
            const l = list[i];
            if (l.client_user === user.id) {
              newList.push(l);
            }
          }

          list = newList;
        }

        setProductDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const makeSearchedArr = (productDataList, searchType, searchText) => {
    let searchResultArr = [];
    searchText = searchText + "";

    for (let i = 0; i < productDataList.length; i++) {
      const product = productDataList[i];
      const client = product.client ? product.client : {};

      let add = false;
      switch (searchType) {
        case "product":
          if (product && product.id == searchText) {
            add = true;
          }
          break;
        case "client":
          if (client && client.id == searchText) {
            add = true;
          }
          break;
        case "client-name":
          if (client && client.name && client.name.includes(searchText)) {
            add = true;
          }
          break;
        case "client-code":
          if (
            client &&
            client.client_code &&
            client.client_code.includes(searchText)
          ) {
            add = true;
          }
          break;
        case "product-name":
          if (
            product &&
            product.product_type &&
            product.product_type.product_name &&
            product.product_type.product_name.includes(searchText)
          ) {
            add = true;
          }
          break;
        default:
          break;
      }
      if (add) {
        searchResultArr.push(product);
      }
    }

    // 필터 적용
    searchResultArr = filterProduct(searchResultArr);

    // 정렬 기준에 따라 필터된 리스트 정렬하기
    if (sortOption.field) {
      searchResultArr.sort((a, b) => {
        const aValue = a[sortOption.field] || ""; // null 값 처리
        const bValue = b[sortOption.field] || ""; // null 값 처리

        if (sortOption.order === "asc") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }

    return searchResultArr;
  };

  const pageClicked = (target) => {
    let search = "";
    let keyword = "";

    if (searchType !== "" && searchText !== "") {
      search = searchType;
      keyword = searchText;
    }

    // 검색어가 있을 경우 검색어 적용하여 이동
    if (search && keyword) {
      navigate(
        "/productmanagement/product/" +
          (target + 1) +
          "/" +
          search +
          "/" +
          keyword
      );
    } else {
      navigate("/productmanagement/product/" + (target + 1));
    }

    if (productDataList.length !== 0) {
      let list = productDataList;

      if (search !== "" && keyword !== "") {
        list = makeSearchedArr(list, search, keyword);
      }
      list = filterProduct(list);

      makeTable(list, target);
      makePageSec(list, target);
    } else {
      callList();
    }
  };

  const searchClick = () => {
    // 검색어가 없을 때
    if (searchText === "") {
      if (productDataList.length !== 0) {
        let list = productDataList;
        list = filterProduct(list);

        makeTable(list, 0);
        makePageSec(list, 0);
      } else {
        callList();
      }
      navigate("/productmanagement/product/1/", { replace: true });
      // reset
      return;
    }

    // 검색어가 있을 때
    navigate("/productmanagement/product/1/" + searchType + "/" + searchText, {
      replace: true,
    });

    // 출고 유형 선택 유지
    if (productDataList.length !== 0) {
      let list = productDataList;
      if (searchType && searchText) {
        list = makeSearchedArr(list, searchType, searchText);
      }
      makeTable(list, 0);
      makePageSec(list, 0);
    } else {
      callList();
    }
  };

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/product" className="conTitleMenu">
            제품
          </Link>
          <div className="conTitleMenu"></div>
          <div className="flex1" />
          <div className="downloadButton" onClick={handleDownloadXLSX}>
            엑셀다운로드
          </div>
        </div>
        {!loading ? (
          <div className="tableWrap">
            <div className="tableCount">{dataCount}</div>
            {productTable}

            <div className="pageSec">
              <div className="flex1" />
              {pageSec}
              <div className="flex1" />
            </div>
            {params.search !== "client" && params.search !== "product" ? (
              <div className="searchSec ">
                <div className="flex1" />
                <div className="">
                  <select
                    name="searchType"
                    style={{ width: "170px", padding: "5px" }}
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    value={searchType}
                  >
                    <option value="client-name">클라이언트 고객사명</option>
                    <option value="client-code">클라이언트 코드</option>
                    <option value="product-name">제품명</option>
                  </select>
                </div>
                <div className="ml10">
                  <input
                    placeholder="검색어를 입력하세요"
                    name="searchText"
                    className="searchinput"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      enterChecker(e, searchClick);
                    }}
                  />
                </div>
                <div className="ml10">
                  <div
                    style={{ cursor: "pointer" }}
                    className="searchButton plainbtn"
                    onClick={searchClick}
                  >
                    검색
                  </div>
                </div>
                <div className="flex1" />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ProductComponent;
