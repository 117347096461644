import React from "react";
import { Route, Routes } from "react-router-dom";
// import useWindowDimensions from '../../modules/useWindowDimensions'

import TopComponent from "../menu/top";
import SideMenuComponent from "../menu/side";

import Notice from "./notice/notice.component";
import NoticeWrite from "./notice/notice.write.component";
import NoticeDetail from "./notice/notice.detail.component";

import Qna from "./qna/qna.component";
import QnaUsWrite from "./qna/qna.write.component";
import QnaUsDetail from "./qna/qna.detail.component";

import Faq from "./faq/faq.component";
import FaqWrite from "./faq/faq.write.component";
import FaqDetail from "./faq/faq.detail.component";

function CsMainComponent() {
  // const { height, width } = useWindowDimensions();

  return (
    <>
      <TopComponent />

      <div className="flexwrap tac">
        <SideMenuComponent />

        <Routes>
          <Route exact path="/" element={<Notice />} />

          <Route exact path="/notice/:page?" element={<Notice />} />
          <Route
            exact
            path="/notice/write/:target?"
            element={<NoticeWrite />}
          />
          <Route
            exact
            path="/notice/detail/:target?"
            element={<NoticeDetail />}
          />

          <Route exact path="/qna/:page?" element={<Qna />} />
          <Route exact path="/qna/write/:target?" element={<QnaUsWrite />} />
          <Route exact path="/qna/detail/:target?" element={<QnaUsDetail />} />

          <Route exact path="/faq/:page?" element={<Faq />} />
          <Route exact path="/faq/write/:target?" element={<FaqWrite />} />
          <Route exact path="/faq/detail/:target?" element={<FaqDetail />} />
        </Routes>
      </div>
    </>
  );
}

export default CsMainComponent;
