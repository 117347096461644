import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { downloadXLSX } from "../../../modules/downloadExcel";
import { getProfile } from "../../../modules/decodeToken";

function FAQComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const pageRowMax = 10;

  const [faqDataList, setFaqDataList] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");

  const [faqList, setFaqList] = useState([]);

  // page view
  const [pageSec, setPageSec] = useState([]);

  const handleDownloadXLSX = () => {
    downloadXLSX(faqDataList, "FAQ");
  };

  const makeTable = (datas, targetPage = null) => {
    const page =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    let f = page * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }
    const thisPage = datas.slice(f, l);

    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle">
        <div className="flex2 br1g">분류</div>
        <div className="width70 br1g">제목</div>
        <div className="flex2">생성일</div>
      </div>
    );
    tableArr.push(titleSec);

    // contents
    // testfaqList -> data
    thisPage.forEach((faq) => {
      const _at = new Date(faq.created_date);
      const dayText = dateFormat(_at);
      const linkTarget = "/cs/faq/detail/" + faq.id;
      const desSec = (
        <Link key={faq.id} to={linkTarget} className="flexwrap tableCon">
          <div className="flex2 tac">{faq.category}</div>
          <div className="width70 ">{faq.title}</div>
          <div className="flex2 tac">{dayText}</div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setFaqList(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const selectPage =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = selectPage * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(selectPage / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (selectPage > 9) {
      const prePage = (Math.floor(selectPage / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" "}
          {"<"}{" "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === selectPage) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" "}
          {">"}{" "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.faqList);

      if (res.status === 200) {
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });
        }
        setFaqDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  // 최초 데이터 셋
  useEffect(() => {
    setUserProfile();
    callList();
  }, []);

  // 테스트 데이터 만들기
  useEffect(() => {
    let list = faqDataList;
    if (faqList.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [faqDataList]);

  const pageClicked = (target) => {
    navigate("/cs/faq/" + (target + 1));

    if (faqDataList.length === 0) {
      callList();
    } else {
      makePageSec(faqDataList, target);
      makeTable(faqDataList, target);
    }
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="#" className="conTitleMenu">
            FAQ
          </Link>
          <div className="flex1" />

          {user && user.is_master ? (
            <>
              <div className="downloadButton" onClick={handleDownloadXLSX}>
                엑셀다운로드
              </div>
              <Link to="/cs/faq/write" className="linkButton">
                작성
              </Link>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="tableWrap">
          <div className="tableCount">{dataCount}</div>
          {faqList}

          <div className="pageSec">
            <div className="flex1" />
            {pageSec}
            <div className="flex1" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQComponent;
