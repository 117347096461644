import React from "react";
import "./spinner.css"; // CSS 파일은 아래에 설명합니다.

const LoadingSpinner = ({ isVisible }) => {
  return (
    <div className={`loading-overlay ${isVisible ? "visible" : ""}`}>
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
