import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { datetimeFormat } from "../../../modules/formatter";
import { getProfile } from "../../../modules/decodeToken";
import {
  TitleBox,
  CommonBox,
  EditableBox,
  editButtons,
  EmptyBox,
  LinkBox,
} from "../../../modules/commonTable";

function ClientInfoComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [clientName, setClientName] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientPassword, setClientPassword] = useState("****");
  const [clientLoginCount, setClientLoginCount] = useState(0);
  const [clientLastLogin, setClientLastLogin] = useState("");
  const [clientIsActive, setClientIsActive] = useState("X");

  const [originClientId, setOriginClientId] = useState("");

  const [clientUrl, setClientUrl] = useState("");
  const [clientLoginLogUrl, setClientLoginLogUrl] = useState("");

  // 수정 모드
  const [isEditMode, setIsEditMode] = useState(false);

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  useEffect(() => {
    if (!params.target) {
      // page 잘못됨
      return;
    }
    setUserProfile();

    const fetchData = async () => {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);
        const apiInfo = { ...api.clientUserDetail };

        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo);

        if (res.status === 200) {
          let data = res.data;
          setClientId(data.user_id);
          setOriginClientId(data.user_id);
          setClientName(data.client.name);
          setClientCode(data.client.client_code);
          setClientIsActive(data.is_active ? "O" : "X");
          setClientLoginCount(data.login_info.count);
          setClientLastLogin(
            data.login_info.last_login
              ? datetimeFormat(data.login_info.last_login)
              : ""
          );

          setClientUrl(
            "/productmanagement/client/detail/" + data.client.id + "/"
          );
          setClientLoginLogUrl(
            "/accountmanagement/loginlog/client/1/client-user/" + data.id
          );
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        navigate("/accountmanagement/client/");
      }
    };
    fetchData();
  }, []);

  const updateOnClick = async () => {
    console.log("수정");
    let targets = [];

    const passwordChanged = clientPassword !== "****" && clientPassword !== "";
    const idChanged = clientId !== originClientId;

    if (!passwordChanged && !idChanged) {
      alert(
        "변경된 사항이 없습니다.\n하나이상 변경 후 수정 버튼을 눌러주세요."
      );
      return;
    }
    if (idChanged) {
      if (clientId.length < 4 || clientId.length > 16) {
        alert("아이디는 4자 이상 16자 이하로 입력해주세요.");
        return;
      }
      targets.push({ target: "user_id", value: clientId });
    }
    if (passwordChanged) {
      if (clientPassword.length < 4 || clientPassword.length > 16) {
        alert("비밀번호는 4자 이상 16자 이하로 입력해주세요.");
        return;
      }
      targets.push({ target: "password", value: clientPassword });
    }

    // target 길이가 0이상이면 업데이트 콜
    if (targets.length > 0) {
      await updateTargetCall(targets);
    }
  };

  const updateTargetCall = async (targets) => {
    const _confirm = window.confirm("정보를 수정 하시겠습니까?");

    if (_confirm) {
      try {
        const target = params.target;

        const callAPI = new CallAPI(setLoading);

        let body = {};
        targets.forEach((target) => {
          body[target.target] = target.value;
        });

        const apiInfo = { ...api.clientUserUpdate };
        apiInfo.url += target + "/";

        const res = await callAPI.request(apiInfo, body);

        if (res.status === 200) {
          alert("수정이 완료되었습니다.");
          window.location.reload();
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        alert("수정에 실패했습니다.\n다시 시도 해주세요.");
      }
    }
  };

  const inactiveOnClick = async () => {
    // confirm 삭제하면 되돌릴 수 없습니다.
    const confirm = window.confirm(
      "비활성화 시 되돌릴 수 없습니다.\n그래도 삭제하시겠습니까?"
    );
    if (confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        const apiInfo = { ...api.clientUserDelete };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo);

        if (res.status === 200) {
          alert("성공적으로 비활성화되었습니다.");
          navigate("/accountmanagement/client/", { replace: true });
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        alert("비활성화에 실패했습니다.\n다시 시도 해주세요.");
      }
    }
  };

  const cancelOnClick = () => {
    const passwordChanged = clientPassword !== "****" && clientPassword !== "";
    const idChanged = clientId !== originClientId;

    if (passwordChanged || idChanged) {
      const confirm = window.confirm(
        "취소 시 변경된 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }

    setClientId(originClientId);
    setClientPassword("****");

    setIsEditMode(false);
  };

  const changeValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "id") {
      setClientId(value);
    } else if (name === "password") {
      setClientPassword(value);
    }
  };

  const focusPassword = () => {
    if (clientPassword === "****") {
      setClientPassword("");
    }
  };

  const editOnClick = () => {
    setIsEditMode(true);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/accountmanagement/client" className="conTitleMenu">
            클라이언트 계정
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />
        </div>
        <div className="conDes">
          <TitleBox title="클라이언트 계정 정보" />
          <div>
            <div className="flexwrap">
              <LinkBox
                title="클라이언트"
                content={clientName + " (" + clientCode + ")"}
                link={clientUrl}
              />
              {clientLoginCount ? (
                <LinkBox
                  title="로그인 정보"
                  content={"총 " + clientLoginCount + "회"}
                  link={clientLoginLogUrl}
                />
              ) : (
                <CommonBox title="로그인 정보" content={"총 0회"} />
              )}
            </div>
            <div className="flexwrap">
              <EditableBox
                title="아이디"
                content={clientId}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="id"
              />
              <CommonBox
                title="최근 로그인"
                content={clientLastLogin ? clientLastLogin : "-"}
              />
            </div>
            <div className="flexwrap">
              <EditableBox
                title="비밀번호"
                content={clientPassword}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="password"
                onClick={focusPassword}
              />
              <CommonBox title="활성 여부" content={clientIsActive} />
            </div>
          </div>
          {clientIsActive === "O" ? (
            <div className="conDesDes pt10">
              <div className="flexwrap">
                <div className="flex1" />
                <div
                  className="createButton"
                  style={{ margin: "5px", fontWeight: "bold" }}
                  onClick={inactiveOnClick}
                >
                  비활성화
                </div>
                {editButtons(
                  isEditMode,
                  updateOnClick,
                  cancelOnClick,
                  editOnClick
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientInfoComponent;
