import './App.css';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { legacy_createStore as createStore } from "redux";
import { Provider } from "react-redux";

// pages
import LoginPage from "./components/auth/login";
import LogoutPage from "./components/auth/logout";
import NotfoundPage from "./components/notfounded";
import Dashboard from "./components/dashboard/dashboard.component";
import ClientDashboard from "./components/dashboard/dashboard.client.component";

import Cs from "./components/cs/cs.component";
import Accountmanagement from "./components/accountmanagement/accountmanagement.component";
import Productmanagement from "./components/productmanagement/productmanagement.component";
import Myproduct from "./components/myproduct/myproduct.component";

import BuildPage from "./components/accountmanagement/build/build.component";

// store root
import rootReducer from "./store";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/logout" element={<LogoutPage />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/dashboard/client" element={<ClientDashboard />} />
        <Route exact path="/cs/*" element={<Cs />} />
        <Route
          exact
          path="/accountmanagement/*"
          element={<Accountmanagement />}
        />
        <Route
          exact
          path="/productmanagement/*"
          element={<Productmanagement />}
        />
        <Route exact path="/myproduct/*" element={<Myproduct />} />
        <Route exact path="/build/*" element={<BuildPage />} />
        <Route path={"*"} element={<NotfoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

