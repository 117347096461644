import {React, useState, useEffect} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from '../../modules/useWindowDimensions'


function SideMenuComponent() {

  const location = useLocation();
const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { height } = useWindowDimensions();

  const dashboardMenus = [{ target: "dashboard", name: "대시보드" }];
  const csMenus = [
    { target: "notice", name: "공지사항" },
    { target: "qna", name: "문의사항" },
    { target: "faq", name: "FAQ" },
  ];
  const myproductMenus = [{ target: "product", name: "제품정보" }];
  const productmanagementMenus = [
    { target: "client", name: "클라이언트" },
    { target: "product", name: "제품" },
    { target: "device", name: "기기" },
    { target: "udi", name: "UDI" },
  ];
  const accountmanagementMenus = [
    { target: "master", name: "관리자 계정" },
    { target: "client", name: "클라이언트 계정" },
    { target: "loginlog", name: "로그인 기록" },
  ];

  const [sideMenus, setSideMenus] = useState([<></>]);

  useEffect(() => {
    const pathname = window.location.pathname;

    let pathHeader = "";
    let menus = dashboardMenus;
    if (pathname.includes("dashboard")) {
      pathHeader = "/";
      menus = dashboardMenus;
    } else if (pathname.includes("cs")) {
      pathHeader = "/cs/";
      menus = csMenus;
    } else if (pathname.includes("myproduct")) {
      pathHeader = "/myproduct/";
      menus = myproductMenus;
    } else if (pathname.includes("productmanagement")) {
      pathHeader = "/productmanagement/";
      menus = productmanagementMenus;
    } else if (pathname.includes("accountmanagement")) {
      pathHeader = "/accountmanagement/";
      menus = accountmanagementMenus;
    }
    let menuTags = [];
    let targetI = 99;
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      if (pathname.includes(pathHeader + menu.target)) {
        targetI = i;
      }
    }
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      let targetStyle = {
        color: "gray",
        padding: "15px",
        textDecoration: "none",
      };
      if (targetI === 99 && i === 0) {
        targetStyle = {
          color: "black",
          padding: "15px",
          fontWeight: "bolder",
          textDecoration: "none",
        };
      }
      if (i === targetI) {
        targetStyle = {
          color: "black",
          padding: "15px",
          fontWeight: "bolder",
          textDecoration: "none",
        };
      }
      menuTags.push(
        <Link
          onClick={() => {
            if (
              location.pathname.includes(pathHeader + menu.target) &&
              location.pathname !== pathHeader + menu.target
            ) {
              navigate(pathHeader + menu.target);
              window.location.reload();
            }
          }}
          key={menu.target + i}
          to={pathHeader + menu.target}
          style={targetStyle}
        >
          {menu.name}
        </Link>
      );
    }

    setSideMenus(menuTags);
  }, [location]);

  return (
    <div
      className="flexcolwrap minw130 tac p15 tll fs13"
      style={{ height: height - 81, width: "150px" }}
    >
      {sideMenus}
      <div className="flex1" />
    </div>
  );
}

export default SideMenuComponent