import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import { dateFormat, datetimeFormat } from "../../../modules/formatter";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { enterChecker } from "../../../modules/common";

function ClientComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [clientDataList, setClientDataList] = useState([]);

  const [clientList, setClientList] = useState([]);
  const [dataCount, setDataCount] = useState("총 0개");

  // page view
  const [pageSec, setPageSec] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("user-id");
  const pageRowMax = 10;

  const makeTable = (datas, targetPage = null) => {
    let tableArr = [];
    // title
    const titleSec = (
      <div key="title" className="flexwrap tableTitle clientTable">
        <div className="flex1 br1g">아이디</div>
        <div className="flex1 br1g">클라이언트</div>
        <div className="flex1 br1g">생성일</div>
        <div className="flex1 br1g">최근로그인</div>
        <div className="flex1">활성 여부</div>
      </div>
    );
    tableArr.push(titleSec);

    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > datas.length) {
      l = datas.length;
    }

    const thisPage = datas.slice(f, l);

    // contents
    // testclientList -> data
    thisPage.forEach((clientUser) => {
      const _at = new Date(clientUser.created_date);

      const dayText = dateFormat(_at);
      const client = clientUser.client ? clientUser.client : {};
      const clientUserLoginInfo = clientUser.login_info;
      const clientAccountLink =
        "/accountmanagement/client/detail/" + clientUser.id;
      const clientLink = "/productmanagement/client/detail/" + client.id;
      const desSec = (
        <Link
          key={client.id}
          to={clientAccountLink}
          className="flexwrap tableCon clientTable"
        >
          <div className="flex1 br1g">{clientUser.user_id}</div>
          <div className="flex1 br1g">
            <Link className="detailButton" to={clientLink}>
              {client.name + " (" + client.client_code + ")"}
            </Link>
          </div>
          <div className="flex1 br1g">{dayText}</div>
          <div className="flex1 br1g">
            {clientUserLoginInfo.last_login
              ? datetimeFormat(clientUserLoginInfo.last_login)
              : "-"}
          </div>
          <div className="flex1">{clientUser.is_active ? "O" : "X"}</div>
        </Link>
      );
      tableArr.push(desSec);
    });

    setClientList(tableArr);
    setDataCount("총 " + datas.length + "개");
  };

  const makePageSec = (arr, targetPage = null) => {
    const pageSelected =
      targetPage !== null ? targetPage : params.page ? params.page - 1 : 0;

    // 페이지 처리를 위해 자르기
    let pageCnt = Math.ceil(arr.length / pageRowMax) - 1;

    let f = pageSelected * pageRowMax;
    let l = f + pageRowMax;
    if (l > arr.length) {
      l = arr.length;
    }

    let startPage = 0;
    let lastPage = 10;

    // 페이지 시작점 끝점 만들기
    startPage = Math.floor(pageSelected / 10) * 10;
    lastPage = startPage + 9;
    if (pageCnt < lastPage) {
      lastPage = pageCnt;
    }

    let pageArr = [];

    // 앞으로 넣기
    if (pageSelected > 9) {
      const prePage = (Math.floor(pageSelected / 10) - 1) * 10 + 9;
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(prePage);
          }}
        >
          {" "}
          {"<"}{" "}
        </div>
      );
    }

    for (let i = startPage; i <= lastPage; i++) {
      let targetStyle = { color: "gray", cursor: "pointer" };
      if (i === pageSelected) {
        targetStyle = {
          fontWeight: "bolder",
          color: "black",
          cursor: "pointer",
        };
      }
      pageArr.push(
        <div
          style={targetStyle}
          key={i + 100}
          onClick={() => {
            pageClicked(i);
          }}
        >
          {" "}
          {i + 1}{" "}
        </div>
      );
    }

    // 최종페이지보다 지금 마지막페이지가 작을경우
    if (pageCnt > lastPage) {
      pageArr.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            pageClicked(lastPage + 1);
          }}
        >
          {" "}
          {">"}{" "}
        </div>
      );
    }

    setPageSec(pageArr);
  };

  // 최초 데이터 셋
  useEffect(() => {
    callList();

    if (params.search && params.keyword) {
      setSearchText(params.keyword);
      setSearchType(params.search);
    } else {
      setSearchText("");
      setSearchType("user-id");
    }
  }, []);

  const callList = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const res = await callAPI.request(api.clientUserList);

      if (res.status === 200) {
        // 성공
        let list = res.data;
        if (list.length > 0) {
          list = list.sort(function (a, b) {
            return b.id - a.id;
          });
        }
        setClientDataList(list);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    let list = clientDataList;

    if (params.search && params.keyword) {
      list = makeSearchedArr(list, params.search, params.keyword);
    }

    if (clientList.length < 2) {
      makeTable(list);
    }
    if (pageSec.length === 0) {
      makePageSec(list);
    }
  }, [clientDataList]);

  const pageClicked = (target) => {
    let search = "";
    let keyword = "";

    if (searchType !== "" && searchText !== "") {
      search = searchType;
      keyword = searchText;
    }

    if (search && keyword) {
      navigate(
        "/accountmanagement/client/" +
          (target + 1) +
          "/" +
          search +
          "/" +
          keyword
      );
    } else {
      navigate("/accountmanagement/client/" + (target + 1));
    }
    if (clientDataList.length !== 0) {
      let list = clientDataList;

      if (search !== "" && keyword !== "") {
        list = makeSearchedArr(list, search, keyword);
      }

      makeTable(list, target);
      makePageSec(list, target);
    } else {
      callList();
    }
  };

  const makeSearchedArr = (clientDataList, searchType, searchText) => {
    let searchResultArr = [];
    searchText = searchText + "";

    for (let i = 0; i < clientDataList.length; i++) {
      const client = clientDataList[i];
      let add = false;
      switch (searchType) {
        case "user-id":
          if (client.user_id && client.user_id.includes(searchText)) {
            add = true;
          }
          break;
        case "client":
          if (client.client.name && client.client.name.includes(searchText)) {
            add = true;
          }

          break;
        default:
          break;
      }
      if (add) {
        searchResultArr.push(client);
      }
    }

    return searchResultArr;
  };

  const searchClick = () => {
    if (searchText === "") {
      if (clientDataList.length !== 0) {
        makeTable(clientDataList, 0);
        makePageSec(clientDataList, 0);
      } else {
        callList();
      }
      navigate("/accountmanagement/client/1/", { replace: true });
      // reset
      return;
      // reset
    }

    navigate("/accountmanagement/client/1/" + searchType + "/" + searchText, {
      replace: true,
    });

    if (clientDataList.length !== 0) {
      let list = clientDataList;
      if (searchType && searchText) {
        list = makeSearchedArr(list, searchType, searchText);
      }
      makeTable(list, 0);
      makePageSec(list, 0);
    } else {
      callList();
    }
  };

  useEffect(() => {
    if (!params.search || !params.keyword) {
      setSearchText("");
    }
  }, [location]);

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/accountmanagement/client" className="conTitleMenu">
            클라이언트 계정
          </Link>
          <div className="conTitleMenu"></div>
          <div className="flex1" />
        </div>
        {!loading ? (
          <div className="tableWrap">
            <div className="tableCount">{dataCount}</div>
            {clientList}
            <div className="pageSec">
              <div className="flex1" />
              {pageSec}
              <div className="flex1" />
            </div>
            <div className="searchSec ">
              <div className="flex1" />
              <div className="">
                <select
                  name="searchType"
                  style={{ width: "150px", padding: "5px" }}
                  onChange={(e) => {
                    setSearchType(e.target.value);
                  }}
                  value={searchType}
                >
                  <option value="user-id">아이디</option>
                  <option value="client">클라이언트</option>
                </select>
              </div>
              <div className="ml10">
                <input
                  placeholder="검색어를 입력하세요"
                  name="searchText"
                  className="searchinput"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    enterChecker(e, searchClick);
                  }}
                />
              </div>
              <div className="ml10">
                <div
                  style={{ cursor: "pointer" }}
                  className="searchButton plainbtn"
                  onClick={searchClick}
                >
                  검색
                </div>
              </div>
              <div className="flex1" />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ClientComponent;
