import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";

function ClientCreateComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [clientUrl, setClientUrl] = useState("/productmanagement/client/");

  useEffect(() => {
    callInfo();
  }, []);

  const callInfo = async () => {
    try {
      const target = params.clientId;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.clientDetail };
      apiInfo.url += target + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const url = "/productmanagement/client/detail/" + target + "/";
        setClientName(res.data.name);
        setClientCode(res.data.client_code);
        setClientUrl(url);

        const clientUser = res.data.client_user;

        // 이미 계정이 있는 경우 클라이언트 상세로 이동
        if (clientUser && clientUser.id) {
          alert("이미 계정이 있는 클라이언트입니다.");
          navigate(url);
          return;
        }
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "idInput") {
      setId(value);
    } else if (name === "passwordInput") {
      setPassword(value);
    }
  };

  const confirmOnClick = async () => {
    try {
      const callAPI = new CallAPI(setLoading);
      const body = {
        client_id: params.clientId,
        user_id: id,
        password: password,
      };

      const res = await callAPI.request(api.clientUserCreate, body);

      if (res.status === 200) {
        alert("성공적으로 생성했습니다.");
        navigate("/accountmanagement/client");
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
      if (err.response.status === 400) {
        alert("이미 존재하는 ID입니다.");
      } else {
        alert("생성에 실패했습니다.\n다시 시도 해주세요.");
      }
    }
  };

  const cancelOnClick = () => {
    if (id || password) {
      const confirm = window.confirm(
        "취소 시 작성하신 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }

    navigate(clientUrl);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/accountmanagement/client" className="conTitleMenu">
            클라이언트 계정
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            생성
          </Link>
        </div>

        <div className="conDes">
          {clientName ? (
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">클라이언트</div>
                <div className="flex1" />
              </div>
              <div>
                <Link className="detailButton" to={clientUrl}>
                  {clientName + " (" + clientCode + ")"}
                </Link>{" "}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">
                아이디(4~16자)<span className="red ml5">*</span>
              </div>
              <div className="flex1" />
            </div>
            <div>
              <input
                name="idInput"
                type="text"
                placeholder="아이디를 입력해주세요."
                value={id}
                onChange={changeValue}
              />
            </div>
          </div>

          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">
                비밀번호(4~16자)<span className="red ml5">*</span>
              </div>
              <div className="flex1" />
            </div>
            <div>
              <input
                name="passwordInput"
                type="password"
                placeholder="비밀번호를 입력해주세요."
                value={password}
                onChange={changeValue}
              />
            </div>
          </div>

          <div className="conDesDes">
            <div className="center">
              <div className="createButton" onClick={confirmOnClick}>
                생성
              </div>
              <div className="cancelButton" onClick={cancelOnClick}>
                취소
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientCreateComponent;
