import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { datetimeFormat, phoneWithHyphen } from "../../../modules/formatter";
import {
  TitleBox,
  CommonBox,
  CopyBox,
  EditableBox,
  LinkBox,
  editButtons,
  MasterInfoBox,
  EmptyBox,
} from "../../../modules/commonTable";

function ClientDetailComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [clientUser, setClientUser] = useState({});
  const [clientCode, setClientCode] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [agency, setAgency] = useState("");
  const [productCount, setProductCount] = useState(0);
  const [pcLimit, setPcLimit] = useState(0);
  const [hmdLimit, setHmdLimit] = useState(0);
  const [pcCount, setPcCount] = useState(0);
  const [hmdCount, setHmdCount] = useState(0);
  const [createdDate, setCreatedDate] = useState("");
  const [masterUser, setMasterUser] = useState({});
  const [memo, setMemo] = useState("");

  const [originName, setOriginName] = useState("");
  const [originPhone, setOriginPhone] = useState("");
  const [originPcLimit, setOriginPcLimit] = useState(0);
  const [originHmdLimit, setOriginHmdLimit] = useState(0);
  const [originMemo, setOriginMemo] = useState("");

  const [accountDetailtUrl, setAccountDetailtUrl] = useState("");
  const [accountCreateUrl, setAccountCreateUrl] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [deviceUrl, setDeviceUrl] = useState("");
  const [productCreateUrl, setProductCreateUrl] = useState("");
  const [masterUrl, setMasterUrl] = useState("");

  // 수정 모드
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (!params.target) {
      alert("잘못된 접근입니다.");
      navigate("/productmanagement/client");
    }

    callInfo();
  }, []);

  const callInfo = async () => {
    try {
      const target = params.target;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.clientDetail };
      apiInfo.url += target + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const data = res.data;

        setClientUser(data.client_user);
        setName(data.name);
        setPhone(data.phone);
        setAgency(data.client_agency.name ? data.client_agency.name : "-");
        setClientCode(data.client_code);
        setProductCount(data.product_count);
        setPcLimit(data.device_count.pc.available);
        setHmdLimit(data.device_count.hmd.available);
        setPcCount(data.device_count.pc.use);
        setHmdCount(data.device_count.hmd.use);
        setCreatedDate(data.created_date);
        setMasterUser(data.master_user);
        setMemo(data.memo);

        setOriginName(data.name);
        setOriginPhone(data.phone ? data.phone : "");
        setOriginPcLimit(data.device_count.pc.available);
        setOriginHmdLimit(data.device_count.hmd.available);
        setOriginMemo(data.memo);

        setAccountDetailtUrl(
          "/accountmanagement/client/detail/" + data.client_user.id
        );
        setAccountCreateUrl("/accountmanagement/client/create/" + data.id);
        setProductUrl("/productmanagement/product/1/client/" + data.id);
        setDeviceUrl("/productmanagement/device/1/client/" + data.id);
        setProductCreateUrl("/productmanagement/product/create/" + data.id);
        setMasterUrl("/accountmanagement/master/detail/" + data.master_user.id);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const changeValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "nameInput") {
      setName(value);
    } else if (name === "phoneInput") {
      setPhone(phoneWithHyphen(value));
    } else if (name === "pcInput") {
      setPcLimit(value);
    } else if (name === "hmdInput") {
      setHmdLimit(value);
    } else if (name === "memoInput") {
      setMemo(value);
    }
  };

  const editOnClick = () => {
    setIsEditMode(true);
  };

  const updateOnClick = async () => {
    let targets = [];

    const replacedPhone = phone ? phone.replace(/-/g, "") : "";

    const nameChanged = name !== originName;
    const phoneChanged = replacedPhone !== originPhone;
    const availablePcChanged = pcLimit !== originPcLimit;
    const availableHmdChanged = hmdLimit !== originHmdLimit;
    const memoChanged = memo !== originMemo;

    if (
      !nameChanged &&
      !phoneChanged &&
      !availablePcChanged &&
      !availableHmdChanged &&
      !memoChanged
    ) {
      alert(
        "변경된 사항이 없습니다.\n하나이상 변경 후 수정 버튼을 눌러주세요."
      );
      return;
    }

    if (nameChanged) {
      if (name.length < 1 || name.length > 100) {
        alert("이름은 1자 이상 100자 이하로 입력해주세요.");
        return;
      }
      targets.push({ target: "name", value: name });
    }
    if (phoneChanged) {
      if (replacedPhone.length < 1 || isNaN(replacedPhone)) {
        alert("연락처는 숫자만 입력해주세요.");
        return;
      }
      targets.push({ target: "phone", value: replacedPhone });
    }
    if (availablePcChanged) {
      if (isNaN(pcLimit)) {
        alert("PC는 숫자만 입력해주세요.");
        return;
      }
      targets.push({ target: "pc_limit", value: Number(pcLimit) });
    }
    if (availableHmdChanged) {
      if (isNaN(hmdLimit)) {
        alert("HMD는 숫자만 입력해주세요.");
        return;
      }
      targets.push({ target: "hmd_limit", value: Number(hmdLimit) });
    }
    if (memoChanged) {
      targets.push({ target: "memo", value: memo });
    }

    // target 길이가 0이상이면 업데이트 콜
    if (targets.length > 0) {
      await updateTargetCall(targets);
    }
  };

  const updateTargetCall = async (targets) => {
    const _confirm = window.confirm("정보를 수정 하시겠습니까?");

    if (_confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        let body = {};
        targets.forEach((target) => {
          body[target.target] = target.value;
        });

        const apiInfo = { ...api.clientUpdate };
        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo, body);

        if (res.status === 200) {
          alert("수정이 완료되었습니다.");
          window.location.reload();
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        if (err.response.status) {
          alert("이미 존재하는 이름입니다. \n다시 시도 해주세요.");
        } else {
          alert("수정에 실패했습니다.\n다시 시도 해주세요.");
        }
      }
    }
  };

  const cancelOnClick = () => {
    const replacedPhone = phone ? phone.replace(/-/g, "") : "";

    const nameChanged = name !== originName;
    const phoneChanged = replacedPhone !== originPhone;
    const availablePcChanged = pcLimit !== originPcLimit;
    const availableHmdChanged = hmdLimit !== originHmdLimit;
    const memoChanged = memo !== originMemo;

    if (
      nameChanged ||
      phoneChanged ||
      availablePcChanged ||
      availableHmdChanged ||
      memoChanged
    ) {
      const confirm = window.confirm(
        "취소 시 변경된 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }

    setName(originName);
    setPhone(originPhone ? phoneWithHyphen(originPhone) : "-");
    setPcLimit(originPcLimit);
    setHmdLimit(originHmdLimit);
    setMemo(originMemo);

    setIsEditMode(false);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/client" className="conTitleMenu">
            클라이언트
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />
        </div>
        <div className="conDes">
          <TitleBox title="클라이언트 정보" />
          <div>
            <div className="flexwrap">
              <CopyBox title="클라이언트 코드" content={clientCode} />
              <EditableBox
                title="연락처"
                content={phone ? phoneWithHyphen(phone) : "-"}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="phoneInput"
              />
            </div>
            <div className="flexwrap">
              <EditableBox
                title="고객사명"
                content={name}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="nameInput"
              />
              <CommonBox title="대리점" content={agency} />
            </div>
            <div className="flexwrap">
              <EditableBox
                title="설치가능 PC"
                content={pcLimit}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="pcInput"
                add=" 대"
              />

              {clientUser && clientUser.id ? (
                <LinkBox
                  title="계정"
                  content={clientUser.user_id}
                  link={accountDetailtUrl}
                />
              ) : (
                <LinkBox
                  title="계정"
                  content="+ 계정 생성"
                  link={accountCreateUrl}
                  className="createButton"
                />
              )}
            </div>
            <div className="flexwrap">
              <EditableBox
                title="설치가능 HMD"
                content={hmdLimit}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="hmdInput"
                add=" 대"
              />
              <CommonBox
                title="제품"
                content={
                  <>
                    {productCount ? (
                      <Link className="detailButton mr10" to={productUrl}>
                        {productCount} 개
                      </Link>
                    ) : (
                      <span className="mr10">0 개</span>
                    )}
                    <Link className="createButton" to={productCreateUrl}>
                      + 제품 생성
                    </Link>
                  </>
                }
              />
            </div>
            <div className="flexwrap">
              <MasterInfoBox
                masterUser={masterUser}
                createdDate={createdDate}
                masterUrl={masterUrl}
              />
              <CommonBox
                title="보유 기기(PC/HMD)"
                content={
                  pcCount || hmdCount ? (
                    <Link className="detailButton mr10" to={deviceUrl}>
                      {pcCount} 대 / {hmdCount} 대
                    </Link>
                  ) : (
                    <span className="mr10">0 대 / 0 대</span>
                  )
                }
              />
            </div>
          </div>

          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">비고</div>
              <div className="flex1" />
            </div>
            <div>
              {isEditMode ? (
                <textarea
                  name="memoInput"
                  className="memoInput"
                  value={memo}
                  onChange={changeValue}
                ></textarea>
              ) : (
                <div className="memo">{memo ? memo : ""}</div>
              )}
            </div>
          </div>

          <div className="conDesDes pt10">
            <div className="flexwrap">
              <div className="flex1"></div>
              {editButtons(
                isEditMode,
                updateOnClick,
                cancelOnClick,
                editOnClick
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailComponent;
