import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import { numWithCommas } from "../../../modules/formatter";
import { getProfile } from "../../../modules/decodeToken";

function QnaDetailComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [titleText, setTitleText] = useState("제목 영역입니다");
  const [conText, setConText] = useState(
    "내용 영역입니다\n내용이 작성된 내용입니다"
  );
  const [feedBackText, setFeedBackText] = useState("");
  const [updateUrl, setUpdateUrl] = useState("#");

  const [updateBtn, setUpdateBtn] = useState("수정");

  const [feedbackDisable, setFeedbackDisable] = useState(true);
  const [updateStyle, setUpdateStyle] = useState({ display: "block" });

  const callDetail = async () => {
    try {
      const target = params.target;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.qnaDetail };
      apiInfo.url += target + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        // 성공
        let data = res.data;

        // 마스터가 아니면서 자신이 쓴 글이 아니면 뒤로
        if (user && !user.is_master && user.id !== data.client_user) {
          alert("잘못된 접근입니다.");
          navigate("/dashboard");
          return;
        }

        if (data.title) {
          setTitleText(data.title);
        } else {
          setTitleText("");
        }

        if (data.content) {
          setConText(data.content);
        } else {
          setConText("");
        }

        setFeedBackText(data.answer); // 피드백 넣어야함
        if (data.answer && data.answer !== "") {
          // 답변 있음 클라이언트는 수정 기능 제외해야함
          if (!user.is_master) {
            setUpdateStyle({ display: "none" });
          }
          setUpdateBtn("답변 수정");
        }
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const setUserProfile = () => {
    const user = getProfile();
    setUser(user);
  };

  useEffect(() => {
    if (!params.target) {
      // page 잘못됨
      return;
    }
    setUserProfile();

    callDetail();
    setUpdateUrl("/cs/qna/write/" + params.target);

    if (user.is_master) {
      setUpdateBtn("답변");
      setUpdateUrl("#");
      setFeedbackDisable(false);
    }
  }, []);

  const feedbackOnClick = async () => {
    console.log("답변 작성");
    if (user.is_master) {
      if (feedBackText === "") {
        alert("내용이 있어야 합니다.");
      } else if (feedBackText.length > 1500) {
        alert(
          "1,500자 이하로 작성해주세요.\n( 현재 : " +
            numWithCommas(feedBackText.length) +
            ")"
        );
      } else {
        try {
          const target = params.target;

          const callAPI = new CallAPI(setLoading);
          const body = {
            answer: feedBackText,
          };

          const apiInfo = { ...api.qnaAnswer };
          apiInfo.url += target + "/";
          const res = await callAPI.request(apiInfo, body);

          if (res.status === 200) {
            alert("답변이 완료되었습니다.");
            // /cs/notice
            // navigate('/cs/notice', {replace: true})
          }
        } catch (err) {
          console.log("err", err);
          alert("작성에 실패했습니다.\n다시 시도 해주세요.");
        }
      }
    } else {
      alert("마스터만 답변 작성이 가능합니다.");
    }
  };

  const changeValue = (e) => {
    if (user.is_master) {
      setFeedBackText(e.target.value);
    }
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/cs/qna" className="conTitleMenu">
            문의사항
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />
          <Link
            to={updateUrl}
            className="linkButton"
            style={updateStyle}
            onClick={feedbackOnClick}
          >
            {updateBtn}
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="/cs/qna" className="linkButton">
            뒤로
          </Link>
        </div>

        <div className="conDes">
          <div className="conDesTitle">
            <div className="flexwrap">
              <div className="fwb conInputTip">제목 (최대 100자)</div>
              <div className="flex1" />
            </div>
            <div>
              <input name="titleInput" type="text" value={titleText} disabled />
            </div>
          </div>
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">내용 (최대 1,500자)</div>
              <div className="flex1" />
            </div>
            <div>
              <textarea
                name="desInput"
                className="qnaTextarea"
                disabled
                value={conText}
              ></textarea>
            </div>
          </div>
          <div className="conDesDes">
            <div className="flexwrap">
              <div className="fwb conInputTip">답변 (최대 1,500자)</div>
              <div className="flex1" />
            </div>
            <div>
              <textarea
                name="feedbackInput"
                className="qnaTextarea"
                value={feedBackText}
                disabled={feedbackDisable}
                onChange={changeValue}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QnaDetailComponent;
