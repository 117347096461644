import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";

function DeviceCreateComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const pcModelNameList = ["Windows 10", "Windows 11"];
  const hmdModelNameList = [
    "PICO Neo 3 Pro Eye",
    "PICO Neo 3 Pro",
    "PICO Neo 3",
    "PICO Neo 3 Link",
    "PICO 4",
    "PICO 4 Enterprise",
    "PICO G3",
  ];

  const [productName, setProductName] = useState("");
  const [deviceType, setDeviceType] = useState("pc");
  const [modelName, setModelName] = useState("Windows 10");
  const [modelNameOptions, setModelNameOptions] = useState([]);
  const [macAddress, setMacAddress] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [licenseDate, setLicenseDate] = useState("");
  const [isLicenseInfinite, setIsLicenseInfinite] = useState(true);
  const [releaseDate, setReleaseDate] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientCode, setClientCode] = useState("");

  const [productUrl, setProductUrl] = useState("");
  const [clientUrl, setClientUrl] = useState("");

  useEffect(() => {
    if (!params.productId) {
      alert("잘못된 접근입니다.");
      navigate("/productmanagement/device");
    }
    callInfo();
  }, []);

  const callInfo = async () => {
    try {
      // 제품 ID와 검사 ID 가져와서 검증
      const productId = params.productId;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.productDetail };
      apiInfo.url += productId + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const data = res.data;

        setProductName(data.product_type.product_name);
        setClientName(data.client.name);
        setClientCode(data.client.client_code);

        setProductUrl("/productmanagement/product/detail/" + data.id + "/");
        setClientUrl(
          "/productmanagement/client/detail/" + data.client.id + "/"
        );
        makeModelNameOptions("pc");
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const makeModelNameOptions = (deviceType) => {
    let modelNameList =
      deviceType === "pc" ? pcModelNameList : hmdModelNameList;

    let options = [];
    for (let i = 0; i < modelNameList.length; i++) {
      const name = modelNameList[i];
      options.push(<option value={name}>{name}</option>);
    }
    setModelName(modelNameList[0]);
    setModelNameOptions(options);
  };

  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "macAddressInput") {
      setMacAddress(value);
    } else if (name === "serialNumberInput") {
      setSerialNumber(value);
    } else if (name === "releaseDateInput") {
      setReleaseDate(value);
    } else if (name === "deviceTypePcInput") {
      setDeviceType("pc");
      makeModelNameOptions("pc");
    } else if (name === "deviceTypeHmdInput") {
      setDeviceType("hmd");
      makeModelNameOptions("hmd");
    } else if (name === "modelNameInput") {
      setModelName(value);
    } else if (name === "licenseDateInput") {
      setLicenseDate(value);
    } else if (name === "licenseInfiniteInput") {
      const checked = e.target.checked;

      if (checked) {
        setIsLicenseInfinite(true);
        setLicenseDate("");
        document.getElementById("licenseDateInput").disabled = true;
      } else {
        setIsLicenseInfinite(false);
        document.getElementById("licenseDateInput").disabled = false;
      }
    }
  };

  const confirmOnClick = async () => {
    // 유효성 검증
    // 기기 유형 필수
    // Mac Address 50자 이하
    // S/N 50자 이하
    const validateData = () => {
      if (macAddress && macAddress.length > 50) {
        alert("Mac Address는 50자 이하로 작성해주세요.");
        return false;
      }

      if (serialNumber && serialNumber.length > 50) {
        alert("S/N은 50자 이하로 작성해주세요.");
        return false;
      }

      if (!isLicenseInfinite && !licenseDate) {
        alert(
          "라이센스 종료일은 필수입니다. 정해진 내용이 없다면 무제한을 선택해주세요."
        );
        return false;
      }

      return true;
    };

    if (!validateData()) {
      return;
    }

    try {
      const callAPI = new CallAPI(setLoading);
      const body = {
        product_id: params.productId,
        type: deviceType,
        mac_address: macAddress,
        serial_number: serialNumber,
        model_name: modelName,
        release_date: releaseDate,
        license_date: isLicenseInfinite ? "9999-12-31" : licenseDate,
      };

      const res = await callAPI.request(api.deviceCreate, body);

      if (res.status === 200) {
        alert("성공적으로 생성했습니다.");
        navigate(productUrl);
      } else {
        throw new Error(res);
      }
      // }
    } catch (err) {
      if (err.response.status === 400) {
        // udi_di 객체가 err.response.data 안에 존재하는지 확인
        if (err.response.data && err.response.data.error) {
          if (err.response.data.error.includes("Cannot create more PC")) {
            alert(
              "생성 가능한 PC 기기 수를 초과했습니다. 클라이언트 상세페이지를 확인해주세요."
            );
          } else if (
            err.response.data.error.includes("Cannot create more HMD")
          ) {
            alert(
              "생성 가능한 HMD 기기 수를 초과했습니다. 클라이언트 상세페이지를 확인해주세요."
            );
          } else {
            alert("생성에 실패했습니다.\n다시 시도 해주세요.");
          }
        } else {
          alert("생성에 실패했습니다.\n다시 시도 해주세요.");
        }
      } else if (err.response.status === 403) {
        alert("새로고침 후 다시 시도 해주세요.");
      } else if (err.response.status === 409) {
        alert("이미 등록된 기기입니다.\n회수처리 후 등록해 주세요.");
        let deviceUrl = err.response.data.device.id ? "/productmanagement/device/detail/" + err.response.data.device.id : null;
        if (deviceUrl !== null) {
          navigate(deviceUrl);
        }
      } else {
        alert("생성에 실패했습니다.");
      }

      console.log("err", err);
    }
  };

  const cancelOnClick = () => {
    if (macAddress || serialNumber || releaseDate || !isLicenseInfinite) {
      const confirm = window.confirm(
        "취소 시 작성하신 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }
    navigate(productUrl);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/product" className="conTitleMenu">
            기기
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            생성
          </Link>
          <div className="flex1" />
        </div>
        <div className="flexwrap">
          <div className="conDes lowerCenterWidth40">
            {clientName ? (
              <div className="conDesDes">
                <div className="flexwrap">
                  <div className="fwb conInputTip">클라이언트</div>
                  <div className="flex1" />
                </div>
                <div>
                  <Link className="detailButton" to={clientUrl}>
                    {clientName + " (" + clientCode + ")"}
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}
            {productName ? (
              <div className="conDesDes">
                <div className="flexwrap">
                  <div className="fwb conInputTip">제품</div>
                  <div className="flex1" />
                </div>
                <div>
                  <Link className="detailButton" to={productUrl}>
                    {productName}
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip" style={{ margin: "0" }}>
                  기기 유형 <span className="red ml5">*</span>
                </div>
                <div className="radioMenu" style={{ margin: "0" }}>
                  <label className="radioButton">
                    <input
                      name="deviceTypePcInput"
                      type="radio"
                      value={deviceType}
                      onChange={changeValue}
                      checked={deviceType === "pc"}
                    />
                    PC
                  </label>
                  <label className="radioButton ml10">
                    <input
                      name="deviceTypeHmdInput"
                      type="radio"
                      value={deviceType}
                      onChange={changeValue}
                      checked={deviceType === "hmd"}
                    />
                    HMD
                  </label>
                </div>
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="flex1 fwb">
                  제품명 <span className="red ml5">*</span>
                </div>
                <select
                  id="modelNameInput"
                  name="modelNameInput"
                  value={modelName}
                  onChange={changeValue}
                  className={"width70 m15"}
                >
                  {modelNameOptions}
                </select>
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">Mac Address(50자)</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="macAddressInput"
                  type="text"
                  placeholder="Mac Address를 입력해주세요."
                  value={macAddress}
                  onChange={changeValue}
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">S/N(50자)</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="serialNumberInput"
                  type="text"
                  placeholder="S/N을 입력해주세요."
                  value={serialNumber}
                  onChange={changeValue}
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">라이센스</div>
              </div>
              <div className="flexwrap">
                <div className="flex1" />
                <label className="radioButton width50">
                  <input
                    name="licenseInfiniteInput"
                    type="checkbox"
                    className="block"
                    value={isLicenseInfinite}
                    onChange={changeValue}
                    checked={isLicenseInfinite}
                  />
                  <span className="vam">무제한</span>
                </label>
                <input
                  id="licenseDateInput"
                  name="licenseDateInput"
                  type="date"
                  value={licenseDate}
                  onChange={changeValue}
                  disabled
                />
              </div>
            </div>
            <div className="conDesDes">
              <div className="flexwrap">
                <div className="fwb conInputTip">기기출고일</div>
                <div className="flex1" />
              </div>
              <div>
                <input
                  name="releaseDateInput"
                  type="date"
                  value={releaseDate}
                  onChange={changeValue}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="conDesDes pt10 pb10">
          <div className="center">
            <div className="createButton" onClick={confirmOnClick}>
              생성
            </div>
            <div className="cancelButton" onClick={cancelOnClick}>
              취소
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceCreateComponent;
