import { jwtDecode } from "jwt-decode";

const getProfile = () => {
  const accessToken = localStorage.getItem("accessToken");
  // accessToken이 없거나 string이 아닐 경우나 null일 경우 로그아웃 처리

  if (
    !accessToken ||
    accessToken == null ||
    typeof accessToken !== "string" ||
    typeof accessToken == "object"
  ) {
    window.location.href = "/logout";
  }

  try {
    const profile = jwtDecode(accessToken);
    return profile;
  } catch (err) {
    console.error("err", err);
    window.location.href = "/logout";
  }
};

export { getProfile };
