import { React, useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../modules/useWindowDimensions";
import api from "../../../store/api";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import CallAPI from "../../../modules/callAPI";
import LoadingSpinner from "../../loading/spinner";
import {
  dateFormat,
  datetimeFormat,
  phoneWithHyphen,
} from "../../../modules/formatter";
import {
  TitleBox,
  CommonBox,
  CopyBox,
  EditableBox,
  LinkBox,
  editButtons,
  MasterInfoBox,
  EmptyBox,
} from "../../../modules/commonTable";

function DeviceDetailComponent() {
  // React Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook
  const { width } = useWindowDimensions();

  // State hooks
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const STATUS = {
    active: "사용중",
    inactive: "해제",
    requested: "해제신청",
    return: "회수",
  };

  const TYPE = {
    pc: "PC",
    hmd: "HMD",
  };

  const pcModelNameList = ["Windows 10", "Windows 11"];
  const hmdModelNameList = [
    "PICO Neo 3 Pro Eye",
    "PICO Neo 3 Pro",
    "PICO Neo 3",
    "PICO Neo 3 Link",
    "PICO 4",
    "PICO 4 Enterprise",
    "PICO G3",
  ];

  const [productName, setProductName] = useState("");
  const [deviceCode, setDeviceCode] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [status, setStatus] = useState("");
  const [modelName, setModelName] = useState("");

  const [releaseDate, setReleaseDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [licenseDate, setLicenseDate] = useState("");
  const [isLicenseInfinite, setIsLicenseInfinite] = useState(true);
  const [isLicenseLimitDayPassed, setIsLicenseLimitDayPassed] = useState(false);
  const [client, setClient] = useState({});
  const [createdDate, setCreatedDate] = useState("");
  const [masterUser, setMasterUser] = useState({});

  const [productUrl, setProductUrl] = useState("");
  const [clientUrl, setClientUrl] = useState("");
  const [masterUrl, setMasterUrl] = useState("");

  const [originModelName, setOriginModelName] = useState("");
  const [originReleaseDate, setOriginReleaseDate] = useState("");
  const [originReturnDate, setOriginReturnDate] = useState("");
  const [originLicenseDate, setOriginLicenseDate] = useState("");
  const [originIsLicenseInfinite, setOriginIsLicenseInfinite] = useState(true);
  const [originStatus, setOriginStatus] = useState("");

  // 수정 모드
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (!params.target) {
      alert("잘못된 접근입니다.");
      navigate("/productmanagement/device");
    }

    callInfo();
  }, []);

  const callInfo = async () => {
    try {
      const target = params.target;
      const callAPI = new CallAPI(setLoading);

      const apiInfo = { ...api.deviceDetail };
      apiInfo.url += target + "/";
      const res = await callAPI.request(apiInfo);

      if (res.status === 200) {
        const data = res.data;

        setDeviceCode(data.device_code);
        setDeviceType(data.type);
        setMacAddress(data.mac_address);
        setSerialNumber(data.serial_number);
        setStatus(data.status);
        setModelName(data.model_name);

        setReleaseDate(dateFormat(data.release_date));
        setReturnDate(dateFormat(data.return_date));
        if (
          data.license_date &&
          dateFormat(data.license_date) !== "9999-12-31"
        ) {
          setIsLicenseInfinite(false);
          setLicenseDate(dateFormat(data.license_date));
          setIsLicenseLimitDayPassed(new Date(data.license_date) < new Date());
          setOriginIsLicenseInfinite(false);
        } else {
          setLicenseDate("");
          setIsLicenseInfinite(true);
          setOriginIsLicenseInfinite(true);
        }
        if (data.product?.product_name) {
          setProductName(data.product.product_name);
          setProductUrl("/productmanagement/product/detail/" + data.product.id);
        }
        setClient(data.client);
        setCreatedDate(datetimeFormat(data.created_date));
        setMasterUser(data.master_user);

        setOriginReleaseDate(dateFormat(data.release_date));
        setOriginReturnDate(dateFormat(data.return_date));
        setOriginLicenseDate(
          data.license_date ? dateFormat(data.license_date) : ""
        );
        setOriginStatus(data.status);
        setOriginModelName(data.model_name);

        if (data.client?.id) {
          setClientUrl("/productmanagement/client/detail/" + data.client.id);
        }
        setMasterUrl("/accountmanagement/master/detail/" + data.master_user.id);
      } else {
        throw new Error(res);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const changeValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "statusInput") {
      setStatus(value);
    } else if (name === "releaseDateInput") {
      setReleaseDate(value);
    } else if (name === "modelInput") {
      setModelName(value);
    } else if (name === "returnDateInput") {
      setReturnDate(value);
    } else if (name === "licenseDateInput") {
      setLicenseDate(value);
    } else if (name === "licenseInfiniteInput") {
      const checked = e.target.checked;

      if (checked) {
        setIsLicenseInfinite(true);
        setLicenseDate("");
        document.getElementById("licenseDateInput").disabled = true;
      } else {
        setIsLicenseInfinite(false);
        document.getElementById("licenseDateInput").disabled = false;
      }
    }
  };

  const editOnClick = () => {
    setIsEditMode(true);

    // 수정 모드 진입 시 modelName이 빈값일 경우 첫번째 값으로 초기화
    if (!modelName) {
      setModelName(
        deviceType === "pc" ? pcModelNameList[0] : hmdModelNameList[0]
      );
    }
  };

  const updateOnClick = async () => {
    let targets = [];

    const statusChanged = status !== originStatus;
    const modelNameChanged = modelName !== originModelName;
    const releaseDateChanged = releaseDate !== originReleaseDate;
    const returnDateChanged = returnDate !== originReturnDate;
    const licenseDateChanged = licenseDate !== originLicenseDate;
    const isLicenseInfiniteChanged =
      isLicenseInfinite !== originIsLicenseInfinite;

    if (
      !statusChanged &&
      !modelNameChanged &&
      !releaseDateChanged &&
      !returnDateChanged &&
      !licenseDateChanged
    ) {
      alert(
        "변경된 사항이 없습니다.\n하나이상 변경 후 수정 버튼을 눌러주세요."
      );
      return;
    }

    if (statusChanged) {
      if (status === "return" && !returnDate) {
        alert("회수로 수정 시 기기회수일은 필수입니다.");
        return;
      }
      targets.push({ target: "status", value: status });
    }

    if (modelNameChanged) {
      targets.push({ target: "model_name", value: modelName });
    }

    if (releaseDateChanged) {
      targets.push({ target: "release_date", value: releaseDate });
    }

    if (returnDateChanged) {
      targets.push({ target: "return_date", value: returnDate });
    }

    if (licenseDateChanged || isLicenseInfiniteChanged) {
      if (isLicenseInfinite) {
        targets.push({ target: "license_date", value: "9999-12-31" });
      } else {
        if (!licenseDate) {
          alert(
            "라이센스 만료일을 선택해주세요.\n무제한의 경우 무제한 체크박스를 체크해주세요."
          );
          return;
        }
        targets.push({ target: "license_date", value: licenseDate });
      }
    }

    // target 길이가 0이상이면 업데이트 콜
    if (targets.length > 0) {
      await updateTargetCall(targets);
    }
  };

  const updateTargetCall = async (targets) => {
    const _confirm = window.confirm("정보를 수정 하시겠습니까?");

    if (_confirm) {
      try {
        const target = params.target;
        const callAPI = new CallAPI(setLoading);

        let body = {};
        targets.forEach((target) => {
          body[target.target] = target.value;
        });

        const apiInfo = { ...api.deviceUpdate };

        apiInfo.url += target + "/";
        const res = await callAPI.request(apiInfo, body);

        if (res.status === 200) {
          alert("수정이 완료되었습니다.");
          window.location.reload();
        } else {
          throw new Error(res);
        }
      } catch (err) {
        console.log("err", err);
        alert("수정에 실패했습니다.\n다시 시도 해주세요.");
      }
    }
  };

  const cancelOnClick = () => {
    const releaseDateChanged = releaseDate !== originReleaseDate;
    const returnDateChanged = returnDate !== originReturnDate;
    const licenseDateChanged = licenseDate !== originLicenseDate;
    const isLicenseInfiniteChanged =
      isLicenseInfinite !== originIsLicenseInfinite;
    const statusChanged = status !== originStatus;
    const modelNameChanged = modelName !== originModelName;

    if (
      releaseDateChanged ||
      returnDateChanged ||
      licenseDateChanged ||
      isLicenseInfiniteChanged ||
      statusChanged ||
      modelNameChanged
    ) {
      const confirm = window.confirm(
        "취소 시 변경된 사항이 저장되지 않습니다. \n취소하시겠습니까?"
      );
      if (!confirm) return;
    }

    setReleaseDate(originReleaseDate);
    setReturnDate(originReturnDate);
    if (originLicenseDate) {
      setIsLicenseInfinite(false);
      setLicenseDate(dateFormat(originLicenseDate));
    } else {
      setIsLicenseInfinite(true);
      setLicenseDate("");
    }
    setStatus(originStatus);
    setModelName(originModelName);

    setIsEditMode(false);
  };

  return (
    <div className="contentWrap" style={{ width: width - 130 }}>
      <LoadingSpinner isVisible={loading} />
      <div className="contentBox">
        <div className="conTitle">
          <Link to="/productmanagement/client" className="conTitleMenu">
            기기
          </Link>
          <div className="conTitleMenu">{">"}</div>
          <Link to="#" className="conTitleMenu">
            상세보기
          </Link>
          <div className="flex1" />
        </div>
        <div className="conDes">
          <TitleBox title="기기 정보" />
          <div>
            <div className="flexwrap">
              {deviceCode ? (
                <CopyBox title="기기 코드" content={deviceCode} />
              ) : (
                <CommonBox title="기기 코드" content={"-"} />
              )}
              <CommonBox title="기기 유형" content={TYPE[deviceType]} />
            </div>
            <div className="flexwrap">
              <CommonBox
                title="Mac Address"
                content={macAddress ? macAddress : "-"}
              />
              <CommonBox
                title="모델명"
                content={
                  isEditMode ? (
                    <select
                      className="width70 center"
                      onChange={changeValue}
                      value={modelName}
                      name="modelInput"
                    >
                      {deviceType == "pc"
                        ? pcModelNameList.map((name) => (
                            <option value={name} selected={modelName === name}>
                              {name}
                            </option>
                          ))
                        : hmdModelNameList.map((name) => (
                            <option value={name} selected={modelName === name}>
                              {name}
                            </option>
                          ))}
                    </select>
                  ) : (
                    <span> {modelName ? modelName : "-"} </span>
                  )
                }
              />
            </div>
            <div className="flexwrap">
              <CommonBox
                title="S/N"
                content={serialNumber ? serialNumber : "-"}
              />
              <CommonBox
                title="상태"
                content={
                  isEditMode ? (
                    <select
                      className="width70 center"
                      onChange={changeValue}
                      value={status}
                      name="statusInput"
                    >
                      <option value="active" selected={status === "active"}>
                        사용중
                      </option>
                      <option
                        value="requested"
                        selected={status === "requested"}
                      >
                        해제신청
                      </option>
                      <option value="inactive" selected={status === "inactive"}>
                        해제
                      </option>
                      <option value="return" selected={status === "return"}>
                        회수
                      </option>
                    </select>
                  ) : status === "active" ? (
                    <span> {STATUS[status]} </span>
                  ) : status === "inactive" ? (
                    <span className="blue"> {STATUS[status]} </span>
                  ) : (
                    <span className="red"> {STATUS[status]} </span>
                  )
                }
              />{" "}
            </div>
            <div className="flexwrap">
              <EditableBox
                title="기기출고일"
                content={releaseDate ? releaseDate : "-"}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="releaseDateInput"
                type="date"
              />
              {productName ? (
                <LinkBox title="제품" content={productName} link={productUrl} />
              ) : (
                <CommonBox title="제품" content={"-"} />
              )}
            </div>
            <div className="flexwrap">
              <EditableBox
                title="기기회수일"
                content={returnDate ? returnDate : "-"}
                isEditMode={isEditMode}
                changeValue={changeValue}
                name="returnDateInput"
                type="date"
              />
              {client?.name ? (
                <LinkBox
                  title="클라이언트"
                  content={client.name + " (" + client.client_code + ")"}
                  link={clientUrl}
                />
              ) : (
                <CommonBox title="클라이언트" content={"-"} />
              )}
            </div>
            <div className="flexwrap">
              <CommonBox
                title="라이센스 만료일"
                content={
                  isEditMode ? (
                    <>
                      <label className="radioButton width50">
                        <input
                          name="licenseInfiniteInput"
                          type="checkbox"
                          className="block"
                          value={isLicenseInfinite}
                          onChange={changeValue}
                          checked={isLicenseInfinite}
                        />
                        <span className="vam pl5">무제한</span>
                      </label>
                      <input
                        id="licenseDateInput"
                        name="licenseDateInput"
                        type="date"
                        value={licenseDate}
                        onChange={changeValue}
                        disabled={isLicenseInfinite ? true : false}
                      />
                    </>
                  ) : isLicenseLimitDayPassed ? (
                    <span className="red">
                      {isLicenseInfinite ? "무제한" : licenseDate}
                    </span>
                  ) : (
                    <span>{isLicenseInfinite ? "무제한" : licenseDate}</span>
                  )
                }
              />
              <MasterInfoBox
                masterUser={masterUser}
                createdDate={createdDate}
                masterUrl={masterUrl}
              />
            </div>
          </div>
          <div className="conDesDes pt10">
            <div className="flexwrap">
              <div className="flex1"></div>
              {editButtons(
                isEditMode,
                updateOnClick,
                cancelOnClick,
                editOnClick
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceDetailComponent;
